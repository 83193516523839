<template> 
  <svg id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 24 24"
  :fill="iconColor" xml:space="preserve"> 
  <path d="M17 7H7V2H17V7M22 20V22H15C15 22.55 14.55 23 14 23H10C9.45 23 9 22.55 9 22H2V20H9C9 19.45 9.45 19 10 19H11V17H4V10C4 8.9 4.89 8 6 8H18C19.11 8 20 8.9 20 10V17H13V19H14C14.55 19 15 19.45 15 20H22M18 10H14V12H18V10Z" />
  </svg> 

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
      
  }
</script>
 
