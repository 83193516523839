<template>
  <v-layout column height="600" class="">    
    <v-divider></v-divider>
    <mbs-page-tab 
        :items="MY_LINKS"/>  
    <v-divider></v-divider>
    <v-divider></v-divider>
    <v-divider></v-divider>  
    <v-layout v-if="false">
        <mbs-page-spinner :show="true" />   
    </v-layout>     
    <v-container v-else grid-list-lg>
        <v-card class="mt-2" :min-height="vsh-250" outlined color="">
            <v-toolbar color="primary" dark>
                <v-icon>mdi-domain</v-icon>
                <v-spacer></v-spacer>
                <v-text-field class="mr-5"
                    name="name" clearable
                    v-model="input_search"
                    label="Search Company" 
                    append-icon="search"
                ></v-text-field>
                <v-btn icon @click="TABLE_ACTION('refresh')"><v-icon>refresh</v-icon></v-btn>
                <v-menu bottom left  rounded="xl">
                    <template v-slot:activator="{ on }">
                    <v-btn 
                        dark
                        icon
                        v-on="on" >
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                    </template> 
                    <v-card>
                        <v-card-title class="secondary white--text" >
                            SELECT ACTIONS
                        </v-card-title> 
                        <v-list>  
                            <div v-for="(item, index) in table_actions" :key="index">
                                <v-list-item  @click="TABLE_ACTION(item?.action,item)" >
                                    <v-list-item-action class="mr-3" ><v-icon>{{item.icon}}</v-icon></v-list-item-action>
                                    <v-list-item-title class="">{{item.text}}</v-list-item-title>
                                </v-list-item> 
                            </div> 
                        </v-list> 
                    </v-card>
                </v-menu>
            </v-toolbar>
            <v-layout row wrap class="px-4 py-5"> 
                <!-- //others -->
                <v-flex xs6 sm4 lg3 >
                    <v-hover >
                        <template v-slot="{ hover  }">
                            <v-card  hover @click="dialogAddCompany=!dialogAddCompany"
                                rounded="xl"   
                                height="100%">   
                                <v-layout column fill-height class="ma-0">
                                    <v-spacer></v-spacer>
                                    <v-responsive  aspect-ratio="1.8"  >
                                        <v-layout column fill-height align-center justify-center>
                                            <v-icon color="primary">mdi-plus-thick </v-icon>
                                            <div class="font-weight-bold grey--text text--darken-1">Add Company</div>
                                        </v-layout>
                                    </v-responsive>   
                                    <v-divider></v-divider> 
                                    <v-card-actions @click="" hover flat rounded="xl">
                                        <v-layout class="ma-0 pa-3">
                                            <v-icon color="red" class="mr-2">info_outline</v-icon>Explore Example 
                                        </v-layout>
                                    </v-card-actions>
                                </v-layout>
                            </v-card> 
                        </template>
                    </v-hover>
                </v-flex>
                <v-flex xs6 sm4 lg3 v-for="(link, index) in SearchedMyCompanies" :key="index">
                    <v-hover>
                        <template v-slot="{ hover  }">
                            <v-card  
                                @click="SWITCH_COMPANY(link)"
                                :elevation="hover ?12:''"
                                rounded="lg"  hover
                                height="100%">  

                                <!--Not my profile--> 
                                <v-responsive  aspect-ratio="1.8"  >
                                    <v-card height="100%" :color="link.image_background_color?link.image_background_color:'primary lighten-4'" tile>
                                        <v-layout class="ma-0" align-center justify-center fill-height > 
                                            <v-card color="transparent" :width="link.image_size?link.image_size+'%':'50%'" tile flat>
                                                <v-layout fill-height v-if="false">
                                                    <v-img  
                                                        position="center"
                                                        :src="link.image?link.image:MBS.data.noImg" > 
                                                        <template v-slot:placeholder>
                                                            <v-row
                                                                class="fill-height ma-0"
                                                                align="center"
                                                                justify="center"
                                                            >
                                                                <v-progress-circular
                                                                indeterminate
                                                                color="grey lighten-5"
                                                                ></v-progress-circular>
                                                            </v-row>
                                                        </template>
                                                    </v-img>  
                                                </v-layout>
                                                <v-layout v-else fill-height justify-center align-center>
                                                    <v-icon size="60">business</v-icon>
                                                </v-layout>
                                            </v-card>
                                        </v-layout>  
                                    </v-card>
                                </v-responsive> 
                                <v-spacer></v-spacer> 
                                <v-card-text> 
                                    <small class="secondary--text font-weight-bold">/{{link.company_key}}</small> 
                                    <div class="font-weight-bold not-f5" >{{link.company_name}}</div>
                                </v-card-text>
                            </v-card> 
                        </template>
                    </v-hover>
                </v-flex>
            </v-layout> 
        </v-card>
    </v-container>  
    <div >
        <v-dialog
            v-model="dialogAddCompany"
            fullscreen
            hide-overlay
            transition="dialog-left-transition" >
            <v-card tile>
                <v-container grid-list-xs>
                    <v-layout column>
                        <br><br>
                        <v-layout  align-center>
                            <v-btn class="mr-2" icon @click="dialogAddCompany = false"><v-icon>close</v-icon></v-btn>
                            <div class="not-f6">Create a company (Step {{currentStep}} of {{3}})</div>
                        </v-layout>
                        <div class="ml-4 mt-5">
                            <v-col cols="12" sm="10" md="9" >
                                <v-divider class="ml-4"></v-divider>
                                <br>
                                <v-card flat v-if="currentStep == 1">
                                    <form id="form_company_name" @submit.prevent="NEXT_STEP(2,500)" >
                                        <v-card-title primary-title>
                                            <div style="line-height: 1.2;" class="not-f9 grey--text text--darken-1">
                                                Let's start with a name for your Company
                                            </div> 
                                        </v-card-title>
                                        <v-card-text > 
                                            <div class="mt-5">You Company Name *</div>
                                            <v-text-field 
                                                v-model="inputCompanyName" filled 
                                                :label="inputCompanyName?'':'Enter name'"
                                                id="company_name"
                                            ></v-text-field>
                                            <div class="mt-2">Company ID *</div>
                                            <v-text-field
                                                :loading="inputLoadingCompanyKey"
                                                v-model="inputCompanyKey"
                                                :label="inputCompanyName?'':'Enter ID'" filled  
                                                id="company_id"
                                            ></v-text-field> 
                                            <v-btn 
                                                :disabled="inputLoadingCompanyKey || !input.company_key"
                                                class="mt-8" type="submit" :loading="inputLoading" color="primary"  >Continue</v-btn> 
                                        </v-card-text> 
                                    </form>
                                </v-card>
                                <v-card flat v-if="currentStep == 2">
                                    <form id="form_company_type" @submit.prevent="NEXT_STEP(3,1000)" >
                                        <v-card-title primary-title>
                                            <div style="line-height: 1.2;" class="not-f9 grey--text text--darken-1">
                                                Company type
                                            </div> 
                                        </v-card-title>
                                        <v-card-text> 
                                            <div class="mt-5">Company Type *</div>
                                            <v-autocomplete required
                                                v-model="input.company_type"
                                                :items="CompanyTypes"
                                                :item-text="'name'"
                                                :item-value="'code'"  filled 
                                                :label="input.company_size?'':'Select company type'"
                                                id="company_types" /> 
                                            <div class="mt-2">Company Size *</div>
                                            <v-autocomplete required
                                                v-model="input.company_size"
                                                :items="CompanySizes"
                                                :item-text="'name'"
                                                :item-value="'code'"  filled 
                                                :label="input.company_size?'':'Select company size'"
                                                id="company_size"/>

                                        </v-card-text> 
                                        <v-layout  align-center> 
                                            <v-btn class="mt-8" text color="primary" @click="currentStep = 1">Previous</v-btn> 
                                            <v-spacer></v-spacer> 
                                            <v-btn class="mt-8" type="submit" :loading="inputLoading" color="primary"  >Continue</v-btn> 
                                        </v-layout>
                                    </form>
                                </v-card>
                                <v-card flat v-if="currentStep == 3">
                                    <form id="form_company_details" @submit.prevent="NEXT_STEP()" >
                                        <v-card-title primary-title>
                                            <div style="line-height: 1.2;" class="not-f9 grey--text text--darken-1">
                                                Company Details
                                            </div> 
                                        </v-card-title>
                                        <v-card-text> 
                                            <div class="mt-5">Company Phone Number *</div>
                                            <v-text-field filled required
                                                v-model="input.company_phone_number" 
                                                :label="input.company_phone_number?'':'Enter phone '"
                                                id="company_phone_number"
                                            ></v-text-field>
                                            <div class="mt-2">Company Email Address (Optional)</div>
                                            <v-text-field filled required
                                                v-model="input.company_email_address" 
                                                :label="input.company_email_address?'':'Enter Email'"
                                                id="company_email"
                                            ></v-text-field> 
                                            <div class="mt-2">Company Location Address (Optional)</div>
                                            <v-text-field filled required
                                                v-model="input.company_location_address" 
                                                :label="input.company_location_address?'':'Enter address'"
                                                id="company_email"
                                            ></v-text-field> 
                                        </v-card-text> 
                                        <v-layout  align-center> 
                                            <v-btn class="mt-8" text color="primary" @click="currentStep = 2">Previous</v-btn> 
                                            <v-spacer></v-spacer> 
                                            <v-btn class="mt-8" type="submit" 
                                            :loading="inputLoading" color="primary">Create Company</v-btn>  
                                        </v-layout>
                                    </form>
                                </v-card>
                            </v-col>
                        </div>
                        
                    </v-layout> 
                </v-container> 
            </v-card> 
        </v-dialog> 
    </div>
  </v-layout> 
</template>

<script>
import DATA from '../../../plugins/DATA' 
import {mapState,mapGetters } from "vuex"
let PAGE_NAME = DATA.ITEMS.COMPANIES.values
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_SHOW:false, 
                DATA:DATA, 
                addCompanySteps:[
                    {name:"Company Name",step:1},
                    {name:"Company Types",step:2},
                    {name:"Company Details",step:3},
                ],
                currentStep:1,
                dialogAddCompany:false,
                inputLoading:false,
                inputLoadingCompanyKey:false,
                inputCompanyKey:"",
                inputCompanyName:"",
                input:{},
                input_search:null,
                runConfirm : null ,
                table_actions:[   
                    {type:"action",icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},  
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                ] 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);    
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            this.MBS.actions.header({
                show:true,
                type:'subToolbar', 
                
            })
            this.MBS.actions.subToolbar({
                show:true,
                height:70,
                flat:false,
                color:'primary',
                textColor:'white', 
                side:'right'
            }) 
            this.FEEDBACKS(500)
             
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                MyCompanies: state=> state.users[(DATA.ITEMS.MY_COMPANIES.values).toUpperCase()], 
            }),  

            /////////
            MY_LINKS(){
                let links = this.MBS.actions.FIND_SUB_LINKS("account",1)  
                return links
            },
            CompanyTypes(){ 
                const re = this.$store.getters.getCompanyTypes
                return re
            },
            CompanySizes(){ 
                const re = this.$store.getters.getCompanySizes
                return re
            },
            // MyCompanies(){ 
            //     const re = this.$store.getters.getMyCompanies
            //     return re
            // },
            JoinedMyCompanies(){ 
                const companies = this.MyCompanies 
                console.log(companies,'companies..........***');
                if(!companies){return null}
                let joined = this.MBS.actions.JOIN_COMPANIES(companies)
                return joined
            },
            SearchedMyCompanies(){ 
                const companies = this.JoinedMyCompanies 
                const input_search = this.MBS.actions.TEXT_UP0(this.input_search)
                console.log(companies,'companies..........***');
                if(!companies){return null}
                if(!input_search){return companies}
                let joined = companies.filter(company=>{
                    let company_name = this.MBS.actions.TEXT_UP0(company.company_name)
                    let index = company_name?company_name.indexOf(input_search):-1
                    return index !=-1
                })
                return joined
            },
        },
        methods: { 
            clicked(){}, 
            TABLE_ACTION(action,item){
                try { 
                    console.log(action,'action..........'); 
                    if (action == "refresh") {
                        let mbs_text = this.MBS.text.item_action(DATA.ITEMS.COMPANIES.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-DAY-SHIFTS", 
                            } 
                        })
                    }else { 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            restDialogAddCompany(){
                this.input={}
                this.inputCompanyName=''
                this.inputCompanyKey=''
                this.inputLoading=false
                this.inputLoadingCompanyKey=false
            }, 
            COMPANY_NAME_CHANGE(company_name){
                try {  
                    if (company_name) {
                        this.inputLoadingCompanyKey = true
                        this.MBS.actions.KEY_NAME(company_name)
                        .then(res=>{ 
                            this.inputLoadingCompanyKey = false
                            this.inputCompanyKey = res?res.key_name:''
                        }).catch(error=>{ 
                            this.inputLoadingCompanyKey = false 
                        })  
                    } else {
                        this.inputCompanyKey = '' 
                        this.input.company_name = ''
                        this.input.company_key = ''
                    }
                } catch (error) {
                    this.inputLoadingCompanyKey = false
                    this.MBS.actions.error({
                        error:error,
                        from:'COMPANY_NAME_CHANGE',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            CONFIRM_KEY(company_name, timeout = 1000){
                try { 
                    this.inputLoadingCompanyKey = true
                    if (this.runConfirm) {
                        clearTimeout(this.runConfirm)
                        this.runConfirm = null
                    } 
                    this.runConfirm = setTimeout(() => { 
                        if (this.input.company_key != company_name) {
                            let path = this.MBS.actions.TEXT_UP(DATA.APP.SERVER+"/"+DATA.ITEMS.COMPANIES.values)
                            this.MBS.actions.KEY_NAME(company_name,path)
                            .then(res=>{ 
                                this.inputLoadingCompanyKey = false
                                this.inputCompanyKey = res?res.key_name:''
                                this.input.company_name = company_name
                                this.input.company_key = this.inputCompanyKey
                            }).catch(error=>{ 
                                this.inputLoadingCompanyKey = false 
                            })  
                        } else {
                            this.inputCompanyKey = company_name
                            this.inputLoadingCompanyKey = false 
                        } 
                    }, timeout);
                } catch (error) {
                    this.inputLoadingCompanyKey = false
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_KEY',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 
            async NEXT_STEP(step , time = 1000){
                try { 
                    let input = this.input
                    let company_name = input?input.company_name:null
                    let company_key = input?input.company_key:null
                    let company_type = input?input.company_type:null
                    let company_size = input?input.company_size:null 
                    let currentStep = this.currentStep 
                    
                    let next = ()=>{
                        this.inputLoading = true
                        setTimeout(() => {
                            this.inputLoading = false
                            this.currentStep = step
                        }, time);    
                    }

                    //check errors
                    let mbs_text = null
                    if (currentStep == 1) { 
                        if (!company_name) {
                            mbs_text = this.MBS.text.field_required("Company name")
                        }if (!company_key) {
                            mbs_text = this.MBS.text.field_required("Company key")
                        } 
                    } 

                    if (mbs_text) {
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                        })
                    } else {
                        if (step) {
                            next()
                        } else {
                            this.CREATE_COMPANY()
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'NEXT_STEP',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            async CREATE_COMPANY(){
                try { 
                    let input = this.input
                    let inputCompanyName = this.inputCompanyName
                    let company_name = inputCompanyName?inputCompanyName:null
                    let company_key = input?input.company_key:null
                    let company_type = input?input.company_type:null
                    let company_size = input?input.company_size:null 
                    let company_phone_number = input?input.company_phone_number:null 
                    let company_email_address = input?input.company_email_address:null 
                    let company_location_address = input?input.company_location_address:null 
                    let currentStep = this.currentStep 
                    let mbs_text = null

                    let company_details = {
                        company_name:company_name?company_name:null, 
                        company_key:company_name?company_key:null, 
                        company_size:company_size?company_size:null, 
                        company_type:company_type?company_type:null, 
                        company_phone_number:company_phone_number?company_phone_number:null, 
                        company_email_address:company_email_address?company_email_address:null, 
                        company_location_address:company_location_address?company_location_address:null, 
                    } 

                    if (!company_key) {
                        mbs_text = this.MBS.text.field_required("Company key")
                    }

                    if (mbs_text) {
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                        })
                    } else {
                        let mbs_text = this.MBS.text.item_action(DATA.ITEMS.COMPANIES.name,"Create")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=ADD-ITEM", 
                                data:{
                                    ...company_details
                                }
                            }
                        }) 
                          
                    }
                     
                } catch (error) {
                    this.inputLoading = false
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_COMPANY',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            SUBMIT_COMPANY(data){
                try { 
                    if (data) {
                        this.inputLoading = true
                        let uid = this.us?this.us.uid:null
                        let path_company = DATA.PATHS.companies+data.company_key
                        // let path_company_users = DATA.PATHS.company_users
                        // path_company_users = this.MBS.actions.COMPANY_LINK(path_company_users,true,data.company_key)
                        let path_user_companies = "/USERS/"+uid+"/companies/"+data.company_key
 
                        this.$store.dispatch("fi_set",{
                            path:path_company,
                            data:data,
                            action:DATA.ITEMS.COMPANIES.values
                        }).then(res=>{
                            return this.$store.dispatch("fi_add",{ 
                                action:DATA.ITEMS.COMPANIES.values,
                                path:path_user_companies,
                                data:{
                                    company_key:data.company_key
                                },
                                us:this.us
                            })
                        }).then(res=>{
                            this.inputLoading = false
                            let mbs_text = this.MBS.text.company_success_created(this.inputCompanyName)
                            this.MBS.actions.dialog({
                                show:true,
                                title:mbs_text.title,
                                text:mbs_text.text,
                                btnYes:mbs_text.btnYes,
                            })
                            this.dialogAddCompany = false
                        }).catch(error=>{
                            this.inputLoading = false
                            let data= error?error.data:null
                            let available= data?data.available:null
                            let mbs_text = null
                             
                            if (available == false) {
                                mbs_text = this.MBS.text.item_action_error_exist("Company Key","Used")
                            } else {
                                mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.COMPANIES.name,"Creating")
                            } 
                            this.MBS.actions.dialog({
                                show:true,
                                title:mbs_text.title,
                                text:mbs_text.text,
                                btnYes:mbs_text.title,
                            })
    
                        })
                        
                    } else {
                        mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.COMPANIES.name,"Creating")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.title,
                        })
                    }
                       
                } catch (error) {
                    this.inputLoading = false
                    this.MBS.actions.error({
                        error:{
                            error:error,
                            data:data
                        },
                        from:'SUBMIT_COMPANY',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            SWITCH_COMPANY(company){
                try {  
                    let mbs_text = this.MBS.text.switch_company_confirm(company.company_name)
                    this.MBS.actions.dialogConfirmPassword({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        icon:'mdi-home-switch-outline',
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=SELECT-COMPANY",
                            data:company,
                            // all_users:true, 
                            phone_number_start:true, 
                            // get_access:{
                            //     permission_type:"action",
                            //     permission_to:"delete",
                            //     action_name:DATA.ITEMS.SALE_ORDER_ITEMS.names 
                            // }
                        }
                    })

                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SWITCH_COMPANY',
                        page:PAGE_NAME, 
                    })
                }  
            }, 
            async SELECT_COMPANY(company){
                try {
                    await this.MBS.actions.SELECT_COMPANY(company)
                    this.MBS.actions.go("/")
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_COMPANY',
                        page:PAGE_NAME, 
                    })
                }  
            }, 
            async RELOAD_ITEMS(){
                try {
                    
                    let res = await this.$store.dispatch('fi_get_user_items',{
                        name:DATA.ITEMS.USER_DETAILS.values,
                        on:true, 
                        cash:true, 
                        get:false,  
                    }) 
                    console.log(res,'res.......');
                    this.MBS.actions.progressDialog()
                    let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.COMPANIES.values,"Refresh")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo, 
                    })
                } catch (error) {
                    console.log(error,'error.......');
                    this.MBS.actions.progressDialog()
                    let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.ITEMS.values,"Refresh")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo, 
                    }) 
                }

            },
            //LOAD 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                this.SUBMIT_COMPANY(action.data)   
                            } else {
                                
                            }
                        }   
                        if (action.code ===PAGE_NAME+'=SELECT-COMPANY') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.SELECT_COMPANY(action.data) 
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=RELOAD-DAY-SHIFTS') {
                            if (action.YES) {
                                setTimeout(() => {  
                                   this.RELOAD_ITEMS()   
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },
            EXIT_PAGE(){
                if (!this.us) {
                    this.MBS.actions.go("/")
                }else{
                    this.PAGE_SHOW = true
                }
            },
            FEEDBACKS(time=400){ 
                setTimeout(()=>{
                    let us = this.us 
                    let ps = this.processes
                    let ld = this.loading
                    let res = this.responses 

                    //CHECK USER STATE 
                    if (ps) {
                        let logging = ps.find(process=>{
                            return process.name == 'logging'
                        }) 

                        if (logging) {
                            this.PAGE_SHOW =false
                        }else{
                            this.EXIT_PAGE()
                        }     
                    }else{
                       this.EXIT_PAGE()
                    }

                     
                },time)
            }
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);      
        },
        watch: { 
            us(v){
                this.FEEDBACKS()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            },
            inputCompanyName(value){
                this.COMPANY_NAME_CHANGE(value) 
            }, 
            inputCompanyKey(value){
                this.CONFIRM_KEY(value,3000) 
            }, 
            dialogAddCompany(value){
                this.restDialogAddCompany()
            }, 
        },
  }
</script>
 
