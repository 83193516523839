
<template>
    <div>   
        <v-container grid-list-xl> 
            <!-- <v-btn color="success" @click="UPLOAD_LOCALS()">UPLOAD_LOCALS</v-btn> -->
            <div>  
                <v-alert v-if="ThisDayShift?ThisDayShift.local_close_at?true:false:false"
                    border="top" 
                    colored-border
                    color="warning"
                    elevation="2" >
                    <v-layout  class="ma-0 py-2"   align-center>
                        <v-icon color="warning">warning</v-icon>
                        <div class="px-5">
                            The selected Day shift has already been closed. Click Read Report Button to view the Sales Report.
                        </div>  
                    </v-layout> 
                    <v-layout row wrap class="ma-0 px">
                        <v-spacer></v-spacer>
                        <v-btn @click="READ_REPORT()" color="warning" outlined>Read Report</v-btn>
                    </v-layout>
                </v-alert>
                <br>
                <h1 class="grey--text text--darken-1">Close Day Shift</h1>
                <br>
                <form id="form_order_details" @submit.prevent="CREATE_ORDER()" >
                    <v-row>
                        <v-col cols="12" md="4">
                            <div class="text-justify">
                                <h4 class="grey--text text--darken-1">Order Details</h4>
                                Add details for this delivery for ease identification of all your deliveries. Stock receiving is the function of checking items delivered to the business after an order. This includes inspecting the quality, condition, and quantity of any incoming goods, and allocating them to a space in the warehouse.
                            </div>
                        </v-col>
                        <v-col cols="12" md="8"  > 
                            <mbs-page-spinner v-if="!show_input"
                                height="500"/>  
                            <mbs-input-fields v-else
                                v-model="input"
                                :field_items="DATA.FIELDS.close_day_shift"
                                :select_items="SelectItems"
                                :disabled_fields="DisabledFields"
                                />  
                            <v-layout row wrap>
                                <v-flex xs7>
                                    <v-btn :to="MBS.actions.COMPANY_LINK('/manage-shift/'+day_shift_key,true)"     
                                        class="mt-5" width="100%" color="primary" :disabled="!day_shift_key">
                                        MANAGE THIS SHIFT
                                        <v-icon class="ml-2">mdi-order-bool-ascending-variant</v-icon>
                                    </v-btn> 
                                </v-flex>
                                <v-flex xs5 class="pl-0">
                                    <v-btn @click="dialog_shift_sales_report=!dialog_shift_sales_report"     
                                        class="mt-5" width="100%" color="secondary" :disabled="!day_shift_key">
                                        DETAILED REPORT
                                        <v-icon class="ml-2" size="32">mdi-clipboard-list-outline</v-icon>
                                    </v-btn> 
                                </v-flex> 
                            </v-layout>
                        </v-col> 
                    </v-row> 
                    <br><br> 
                    <hr>
                    <br><br><br> 
                    <v-row> 
                        <v-col cols="12" >
                             <input ref="btn_submit_order" type="submit" v-show="false"> 
                            <v-card v-if="ThisDayShift?ThisDayShift.local_close_at?true:false:false">
                                 
                            </v-card>
                            <v-card :min-height="vsh-170" outlined rounded="sm" v-else>
                                <v-layout fill-height column class="ma-0 "> 
                                    <v-toolbar  rounded="" outlined    color="primary" class="m-2" dark>  
                                        <template > 
                                            <v-toolbar-title> 
                                                <v-list color="transparent" v-if="Joined_DayShift" >
                                                    <v-list-item>
                                                        <v-list-item-action><v-icon>home</v-icon></v-list-item-action>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{Joined_DayShift.outlet_name}}</v-list-item-title> 
                                                            <v-list-item-subtitle>{{
                                                                MBS.date.moment(Joined_DayShift.operating_date).format("dddd, Do, MMMM YYYY")
                                                                }}</v-list-item-subtitle> 
                                                        </v-list-item-content>                                                                                                             
                                                    </v-list-item>
                                                </v-list>
                                            </v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-divider vertical></v-divider> 

                                            <!-- <v-btn 
                                                icon @click="ON_PDF()">
                                                <v-icon>mdi-file-pdf-box </v-icon>
                                            </v-btn>
                                            <v-btn 
                                                icon @click="ON_EXCEL()">
                                                <v-icon>mdi-microsoft-excel </v-icon>
                                            </v-btn>
                                            <v-btn 
                                                icon @click="ON_EMAIL()">
                                                <v-icon>mdi-email-outline</v-icon>
                                            </v-btn>
                                             -->
                                            <!-- <v-btn  
                                                icon @click="ON_PRINTING()">
                                                <v-icon>mdi-printer</v-icon>
                                            </v-btn> -->
                                        </template>
                                    </v-toolbar>
                                    <v-card color="transparent" :min-height="vsh-320" flat tile class="mb-5">
                                        <!-- <mbs-day-shift-temp3  
                                            sales_type="Z-REPORT"
                                            :closing_cash="input.closing_cash"
                                            :data="Joined_DayShift" />   -->
                                        <mbs-epson-day-shift-temp1 v-if="!dialog_shift_sales_report"
                                            class="mb-5"
                                            :closing_cash="input.closing_cash"
                                            report_type="Z-READ"
                                            :data="Joined_DayShift" />  
                                    </v-card>
                                    <v-card @click="SUBMIT()"  
                                        :disabled="inputLoading" 
                                        :flat="!input.closing_cash"  
                                        class="mx-5 my-4 v-btn" :color="inputLoading?'grey darken-1':'primary'" dark >
                                        <!-- class="mx-5 my-4 v-btn" :color="!input.closing_cash?'grey darken-1':'primary'" dark > -->
                                        <v-layout class="ma-0 py-2 px-4" justify-center align-center v-if="!inputLoading">
                                            <!-- <div class="font-weight-bold not-f7">Close Day Shift</div> 
                                            <v-spacer></v-spacer> -->
                                            <div class="font-weight-bold not-f7">CLOSE DAY SHIFT </div> 
                                            <v-icon size="35" class="ml-2">$printer-pos-remove</v-icon>
                                        </v-layout> 
                                        <v-layout v-else class="ma-0 pa-2" justify-center>
                                            <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                        </v-layout>
                                    </v-card>
                                </v-layout> 
                            </v-card>   
                        </v-col> 
                    </v-row> 
                </form>
            </div> 
        </v-container> 
        
        <!-- //DIALOGs -->
        <div>
            <mbs-auto-print
                v-if="printing"
                @finish_print="FINISH_PRINT"
                :show="true"  
                :auto_print="auto_print"
                :auto_printer_name="auto_printer_name"
                :type="print_type" 
                :title="print_title" 
                :data="print_data" 
                /> 
            <mbs-day-shift-sales-report
                v-model="dialog_shift_sales_report"
                :day_shift="ThisDayShift"
                /> 

        </div>
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import DB from '../../../plugins/DB'
    import {mapState} from "vuex"
    let PAGE_NAME = "Z-READ"  
    export default {
        name:PAGE_NAME, 
        props:['id','day_key','type'], 
        data() { 
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10, 
                search_item:'', 
                selected_items:null,
                inputLoading:false,
                input:{},
                search_input:null,  
                DATA:DATA, 
                day_shift_key:null, 
                show_input:true, 

                dialog_shift_sales_report:false, 

                printing:false,
                print_type:'z_read',
                print_title:'Z-READ',
                print_data:null,
                auto_print:false,
                auto_printer_name:null,
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
                this.input.day_shift_key = this.day_key  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.MBS.actions.toolbar({
                    show:true, 
                }) 
                this.FEEDBACKS(1000) 
                this.ON_SELECT_SHIFT()
                this.input.day_shift_key = this.day_key  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            ///////// 
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],  
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],  
                DayShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDayShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],  
                LocalDaysShifts: state=> state.locals[(DATA.ITEMS.LOCAL_DAYS_SHIFTS.values).toUpperCase()], 
                Tills: state=> state.items[(DATA.ITEMS.TILLS.values).toUpperCase()],
                JoinedTills: state=> state.join[(DATA.ITEMS.TILLS.values).toUpperCase()], 

                SalesOrders: state=> state.items[(DATA.ITEMS.DAY_SALES.values).toUpperCase()],
            }), 
            DataPayments(){
                let Payments = this.Payments
                let JoinedPayments = this.JoinedPayments 
                return JoinedPayments?JoinedPayments:Payments
            },
            DayShiftsData(){
                let DayShifts = this.DayShifts
                let JoinedDayShifts = this.JoinedDayShifts 
                let items = null
                if (JoinedDayShifts) {
                    items = JoinedDayShifts
                }else{
                    if (DayShifts) {
                        items = this.MBS.actions.JOIN_DAY_SHIFTS(DayShifts)   
                    }
                }
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))    
                return list 
            },
            AllDaysShifts(){
                // let locals = this.MBS.actions.JOIN_DAY_SHIFTS(this.LocalDaysShifts)
                let locals = this.LocalDaysShifts
                let dayShifts = this.DayShiftsData

                let data = []
                if(!dayShifts && !locals){return null}
                
                if (dayShifts) {
                    data = [...dayShifts] 
                }if (locals) {
                    locals.forEach(local => {
                        let index = data.findIndex(item=>item.key == local.key)
                        if (index ==-1 ) {
                            data.push(local)
                        } else {
                            console.log(data[index],'al********************----------');
                            console.log(local,'loc********************----------');
                            data[index] = {...data[index],...local}
                        }
                        
                    }); 
                 }
                return data
            }, 
              
            LocalCashup(){   
                let localCashup = this.MBS.actions.LocalCashup 
                return localCashup
            }, 
            TillsData(){ 
                return this.JoinedTills?this.JoinedTills:this.Tills
            }, 
            CurrentTill(){
                let tills = this.TillsData 
                let cashup = this.LocalCashup 
                if(!tills || !cashup){return null}
                let filled = tills.find(item=>{
                    return item.key == cashup.till_key
                })
                return filled
            },
            CurrentReceiptPrinter(){
                let till = this.CurrentTill 
                let printer = till?till.printer:null
                let joined = this.MBS.actions.JOIN_PRINTER(printer) 
                return joined
            },
            selectedCompany(){ 
                return this.$store.getters.getSelectedCompany
            },  
             
            ThisDayShift(){  
                let day_shift_key = this.day_shift_key
                let day_shifts= this.AllDaysShifts
                let day_shift = day_shifts?day_shifts.find(day=>day.key==day_shift_key):null 
                return day_shift
            },  
            Joined_DayShift(){  
                let item = this.ThisDayShift  
                if(!item){return null}
                let joined = this.MBS.actions.JOIN_DAY_SHIFT(item)  
                return joined
            },  
            SelectItems(){ 
                let DayShifts = this.AllDaysShifts
                return {
                    DayShifts:DayShifts, 
                }
            },
            DisabledFields(){
                let shift = this.ThisDayShift
                let settings = this.CompanySettings  
                let edit_closing_cash = settings?settings.edit_closing_cash:null
                let total_closing_cash = shift?shift.total_closing_cash:null
                
                if (!edit_closing_cash) {
                    return ['closing_cash','operating_date_','outlet_name','expected_cash']
                }
                if (total_closing_cash) {
                    return ['closing_cash','operating_date_','outlet_name','expected_cash']
                }
                return null
            },

            SaleOrders(){ 
                let items = this.$store.getters.getSaleOrders  
                if(!items){return null}
                let filtered_items = items.filter(item=>{
                    return !item.archive && item.day_shift_key == this.day_shift_key
                }) 
                if(!filtered_items){return null}
                let list = [...filtered_items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",true))  
                return list
            }, 
            Joined_SaleOrders(){   
                let orders = this.SaleOrders 
                let joined = this.MBS.actions.JOIN_SALE_ORDERS(orders)   
                return joined
            },
            DayShiftSaleOrders(){ 
                let sales = this.Joined_SaleOrders 
                let day_shift_key = this.day_shift_key 
                if(!day_shift_key||!sales){return null}
                let filtered_items = sales.filter(item=>{
                    return item.day_shift_key == day_shift_key
                })    
                return filtered_items  
            }, 
            DayShiftSaleOrdersItems(){
                let sales = this.DayShiftSaleOrders 
                let items = this.ORDER_ITEMS(sales)  
                return items
            },
            RetailDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                
                let retail_items = this.RETAIL_ITEMS(items)
                return retail_items 
            },
            WholesaleDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.WHOLESALE_ITEMS(items)
                return retail_items 
            },
            CashDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems 
                let retail_items = this.CASH_ITEMS(items) 
                return retail_items 
            },
            OnAccountDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.ON_ACCOUNT_ITEMS(items)
                return retail_items 
            },



            ThisInvoicePayments(){
                let payments = this.DataPayments
                let Joined_DayShift = this.Joined_DayShift 
                let day_shift_key = Joined_DayShift?Joined_DayShift.key:null
                if(!payments||!day_shift_key){return null}
                let filled_items = payments.filter(item=>{
                    return item.day_shift_key == day_shift_key
                })  
                return filled_items
            },
            InvoiceCashPayments(){
                let payments = this.ThisInvoicePayments 
                if(!payments){return null}
                let filled_items = payments.filter(item=>{
                    return !item.payment_option_code
                }) 
                return filled_items
            },
            OtherInvoicePayments(){
                let payments = this.ThisInvoicePayments 
                if(!payments){return null}
                let filled_items = payments.filter(item=>{
                    return item.payment_option_code
                }) 
                return filled_items
            },
            TotalInvoicePayments(){
                try { 
                    let items = this.ThisInvoicePayments
                    let total_amount = 0 
                    if (!items) {return 0}
                    items.forEach(item => {
                        let amount = this.toNumber(item.amount) 
                        total_amount = total_amount + amount
                    });   
                    return total_amount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalInvoicePayments',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  
            
        },
        methods: {   
            TOTAL_AMOUNT(items){
                try {  
                    let total_amount = 0 
                    if (!items) {return 0}
                    items.forEach(item => {
                        let amount = this.toNumber(item.amount) 
                        total_amount = total_amount + amount
                    });  
                    return total_amount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TOTAL_AMOUNT',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  
            TABLE_ACTION(action,item){
                try {
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            FINISH_PRINT(action,payload){
                try { 
                    let canceled = payload?payload.canceled:null
                    if (canceled) {
                        
                    }else{
                        setTimeout(() => {
                                this.MBS.actions.go(-1)   
                        }, 1); 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FINISH_PRINT',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            
            SUBMIT(){
                try {
                    let btn_submit_order = this.$refs.btn_submit_order
                    if (btn_submit_order) { 
                        btn_submit_order.click()
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ADD_ITEM(item_key){
                try {
                    let items = this.JOINED_ITEMS
                    if (items) {
                        let item_selected = items.find(item=>{
                            return item.key == item_key
                        })
                        if (!item_selected) {
                            console.log(item_key,'not found');
                            return 
                        }
                        if (!this.selected_items) {
                            this.selected_items = []
                        }

                        let item = this.selected_items.find(item=>{
                            return item.key == item_key
                        }) 
                        if (item) {
                            item.quantity = (item.quantity*1)+1
                        } else {
                            this.selected_items.push({
                                ...item_selected, 
                                quantity:1,
                            })   
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            REMOVE_ITEM(selected_item){
                try {
                    let items = this.selected_items
                    if (items) {
                        let filtered_item = items.filter(item=>{
                            return item.key != selected_item.key
                        })
                        this.selected_items = filtered_item

                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let price = this.toNumber(item.price)
                    let discount = this.toNumber(item.discount)
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CREATE_ORDER(){
                try {
                    let input = this.input   
                    let localCashup = this.LocalCashup 
                    let day_shift = this.ThisDayShift   
                    // let cashups = day_shift?day_shift.joined_cashups:null
                    let cashups = this.MBS.actions.OBJECT_ITEMS(day_shift?day_shift.cashups:null) 
                    let running_cashups = cashups?cashups.filter(cash=>!cash.closed):null
                    let total_cashups =  this.MBS.actions.CALCULATE_TOTAL_CASHUPS(cashups) 
                    let sales_retail = total_cashups?total_cashups.sales_retail:0
                    let sales_wholesales = total_cashups?total_cashups.sales_wholesales:0
                    let sales_on_account = total_cashups?total_cashups.sales_on_account:0
                    let sales_cash_payments = total_cashups?total_cashups.sales_cash_payments:0
                    let invoices_cash_payments = total_cashups?total_cashups.invoices_cash_payments:0
                    
                    console.log(day_shift,'day_shift....');
                    console.log(cashups,'cashups....');
                    let closing_cash = input?this.toNumber(input.closing_cash):null 
                    let day_shift_key = input?input.day_shift_key:null 
                    let description = input?input.description:null  
                    let moment = this.MBS.date.moment
                    let time = moment?moment().valueOf():null
 
                    if(!day_shift){return null}
                    let order_details = {
                        // ...day_shift,
                        company_key:day_shift.company_key,  
                        total_closing_cash:closing_cash,  
                        totals_sales_retail:sales_retail,  
                        totals_sales_wholesales:sales_wholesales,  
                        totals_sales_on_account:sales_on_account,  
                        totals_sales_cash_payments:sales_cash_payments,  
                        totals_invoices_cash_payments:invoices_cash_payments,  
                        key:day_shift_key, 
                        local_close_at:time, 
                        uploaded:false, 
                    } 
                    if (description) {
                        order_details.description = description
                    }   
                    console.log(order_details,'order_details....');
                    console.log(localCashup,'localCashup....');
                    if (localCashup) { 
                        let mbs_text = this.MBS.text.close_register_before_z_read(DATA.ITEMS.DAYS_SHIFTS.name,"Close")
                        mbs_text.btnYes="CLOSE REGISTER"
                        console.log('close....');
                        this.MBS.events.$emit("ON_CLOSE_REGISTER",{mbs_text:mbs_text}) 
                        return
                    }else if (this.MBS.actions.SIZE(running_cashups)) {  
                        console.log(running_cashups,'running_cashups....');
                        setTimeout(() => {
                            let link = this.MBS.actions.COMPANY_LINK("/manage-shift/"+this.day_shift_key,true)
                            let mbs_text = this.MBS.text.close_register_before_z_read(DATA.ITEMS.DAYS_SHIFTS.name,"Close")
                            this.MBS.actions.dialog({
                                show:true,
                                title:'THERE IS UNCLOSED TILL(S)',
                                text:'It seams like there is unclosed till(s) in this day shift. Please, Make sure that all tills are close before Z-Read. Click Manage Shift Button to manage Tills.',
                                btnYes:"Manage Day Shift",
                                btnNo:"Cancel", 
                                action:{
                                    code:"=GO", 
                                    to:link
                                }
                            }) 
                        }, 500);
                        return
                    }
                    if (day_shift) {
                        // console.log(order_details);
                        let mbs_text = this.MBS.text.item_action(DATA.ITEMS.DAYS_SHIFTS.name,"Close")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=CREATE-ORDER", 
                                data:order_details
                            }
                        })
                        
                    }
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ORDER',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            SUBMIT_ORDER(order){
                try {  
                    if (!order) {
                        console.log(order,'order error');
                    }   
                    console.log(order,'Saving...........');
                    // this.ON_PRINTING()   
                    // return
                    this.inputLoading = true
                    this.MBS.actions.progressDialog({
                        show:true,
                        title:"Uploading..",
                    }) 
                    DB.addItems({
                        name:DATA.ITEMS.LOCAL_DAYS_SHIFTS.values,
                        data:order,
                        update_locals:true,   
                    }).then(res=>{ 
                        console.log(res,'res........');
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.DAYS_SHIFTS.value,"Closing")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                        this.ON_PRINTING(res)   
                    }).catch(error=>{
                        this.MBS.actions.error({
                            error:error,
                            from:'SUBMIT_ORDER',
                            page:PAGE_NAME, 
                        })  
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_failed(DATA.ITEMS.QUOTATIONS.value,"Submitted")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_SELECT_SHIFT(){
                try {  
                    let settings = this.CompanySettings  
                    let edit_closing_cash = settings?settings.edit_closing_cash:null
                    this.show_input = false 
                    let day_shift = this.Joined_DayShift  
                    let cashups = day_shift?day_shift.joined_cashups:null
                    let Floating_cash = this.toNumber(day_shift?day_shift.total_floating_cash:0)

                    let InvoiceCashPayments = this.TOTAL_AMOUNT(this.InvoiceCashPayments)   
                    let SalesCashPayments = this.toNumber(this.TOTAL_SOLD(this.CashDayShiftItems)) 
                     
                    let total_cashups =  this.MBS.actions.CALCULATE_TOTAL_CASHUPS(cashups)
                    let sales_cash_payments = total_cashups.sales_cash_payments
                    let invoices_cash_payments = total_cashups.invoices_cash_payments
                    
                    let closing_cash1 = SalesCashPayments+InvoiceCashPayments
                    let closing_cash2 = sales_cash_payments+invoices_cash_payments

                    let closing_cash = closing_cash1>closing_cash2?closing_cash1:closing_cash2 
                    let total_cash = closing_cash +Floating_cash
                    if (day_shift) { 
                        this.input = {
                            ...day_shift,
                            ...this.input,
                            day_shift_key:day_shift.key,
                            expected_cash:total_cash,
                            closing_cash:day_shift.total_closing_cash?day_shift.total_closing_cash:closing_cash
                        }
                        setTimeout(() => {
                            this.show_input = true  
                        }, 1000);
                    }else{
                        this.show_input = true
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SELECT_SHIFT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            READ_REPORT(){
                try {  
                    this.MBS.actions.go(-1)
                    setTimeout(() => {
                        let salesReport = this.MBS.actions.COMPANY_LINK("/day-shift-report/"+this.day_shift_key,true)
                        this.MBS.actions.go(salesReport) 
                    }, 5);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'READ_REPORT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            ON_PDF(){
                try { 
                     this.MBS.actions.dialog(null,true,false,
                        "PDF OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_PDF',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EXCEL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EXCEL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK")  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EMAIL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EMAIL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EMAIL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            async ON_PRINTING(){
                try {  
                    let printer = this.CurrentReceiptPrinter
                    let printer_name = printer?printer.name:null
                    let printer_type_name = printer?printer.printer_type_name:null
                    
                    // let day_shift = this.ThisDayShift 
                    let day_shift = this.Joined_DayShift 
                    
                    this.printing = false
                    setTimeout(() => {
                        if (printer_type_name) {
                            // this.auto_print = true
                        }
                        this.auto_printer_name = printer_name
                        this.print_data = day_shift 
                        this.printing = true   
                    }, 5); 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_PRINTING',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            async UPLOAD_LOCALS(){
                try {  
                    let res = await this.$store.dispatch("upload_locals")
                    console.log(res,'ress.................');
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPLOAD_LOCALS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            //---------------[SALES CALCULATIONS]------------ 
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let sold_price = this.toNumber(item.sold_price)
                    let discount = this.toNumber(item.discount)
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },   
            ORDER_ITEMS(orders){  
                try { 
                    if(!orders){return null} 
                    let items = []
                    orders.forEach(element => {
                        let joined_items = element.joined_items
                        if (joined_items) {
                            joined_items.forEach(item => {
                                items.push({
                                    ...item,
                                    account_type:element.account_type
                                }) 
                            });
                            
                        }
                    }); 
                    return items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ORDER_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            RETAIL_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(element.selling_model)) ==  
                        this.MBS.actions.TEXT_SPACE0("retail price")
                    });
                    return filtered_items
                } catch (error) { 
                    this.MBS.actions.error({
                        error:error,
                        from:'RETAIL_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            WHOLESALE_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(element.selling_model)) ==  
                        this.MBS.actions.TEXT_SPACE0("wholesale price")
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'WHOLESALE_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CASH_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "cash"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CASH_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_ACCOUNT_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "on_account"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_ACCOUNT_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            TOTAL_SOLD(items){
                try {  
                    let total = 0
                    if (items) {
                        items.forEach(element => {
                            let sold_price = this.toNumber(element.sold_price)
                            let quantity = this.toNumber(element.quantity)
                            let discount = this.toNumber(element.discount)
                            total = total+((sold_price-discount)*quantity)
                        }); 
                    }
                    return total
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CLOSE_REGISTER',
                        page:PAGE_NAME, 
                    })
                }  
            },  
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=CREATE-ORDER') {
                            if (action.YES) {
                                setTimeout(() => {
                                    console.log(action.data);
                                    this.SUBMIT_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })  
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: {
            search_item(value){ 
                if (value) {
                    this.ADD_ITEM(value) 
                }
            },
            input(value){   
                if (value) {
                    if (value.day_shift_key != this.day_shift_key) {
                        this.day_shift_key = value.day_shift_key
                        this.ON_SELECT_SHIFT(value)
                    }  
                } 
            },
            Joined_DayShift(value){    
                this.ON_SELECT_SHIFT(value)
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
