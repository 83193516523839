import Vue from 'vue'
import Vuex from 'vuex'
// import * as firebase from 'firebase'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
///import { FirebaseAuth } from
import ipLocation from 'iplocation'
import crypt from '../../plugins/crypto'
import DATA from './../../plugins/DATA' 
import DB from './../../plugins/DB'

let REF_USER = null
let REF_USERS = null
let REF_USER_DETAILS = null
let REF_USER_STATUSES = null 
let REF_USER_GROUP = null
let REF_USER_GROUPS = null
let REF_PERMISSIONS = null
let REF_VISITERS = null 

let REF_USER_STATUS = null 
let REF_CONNECT = null 

let NAME = "STORE-USER"

Vue.use(Vuex)

export default{
  state: {
    ONLINE_STATUS:null,
    USER:null,
    USER_DETAILS:null,
    MY_COMPANIES:null,
    USER_STATUSES:null,
    USERS:null,
    USER_GROUPS:null,
    PERMISSIONS:null,
    IP_LOCATION:null,
    IPs:{
      ip1:null,
      ip2:null
    },
    VISITERS:null,
    HISTORY:{
      full_history:[],
      visited_page:[]
    }
  },
  getters:{
    getUser(state){
      return state.USER
    },
    getUserDetails(state) {
      return state.USER_DETAILS
    },
    getUsers(state) {
      return state.USERS 
    }, 
    getUserByUid(state) {
      return key=>{ 
        if(!key){return null}
        if(!state.USERS){return null}
        return state.USERS.find(ct=>{
          return ct.key == key
        }) 
      }
    }, 
    getOnlineStatus(state) {
      return state.ONLINE_STATUS 
    }, 
    getUserStatuses(state) {
      return state.USER_STATUSES 
    }, 
    getUserStatusByKey(state) {
      return key=>{ 
        if(!key){return null}
        if(!state.USER_STATUSES){return null}
        return state.USER_STATUSES.find(ct=>{
          return ct.key == key
        }) 
      }
    }, 
    getUsersByPhoneNumber(state) {
      return phone_number=>{
        if(!phone_number){return null}
        if(!state.USERS){return null}
        return state.USERS.find(ct=>{
          return ct.phone_number == phone_number
        }) 
      }
    }, 
    getUserGroups(state) {
      return state.USER_GROUPS 
    }, 
    getUserGroupByKey(state) {
      return key=>{
        if(!key){return null}
        if(!state.USER_GROUPS){return null}
        return state.USER_GROUPS.find(ct=>{
          return ct.key == key
        }) 
      }
    }, 
    getUserGroupByCode(state) {
      return code=>{
        if(!code){return null}
        if(!state.USER_GROUPS){return null}
        return state.USER_GROUPS.find(ct=>{
          return ct.code == code
        }) 
      }
    }, 
    getPermissions(state) {
      return state.PERMISSIONS 
    }, 
    getUserPermissionByKey(state) {
      return key=>{
        if(!key){return null}
        if(!state.PERMISSIONS){return null}
        return state.PERMISSIONS.find(ct=>{
          return ct.key == key
        }) 
      }
    }, 
    getUserPermissionByCode(state) {
      return code=>{
        if(!code){return null}
        if(!state.PERMISSIONS){return null}
        return state.PERMISSIONS.find(ct=>{
          return ct.code == code
        }) 
      }
    },
    getIpLocation(state){
      return state.IP_LOCATION
    },
    getIpAddress(state){
      return state.IPs
    },
    getVisiters(state){
      return state.VISITERS
    }, 
    getHistory(state){
      return state.HISTORY
    }, 
    getMyCompanies(state) {
      let ud = state.USER_DETAILS
      let obj = ud?ud.companies:null
      if(!obj) {return null}
      if (typeof obj === 'object' && obj !== null) {
          let newData = []
          let i = 1
          for (const key in obj) {
              let item = obj[key]
              newData.push({
                  i:i,
                  key: key,
                  company_key: key,
                  ...item
              })
              i++
          }
          return newData
      }
      return null
    },
  },
  mutations: {
    setUserItems(state, payload) {
      try {
        let name = payload?payload.name:null
        let data = payload?payload.data:null
        state[name]=data  
      } catch (error) {
        console.log(error);
      }
    }, 
    updateUser(state, payload) {
      state.USER = payload
    },
    updateUserDetails(state, payload) {
      state.USER_DETAILS = payload
    }, 
  },
  actions: {
    async initialize_users_ref({commit,state}) {
      try {   
        // let linkPath =async (link)=>{
        //   return await dispatch("company_link",{link:link,company_key:company_key})
        // }
        let P_PERMISSION = DATA.PATHS.permissions
        let P_USERS = DATA.PATHS.users
        let P_USER_STATUS = DATA.PATHS.user_status
        let P_USER_GROUPS = DATA.PATHS.user_groups
        let P_VISITERS = DATA.PATHS.visiters
        REF_PERMISSIONS = firebase.database().ref(P_PERMISSION)
        REF_USERS = firebase.database().ref(P_USERS)
        REF_USER_GROUPS = firebase.database().ref(P_USER_GROUPS)
        REF_USER_STATUSES = firebase.database().ref(P_USER_STATUS)
        REF_VISITERS = firebase.database().ref(P_VISITERS) 
      } catch (error) {
        commit("ERROR",{
          error:error,
          from:'initializeItemRef',
          page:NAME, 
        })
      }
    }, 
    initializeUserByUidRef({commit,state}) {
      try {  
        let user = state.USER 
        let uid = user?user.uid:null
        if (uid) {
          REF_USER_DETAILS = firebase.database().ref('/'+DATA.ITEMS.USERS.values.toUpperCase()+'/'+uid)
          // REF_USER_GROUP = firebase.database().ref(DATA.APP.SERVER+'/SETTINGS/'+DATA.ITEMS.USER_GROUP.toUpperCase()+'/') 
          
        }
      } catch (error) {
        commit("ERROR",{
          error:error,
          from:'initializeItemRef',
          page:NAME, 
        })
      }
    }, 
    async connectUser1({commit,state},payload) {
      try {    
        // console.log('connectUser...........');
        let user = firebase.auth().currentUser
        let uid = null; 
        let isOffline= {
            online: false,
            last_changed: firebase.database.ServerValue.TIMESTAMP,
        }; 
        let isOnline = {
            online: true,
            last_changed: firebase.database.ServerValue.TIMESTAMP,
        }; 

        if (user) {
          isOffline.logged = true
          isOnline.logged = true
          uid = user?user.uid:null
        } else {  
          let res = await DB.getItems({
            name:DATA.ITEMS.USER_LIVES.values,
            key:DATA.APP.USER_TIMELINE.logged_out,
          }) 
          uid = res.data?res.data.uid:null
          isOffline.logout_date= res.data?res.data.date.toISOString():null
          isOnline.logout_date= res.data?res.data.date.toISOString():null
          isOffline.logged = false
          isOnline.logged = false  
        }

        firebase.database().ref('.info/connected').on('value', async function(snapshot) { 
          state.ONLINE_STATUS = snapshot.val();
          if (state.ONLINE_STATUS == false) {return;};  
          // console.log(user,uid,'connected.......');
          if (uid) {
            console.log(user,uid,'connected.......');
            var statusRef = firebase.database().ref(DATA.ITEMS.USER_STATUSES.values.toUpperCase() +'/'+ uid); 
            statusRef.onDisconnect().set(isOffline)
            .then(function() {  
                statusRef.set(isOnline);
                // console.log('isOnline....',isOnline);
            });
             
            statusRef.on('value', snapshot1=>{ 
              // console.log(user,uid,'statusRef.......'); TODO
              let online = snapshot1.val()?snapshot1.val().online:null
              let logged = snapshot1.val()?snapshot1.val().logged:null
              if(firebase.auth().currentUser){
                if (!online||!logged) { 
                    statusRef.set(isOnline); 
                    // console.log(firebase.auth().currentUser,'isOnline....22',isOnline);
                } 
              }
            })  
          } 
        });    
      } catch (error) {
        commit("ERROR",{
          error:error,
          from:'connectUser',
          page:NAME, 
        })
      }
    },  
    async connectUser({commit,state,dispatch},payload) {
      try {  
          // console.log('connectUser...........');
        let user = firebase.auth().currentUser
        let uid = null;   
         
        var isOffline = {
          online: false,
          last_changed: firebase.database.ServerValue.TIMESTAMP,
          version:DATA.APP.VERSION,
          version_name:DATA.APP.VERSION_NAME,
        }; 
        var isOnline = {
          online: true,
          last_changed: firebase.database.ServerValue.TIMESTAMP,
          version:DATA.APP.VERSION,
          version_name:DATA.APP.VERSION_NAME,
        }; 
        if (user) {
          isOffline.logged = true
          isOnline.logged = true
          uid = user?user.uid:null
        } else {  
          let res = await DB.getItems({
            name:DATA.ITEMS.USER_LIVES.values,
            key:DATA.APP.USER_TIMELINE.logged_out,
          }) 
          uid = res.data?res.data.uid:null
          isOffline.logout_date= res.data?res.data.date.toISOString():null
          isOnline.logout_date= res.data?res.data.date.toISOString():null
          isOffline.logged = false
          isOnline.logged = false  
        }

        if (REF_CONNECT) {
          console.log("off REF_CONNECT");
          REF_CONNECT.off("value")
        } 
        REF_CONNECT = firebase.database().ref('.info/connected');
        REF_CONNECT.on('value', function(snapshot) {
          state.ONLINE_STATUS = snapshot.val();
          if (state.ONLINE_STATUS == false) {return;};  
          dispatch("upload_locals",{online: snapshot.val()})
          if (uid) {
            // console.log(user,uid,'connected.......1111');
            if (REF_USER_STATUS) {
              //console.log("off REF_USER_STATUS");
              REF_USER_STATUS.off("value")
            }
            REF_USER_STATUS = firebase.database().ref(DATA.ITEMS.USER_STATUSES.values.toUpperCase() +'/'+ uid); 
            REF_USER_STATUS.onDisconnect().update(isOffline)
            .then(function() { 
              REF_USER_STATUS.update(isOnline);
              //console.log('Setting------------------....');
            });

            REF_USER_STATUS.on('value', snapshot1=>{ 
              let online = snapshot1.val()?snapshot1.val().online:null 
              let logged = snapshot1.val()?snapshot1.val().logged:null
              //console.log(online,'status change Ref.......');  
              if(firebase.auth().currentUser){
                if (!online||!logged) { 
                  REF_USER_STATUS.update(isOnline); 
                    //console.log('Setting------------------....22');
                } 
              }
              // console.log(snapshot1.val());
              // let ref_action = {
              //   name:DATA.ITEMS.USER_STATUSES.values, 
              //   // action:action,  
              //   cash:false,  
              // }
              // dispatch('f_save_user_items',{...ref_action,data:snapshot1})
            })  
            
          }
        });
        
      } catch (error) {
        commit("ERROR",{
          error:error,
          from:'connectUser',
          page:NAME, 
        })
      }
    }, 
     
    //-------------[SETTER]---------------
    //user
    setUser({ commit, state }, payload) {
      state.USER = payload
    }, 
    setUserDetails({ commit, state }, payload) {
      // state.USER_DETAILS = payload
      commit("updateUserDetails",payload) 
    }, 

    //---------------[CREATING USER]--------
    fi_createUserWithEmail({ commit}, payload){
      let email = payload.email
      let password = payload.password

      //not
      commit('updateLoading', true)
      commit('updateProcesses', {
        add: true,
        name: 'creating/user'
      })
      firebase.auth().createUserWithEmailAndPassword(email,password)
      .then(result=>{
        //not
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'creating/user'
        })
        commit('updateResponses', {
          res: 'creating/user/successful',
          val: result
        })

      })
      .catch(error=>{
        //not
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'creating/user'
        })
        commit('updateResponses', {
          res: 'creating/user/error',
          val: error
        })
        commit("ERROR",{
          page:NAME,
          from:"fi_createUserWithEmail",
          error:error
        })
      })
    },

    //---------------[SIGNING USER]---------
    //Number
    fi_signWithPhoneNumber({ commit}, payload){
      let appVerifier = payload.appVerifier
      let phoneNumber = payload.phoneNumber

      //not
      commit('updateLoading', true)
      commit('updateProcesses', {
        add: true,
        name: 'signing/withPhoneNumber'
      })

      firebase.auth().signInWithPhoneNumber(phoneNumber,appVerifier)
      .then(confirmationResult=>{

        //not success
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'signing/withPhoneNumber'
        })
        commit('updateResponses', {
          res: 'signing/withPhoneNumber/successful',
          val: confirmationResult
        })

      })
      .catch(error=>{
        //not error
        commit('updateLoading', false)
        commit('updateProcesses', {
          add: true,
          name: 'signing/withPhoneNumber'
        })
        commit('updateResponses', {
          res: 'signing/withPhoneNumber/error',
          val: error
        })
        commit("ERROR",{
          page:NAME,
          from:"fi_signWithPhoneNumber",
          error:error
        })
      })
    },
    //confirm
    fi_confirmPhoneNumber({commit},payload){
      let code = payload.code
      let confirmationResult = payload.confirmationResult

      //not
      commit('updateLoading', true)
      commit('updateProcesses', {
        add: true,
        name: 'signing/confirmPhoneNumber'
      })
      confirmationResult.confirm(code)
      .then(result=>{
        //not success
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'signing/confirmPhoneNumber'
        })
        commit('updateResponses', {
          res: 'signing/confirmPhoneNumber/successful',
          val: result
        })
      })
      .catch(error=>{
        //not error
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'signing/confirmPhoneNumber'
        })
        commit('updateResponses', {
          res: 'signing/confirmPhoneNumber/error',
          val: error
        })
        commit("ERROR",{
          page:NAME,
          from:"fi_confirmPhoneNumber",
          error:error
        })
        
      })
    },
    //Number
    fi_signWithEmail({ commit}, payload){ 
      return new Promise(async(resolve,reject) =>{
        try {
          let email = payload.email
          let password = payload.password
          //not
          commit('updateLoading', true)
          commit('updateProcesses', {
            add: true,
            name: 'signing/withEmail'
          })
          let data = await firebase.auth().signInWithEmailAndPassword(email,password)
          //not
          commit('updateLoading', false)
          commit('updateProcesses', { 
            name: 'signing/withEmail'
          })
          commit('updateResponses', {
            res: 'signing/withEmail/successful',
            val: data
          }) 
          resolve({
            response:"success",
            data:data
          })
        } catch (error) {
          commit('updateLoading', false)
          commit('updateProcesses', { 
            name: 'signing/withEmail'
          })
          commit('updateResponses', {
            res: 'signing/withEmail/error',
            val: error
          }) 
          commit("ERROR",{
            page:NAME,
            from:"fi_signWithEmail",
            error:error
          })
          reject({
            response:"fail",
            error:error
          })
        }
      })

      

    }, 
    //Gmail
    fi_signWithGmail({commit},payload) {
      //remember
      let GML = JSON.parse(localStorage.getItem('GML'));
      let date = new Date
      let detail = {
        date: date.toISOString(),
        gmail: true
      }
      if (GML === null) {
        localStorage.setItem('GML', JSON.stringify(detail));
      } else {
        localStorage.removeItem('GML')
        localStorage.setItem('GML', JSON.stringify(detail));
      }
      //
      var provider = new firebase.auth.GoogleAuthProvider()
      
      // provider.addScope('profile');
      // provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
      // provider.addScope('https://www.googleapis.com/auth/userinfo.email');
      // provider.addScope('https://www.googleapis.com/auth/plus.login');
      // provider.addScope('https://www.googleapis.com/auth/plus.me');
      // provider.addScope('https://www.googleapis.com/auth/user.birthday.read');
      // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
      // provider.addScope('email');
      
      //not
      commit('updateLoading', true)
      commit('updateProcesses', {
        add: true,
        value: 'signing/withGmail'
      })
      firebase.auth().signInWithRedirect(provider)
      //firebase.auth().signInWithPopup(provider)
      .then(function (result) {
          //not
          commit('updateLoading', false)
          commit('updateProcesses', { 
            name: 'signing/withGmail'
          })
          commit('updateResponses', {
            res: 'signing/withGmail/successful',
            val: result
          })
        if (result.credential) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken;
          // ...
        }
        // The signed-in user info.
        var user = result.user; 
      }).catch(function (error) {
        //not
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'signing/withGmail'
        })
        commit('updateResponses', {
          res: 'signing/withGmail/error',
          val: error
        })
        commit("ERROR",{
          page:NAME,
          from:"fi_signWithGmail",
          error:error
        })
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ... 
      });

    } ,
    fi_getGoogleResults(){
      firebase.auth().getRedirectResult().then(function (result) {
        if (result.credential) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken;
          // ...
        }
        // The signed-in user info.
        var user = result.user; 
        
      }).catch(function (error) {
        commit("ERROR",{
          page:NAME,
          from:"fi_getGoogleResults",
          error:error
        })
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ... 
        
      });
    },
    //Facebook
    fi_signWithFacebook() {
      //var provider = new firebase.auth.GoogleAuthProvider()
      var provider = new firebase.auth.FacebookAuthProvider()

      // provider.addScope('profile');
      // provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
      // provider.addScope('https://www.googleapis.com/auth/userinfo.email');
      // provider.addScope('https://www.googleapis.com/auth/plus.login');
      // provider.addScope('https://www.googleapis.com/auth/plus.me');
      // provider.addScope('https://www.googleapis.com/auth/user.birthday.read');
      // provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
      // provider.addScope('email');

      // firebase.auth().signInWithPopup(provider)
      firebase.auth().signInWithRedirect(provider)
        .then(function (result) {
          if (result.credential) {
            // This gives you a Google Access Token. You can use it to access the Google API.
            var token = result.credential.accessToken;
            // ...
          }
          // The signed-in user info.
          var user = result.user; 
        }).catch(function (error) {
          commit("ERROR",{
            page:NAME,
            from:"fi_signWithFacebook",
            error:error
          })
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          // ... 
        });

    },
    //Twitter
    fi_signWithTwitter() { 
      var provider = new firebase.auth.TwitterAuthProvider()
      // firebase.auth().signInWithPopup(provider)
      firebase.auth().signInWithRedirect(provider)
        .then(function (result) {
          if (result.credential) {
            // This gives you a Google Access Token. You can use it to access the Google API.
            var token = result.credential.accessToken;
            // ...
          }
          // The signed-in user info.
          var user = result.user; 
        }).catch(function (error) {
          commit("ERROR",{
            page:NAME,
            from:"fi_signWithTwitter",
            error:error
          })
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          // ...
          console.log(error, 'eeeeeee');
        });

    },
    //--------------[Recaptach]--------
    fi_createRecaptach(){

    },

    //--------------[SIGN OUT USER]--------
    fi_signUserOut({ commit, state }, payload) { 
      //not 
      commit('updateLoading', true)
      commit('updateProcesses', {
        add: true,
        value: 'signing/out'
      })
      firebase.auth().signOut()
      .then(results=>{  
        return DB.addItems({
          name:DATA.ITEMS.USER_LIVES.values,
          data:{
            key:DATA.APP.USER_TIMELINE.logged_out,
            date:new Date(),
            uid:state.USER_DETAILS?state.USER_DETAILS.key:null
          }
        })
      }) 
      .then(res=>{
        return DB.deleteItems({
          name:DATA.ITEMS.USER_LIVES.values,
          key:DATA.APP.USER_TIMELINE.action_request
        })
      })
      .then(results=>{ 
        return DB.deleteItems({
          name:DATA.ITEMS.USER_LIVES.values,
          key:DATA.APP.USER_TIMELINE.current
        })
      })   
      .then(result=>{ 
        state.USER = null
        state.USER_DETAILS = null

        //not
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'signing/out'
        })
        commit('updateResponses', {
          res: 'signing/out/successful',
          val: result
        })
      }).catch(error=>{
        //not
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'signing/out'
        })
        commit('updateResponses', {
          res: 'signing/out/error',
          val: error
        })
        commit("ERROR",{
          page:NAME,
          from:"fi_signUserOut",
          error:error
        })
      });
    },

    //---------[SEND VERIFICATION 2 USER]--------
    //send verification email
    fi_sendVerificationEmail({ commit, state }, payload){
      let user = state.USER

      //not
      commit('updateLoading', true)
      commit('updateProcesses', {
        add: true,
        name: 'sending/verificationEmail'
      })
      user.sendEmailVerification()
      .then(result=>{
        //not success
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'sending/verificationEmail'
        })
        commit('updateResponses', {
          res: 'sending/verificationEmail/successful',
          val: result
        })
      })
      .catch(error=>{
        //not success
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'sending/verificationEmail'
        })
        commit('updateResponses', {
          res: 'sending/verificationEmail/error',
          val: error
        })
        commit("ERROR",{
          page:NAME,
          from:"fi_sendVerificationEmail",
          error:error
        })
      })
    },
    //send password rest email
    fi_sendPasswordResetEmail({ commit, state }, payload){
      let email = payload.email
      let auth = firebase.auth()

      //not
      commit('updateLoading', true)
      commit('updateProcesses', {
        add: true,
        name: 'sending/resetEmail'
      })
      auth.sendPasswordResetEmail(email)
      .then(result=>{
        //not success
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'sending/resetEmail'
        })
        commit('updateResponses', {
          res: 'sending/resetEmail/successful',
          val: result
        })
        
      })
      .catch(error=>{
        //not success
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'sending/resetEmail'
        })
        commit('updateResponses', {
          res: 'sending/resetEmail/error',
          val: error
        })
        commit("ERROR",{
          page:NAME,
          from:"fi_sendPasswordResetEmail",
          error:error
        })
      })
    },

    //---------------[UPDATE USER / CODE]--------
    //email 
    fi_updateUserEmail({commit,state},payload){
      let user = state.USER
      let details = payload.data
      let date = new Date
      date = date.toISOString()
      details = { ...details, updated_at:date }
      let email = details.email 

      //not
      commit('updateLoading', true)
      commit('updateProcesses', {
        add: true,
        name: 'updating/userEmail'
      })
      user.updateEmail(email) 
      .then(result=>{
        return firebase.database().ref('USERS/' + user.uid)
        .update(details)
      })
      .then(result=>{
        //not success
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'updating/userEmail'
        })
        commit('updateResponses', {
          res: 'updating/userEmail/successful',
          val: result
        })
      })
      .catch(error=>{
        //not error
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'updating/userEmail'
        })
        commit('updateResponses', {
          res: 'updating/userEmail/error',
          val: error
        })
        commit("ERROR",{
          page:NAME,
          from:"fi_updateUserDetails",
          error:error
        })
      })
    },
    //password
    fi_updateUserPassword({commit,state},payload){
      let user = state.USER 
      let details = payload.data
      let date = new Date
      date = date.toISOString()
      details = { ...details, updated_at:date }
      let email = details.email
      let p_uid = details.p_uid
      let p_uid2 = details.p_uid2
      //not
      commit('updateLoading', true)
      commit('updateProcesses', {
        add: true,
        name: 'updating/userPassword'
      })
      firebase.auth().signInWithEmailAndPassword(email,p_uid2)
      .then(res=>{
        return user.updatePassword(p_uid)
      })
      .then(result=>{
        return firebase.database().ref('USERS/' + user.uid)
        .update(details)
      })
      .then(result=>{
        //not success
        commit('updateLoading', false)
        commit('updateProcesses', {
          action: 'remove',
          value: 'updating/userPassword'
        })
        commit('updateResponses', {
          res: 'updating/userPassword/successful',
          val: result
        })
      })
      .catch(error=>{
        //not error
        commit('updateLoading', false)
        commit('updateProcesses', {
          action: 'remove',
          value: 'updating/userPassword'
        })
        commit('updateResponses', {
          res: 'updating/userPassword/error',
          val: error
        })
        commit("ERROR",{
          page:NAME,
          from:"fi_updateUserPassword",
          error:error
        })
      })
    },
    //email and password
    fi_updateUser({commit,state},payload){
      return new Promise((resolve,reject)=>{
        let response={
          from:"fi_updateUser",
          page:NAME,
          payload:payload
        }
        try {
          let user = state.USER 
          let date = new Date
          date = date.toISOString() 
          let details = {
            updated_at:date
          }
  
          let current_email = payload?payload.current_email:null
          let new_email = payload?payload.new_email:null 
          let current_password = payload?payload.current_password:null
          let new_password = payload?payload.new_password:null
          let names = payload?payload.names:null
          let surname = payload?payload.surname:null
    
          //not
          commit('updateLoading', true)
          commit('updateProcesses', {
            add: true,
            name: 'updating/fi_updateUser'
          })
          firebase.auth().signInWithEmailAndPassword(current_email,current_password)
          .then(res=>{
            if (!new_email) {
              return user
            }
            return user.updateEmail(new_email)
          }) 
          .then(res=>{
            if (!new_password) {
              return user
            }
            return user.updatePassword(new_password)
          })
          .then(res=>{
            if (!names || !surname) {
              return user
            }
            return user.updateProfile({
              displayName:surname+' '+names
            })
          })
          .then(res=>{
            if (new_password) {
              details.p_uid = crypt.encrypt(new_password)
              details.p_uid2 = crypt.encrypt(current_password)
            }if (new_email) {
              details.email = new_email
            }if (names && surname) {
              details.names = names
              details.surname =surname
            }
            return firebase.database().ref('USERS/' + user.uid)
            .update(details)
          })
          .then(res=>{
            //not success
            commit('updateLoading', false)
            commit('updateProcesses', { 
              name: 'updating/fi_updateUser'
            })
            commit('updateResponses', {
              res: 'updating/fi_updateUser/successful',
              val: res
            })
            resolve({
              success:true,
              data:res
            })
          })
          .catch(error=>{ 
            commit('updateLoading', false)
            commit('updateProcesses', { 
              name: 'updating/fi_updateUser'
            })
            commit('updateResponses', {
              res: 'updating/fi_updateUser/error',
              val: error
            })
            commit("ERROR",{
              ...response,
              error:error
            })
            reject({
              ...response,
              error:error
            })
          }) 
          
        } catch (error) {
          commit('updateLoading', false)
            commit('updateProcesses', { 
              name: 'updating/fi_updateUser'
            })
            commit('updateResponses', {
              res: 'updating/fi_updateUser/error',
              val: error
            })
            commit("ERROR",{
              ...response,
              error:error
            })
            reject({
              ...response,
              error:error
            })
        }
      })
    },
    //profile
    fi_updateUserProfile({commit,state},payload){
      let user = state.USER
      let details = payload.data
      let date = new Date
      date = date.toISOString() 
      details = { ...details, updated_at:date }
      let names = details.names
      let surname = details.surname

      //not
      commit('updateLoading', true)
      commit('updateProcesses', {
        add: true,
        name: 'updating/userProfile'
      })
      user.updateProfile({
        displayName:surname+' '+names
      })
      .then(()=>{
        return firebase.database().ref('USERS/' + user.uid)
        .update(details)
      })
      .then(result=>{
        //not success
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'updating/userProfile'
        })
        commit('updateResponses', {
          res: 'updating/userProfile/successful',
          val: result
        })
      })
      .catch(error=>{
        //not error
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'updating/userProfile'
        })
        commit('updateResponses', {
          res: 'updating/userProfile/error',
          val: error
        })
        commit("ERROR",{
          page:NAME,
          from:"fi_updateUserProfile",
          error:error
        })
      })
    }, 
    //profile pic
    fi_updateUserProfilePicture({ commit , state },payload){
       
      // create image from base64
      function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;

        if (dataURI.split(',')[0].indexOf('base64') >= 0)
          byteString = atob(dataURI.split(',')[1]);
        else
          byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
      } 


      let details = payload.data
      let date = new Date
      date = date.toISOString()
      details = { ...details, updated_at:date }
      let image = details.image
      let newImage = dataURItoBlob(image);
      let uid = state.USER?state.USER.uid:null
      let ext = 'jpeg'
      let storeRef = firebase.storage().ref()
      let uploadTask = null
      let pNum =0
      let photoURL=''

      //not
      commit('updateLoading', true)
      commit('updateProcesses', {
        add: true,
        name: 'uploading/profilePicture',
        val: 1
      })

      if (uid!=null) {
        // Create the file metadata
        let metadata={
          contentType:'image/'+ext
        }
        //upload image and metadata extension  
        uploadTask = storeRef.child(DATA.APP.SERVER+'/USER-PROFILE/'+uid+"."+ext).put(newImage,metadata)

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,snapshot=>{
          //TASK PROGRESS, INCLUDING THE NUMBER OF BYTES UPLOADED
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes)*100
          pNum= 0 + progress 
          
          commit('updateProcesses', {
            add: true,
            name: 'uploading/profilePicture',
            val: pNum
          })  
          switch(snapshot.state){
            case firebase.storage.TaskState.PAUSED:  // or 'paused'
              commit('updateResponses', {
                res: 'uploading/profilePicture/state',
                val: 'paused'
              }) 
              break
            case firebase.storage.TaskState.RUNNING: // or 'running'
              commit('updateResponses', {
                res: 'uploading/profilePicture/state',
                val: 'running'
              }) 
              break; 
          }

        },error=>{
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          commit('updateLoading', false)
          commit('updateProcesses', { 
            name: 'uploading/profilePicture',
          })
          commit('updateResponses', {
            res: 'uploading/profilePicture/error',
            val: error
          })
          commit("ERROR",{
            page:NAME,
            from:"fi_updateUserProfilePicture",
            error:error
          })
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object 
              break 
            case 'storage/canceled':
              // User canceled the upload 
              break 
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse 
              break;
          }
        },()=>{
          // Upload completed successfully, now we can get the download URL 
          uploadTask.snapshot.ref.getDownloadURL() 
          .then(function (downloadURL) { 
            photoURL = downloadURL
            return state.USER.updateProfile({ photoURL: photoURL})
          })
          .then(res=>{
            return firebase.database().ref('USERS/' +uid).update({ 
              ...details,
              photoURL: photoURL,
            })
          })
          .then(data=>{
            commit('updateLoading', false)
            commit('updateProcesses', { 
              name: 'uploading/profilePicture'
            })
            commit('updateResponses', {
              res: 'uploading/profilePicture/success',
              val: data
            })
          }).catch(error=>{
            commit("ERROR",{
              page:NAME,
              from:"fi_updateUserProfilePicture",
              error:error
            })
            commit('updateLoading', false)
            commit('updateProcesses', {
              name: 'uploading/profilePicture'
            })
            commit('updateResponses', {
              res: 'uploading/profilePicture/error',
              val: error
            })
          })
        }) 
      }else{ 
        commit("ERROR",{
          page:NAME,
          from:"fi_updateUserProfilePicture",
          error:"No user"
        })
        commit('updateLoading', false) 
        commit('updateProcesses', {
          name: 'uploading/profilePicture'
        })
        commit('updateResponses', {
          res: 'uploading/profilePicture/error',
          val: 'no user'
        })
      }
    }, 
    //detail
    fi_updateUserDetails({ commit, state }, payload) {
      let user = state.USER
      let details = payload.data

      //not
      commit('updateLoading', true)
      commit('updateProcesses', {
        add: true,
        name: 'updating/userDetails'
      })
      firebase.database().ref('USERS/' + user.uid).update(details)
      .then(result => {
        //not success
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'updating/userDetails'
        })
        commit('updateResponses', {
          res: 'updating/userDetails/successful',
          val: result
        })
      }).catch(error => { 
        //not success
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'updating/userDetails'
        })
        commit('updateResponses', {
          res: 'updating/userDetails/error',
          val: error
        })
        commit("ERROR",{
          page:NAME,
          from:"fi_updateUserDetails",
          error:error
        })
      })

    },   
    

    //----------------[DELETE]----------
    //user
    DELETE_USER({ commit,state}, payload){
      return new Promise(async(resolve,reject)=>{
        let response = null
        try {  
          let http = this._vm.$http  
          let phone_number = payload.phone_number 
          let email = payload.email 
          let date = new Date()  
          if (phone_number) {
            commit('updateLoading', true)
            commit('updateProcesses', {
              add: true,
              name: 'deleting/user'
            })
            let prams = {
              phone_number:phone_number,
              email:email,
              key:DATA.APP.key.apiKey,
            }
            try { 
              let res = await http.post(DATA.APP.SERVER_LINK+'app/deleteUser',prams)
              let body = res?res.body:null
              let deleted_user = body?body.user:null
              let deleted_uid = deleted_user?deleted_user.uid:null 
  
              commit('updateLoading', false)
              commit('updateProcesses', { 
                name: 'deleting/user'
              })
              commit('updateResponses', {
                res: 'deleting/user/successful',
                val: res
              }) 
              response={
                body:body,
                deleted:body?body.deleted:false,
                deleted_uid:deleted_uid
              }
              resolve(response) 
            } catch (error) {
              commit("ERROR",{
                page:NAME,
                from:"DELETE_USER",
                error:{
                  error:error,
                  prams:prams,
                  payload:payload
                }
              })
              reject({
                error:error,
                prams:prams,
                payload:payload
              })
            } 
          } else {
            commit("ERROR",{
              page:NAME,
              from:"fi_add_visiter",
              error:{
                error:'no phone number',
                payload:payload
              }
            })
            reject({
              error:'no phone number',
              payload:payload
            })
          } 
        } catch (error) {
          commit("ERROR",{
            page:NAME,
            from:"DELETE_USER",
            error:error
          })
          reject(error)
        }  
      }) 
    }, 
    fi_deleteUserWithEmail({ commit,state}, payload){
      let USER = state.USER
      let USER_DETAILS = state.USER_DETAILS
      let uid = USER?USER.uid:null

      let key = payload.key
      let email = payload.email
      let p_uid = payload.p_uid
      let p_uid2 = crypt.decrypt(p_uid) 
      let dt = new Date
      let date = dt.toISOString()
      let STEPS = 0
       
      //not
      commit('updateLoading', true)
      commit('updateProcesses', {
        add: true,
        name: 'deleting/user'
      })

      DB.addItems({
        name:DATA.ITEMS.USER_LIVES.values,
          data:{
          key:DATA.APP.USER_TIMELINE.action_request,
          date:date,
          uid:uid
        }
      })
      .then(result=>{
        STEPS = 1
        return firebase.auth().signInWithEmailAndPassword(email,p_uid2)
      }) 
      .then(result=>{ 
        //add details
        STEPS = 2
        let us = result.user  
        return us.delete()
      })
      .then(result=>{
        STEPS = 3
        return firebase.database().ref('USERS/'+key).set(null)
      })
      .then(result=>{ 
        //sign user back 
        STEPS = 4
        let logEmail = USER_DETAILS.email
        let logPassword = crypt.decrypt(USER_DETAILS.p_uid) 
        return firebase.auth().signInWithEmailAndPassword(logEmail,logPassword)
      })
      .then(result=>{  
        return DB.addItems({
          name:DATA.ITEMS.USER_LIVES.values,
            data:{
              key:DATA.APP.USER_TIMELINE.action_request,
              date:new Date(),
              uid:null
            }
          })
        })
      .then(res=>{ 
        //success
        STEPS = 5
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'deleting/user'
        })
        commit('updateResponses', {
          res: 'deleting/user/successful',
          val: res
        }) 
      })
      .catch(error=>{
        console.log(payload,'payload.........');
        console.log(error,STEPS,'eeeeeeeeee');
        //error
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'deleting/user'
        })
        commit('updateResponses', {
          res: 'deleting/user/error',
          val: error
        })
      })
    }, 
    fi_deleteUser({ commit,dispatch, state }, payload){ 
      let user = state.USER 

      //not
      commit('updateLoading', true)
      commit('updateProcesses', {
        add: true,
        name: 'deleting/user'
      })
      user.delete()
      .then(result=>{
        //not success
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'deleting/user'
        })
        commit('updateResponses', {
          res: 'deleting/user/successful',
          val: result
        })
        dispatch('fi_signOut')
      })
      .catch(error=>{
        //not success
        commit('updateLoading', false)
        commit('updateProcesses', { 
          name: 'deleting/user'
        })
        commit('updateResponses', {
          res: 'deleting/user/error',
          val: error
        })
        commit("ERROR",{
          page:NAME,
          from:"fi_deleteUser",
          error:error
        })
      })
    },
    fi_deleteUserProfilePicture({commit,state},payload){ 
      let uid = state.USER.uid
      let ext = 'jpeg'
      let storeRef = firebase.storage().ref() 

      //not
      commit('updateLoading', true)
      commit('updateProcesses', {
        add: true,
        name: 'deleting/profilePicture' 
      })
      if (uid!=null) {   
        storeRef.child(DATA.APP.SERVER+'/USER-PROFILE/'+uid+"."+ext).delete() 
        .then( ()=> { 
          return state.USER.updateProfile({
            photoURL: null
          })
        })
        .then(res => {
          return firebase.database().ref('USERS/' + uid).update({
            photoURL: null
          })
        })
        .then(data => {
          commit('updateLoading', false)
          commit('updateProcesses', { 
            name: 'deleting/profilePicture' 
          })
          commit('updateResponses', {
            res: 'deleting/profilePicture/success',
            val: data
          })
        }).catch(error => { 
          commit('updateLoading', false)
          commit('updateProcesses', {
            name: 'deleting/profilePicture' 
          })
          commit('updateResponses', {
            res: 'deleting/profilePicture/error',
            val: error
          })
          commit("ERROR",{
            page:NAME,
            from:"fi_deleteUserProfilePicture",
            error:error
          })
        })
         

      }else{
        console.log('Unable to upload image') 
        commit('updateLoading', false) 
        commit('updateProcesses', {
          name: 'deleting/profilePicture' 
        })
        commit('updateResponses', {
          res: 'uploading/profilePicture/error',
          val: 'no user'
        })
        commit("ERROR",{
          page:NAME,
          from:"fi_deleteUserProfilePicture",
          error:'no user'
        })
      }
    },  

    //---------------[FIRE ADD]-----------
    //visiter
    fi_add_visiter({ commit, state }, payload){
      //not
      commit('updateLoading', true)
      commit('updateProcesses', {
        add: true,
        name: 'adding/visiter'
      })
      let date = new Date
      let data = payload.data

      //GET USER IP ADDRESS 
      let http = this._vm.$http
      http.get("https://httpbin.org/ip")
      .then(res=>{
        let ips = res.body.origin 
        if (ips.includes(",")) {
          let ip1 = ips.split(",")[0]
          let ip2 = ips.split(",")[1]
          state.IPs.ip1 = ip1
          state.IPs.ip2 = ip2 
        } else {
          state.IPs.ip1 = ips
        } 
        return state.IPs
      },error=>{
        commit("ERROR",{
          page:NAME,
          from:"fi_add_visiter",
          error:error
        })
        return "0.0.0.0"
      }).then(ips=>{
        return ipLocation(ips.ip1)
      }).then(res=>{
        state.IP_LOCATION = res 

        //------ADD VISITER
        let VISITER = JSON.parse(localStorage.getItem(DATA.APP.SERVER+'_VISITER')) 
        if (VISITER===null) {    
          let visiter ={
            ...data,
            date: date.toISOString()
          } 
          let visiterKey = null
          firebase.database().ref(DATA.APP.SERVER+'/VISITERS/').push(visiter)
          .then(data=>{
            // Data saved successfully!
            visiterKey = data.key
            commit('updateLoading', false)
            commit('updateProcesses', { 
              name: 'adding/visiter'
            })
            commit('updateResponses', {
              res: 'adding/visiter/successful',
              val: data
            })
            let vst={
              date: date.toISOString(),
              key:data.key
            }  
            return localStorage.setItem(DATA.APP.SERVER+'_VISITER', JSON.stringify(vst)) 
          })
          .then(data=>{ 
            return state.IP_LOCATION
          })
          .then(res=>{ 
            return firebase.database().ref(DATA.APP.SERVER+'/VISITERS/' + visiterKey + '/location').set(res)
          }) 
          .then(res=>{ 
            commit('updateLoading', false)
            commit('updateProcesses', { 
              name: 'adding/visiter'
            })
            commit('updateResponses', {
              res: 'adding/visiter/successful',
              val: res
            })
          })
          .catch(error=>{
            commit('updateLoading', false)
            commit('updateProcesses', { 
              name: 'adding/visiter'
            })
            commit('updateResponses', {
              res: 'adding/visiter/error',
              val: error
            })
            commit("ERROR",{
              page:NAME,
              from:"fi_deleteUser",
              error:error
            })
          })
          
        }else{
          let vst = { 
            date: date.toISOString()
          }
          firebase.database().ref(DATA.APP.SERVER+'/VISITERS/'+VISITER.key+'/revisit').push(vst)
            .then(data => {
              commit('updateLoading', false)
              commit('updateProcesses', { 
                name: 'adding/visiter'
              })
              commit('updateResponses', {
                res: 'adding/visiter/successful',
                val: data
              })
            }).catch(error => {
              commit('updateLoading', false)
              commit('updateProcesses', {
                action: 'remove',
                value: 'adding/visiter'
              })
              commit('updateResponses', {
                res: 'adding/visiter/error',
                val: error
              })
              commit("ERROR",{
                page:NAME,
                from:"fi_add_visiter",
                error:error
              })
            })
           
        }
      }).catch(error => {
        commit("ERROR",{
          page:NAME,
          from:"fi_add_visiter",
          error:error
        })
      }) 
      
    }, 
    //visiter
    add_history({ commit, state }, payload){
      //not
      commit('updateLoading', true)
      commit('updateProcesses', {
        add: true,
        name: 'adding/history'
      })
      let date = new Date
      let data = payload.data
      let newStory = {
        date:date.toISOString(),
        name:data.name?data.name:"404",
        fullPath:data.fullPath
      }
      if (state.HISTORY.visited_page) {
        let page = false
        state.HISTORY.visited_page.forEach(element => {
          if (element.name == newStory.name) {
            page = true
          }
        }); 
        if (!page) {
          state.HISTORY.visited_page.push(newStory)
        }
      }else{
        state.HISTORY.visited_page=[]
        state.HISTORY.full_history=[]
        state.HISTORY.visited_page.push(newStory)
      }
      state.HISTORY.full_history.push(newStory)
      commit('updateProcesses', { 
        name: 'adding/history'
      }) 

      //console.log(newStory,'mmmmmmmmmm');

      // let MBS_HISTORY = JSON.parse(localStorage.getItem('MBS_HISTORY')) 
      // localStorage.setItem('MBS_HISTORY', JSON.stringify(vst)) 
      
    },    

    //-----------------[ SAVE ]--------------\ 
    async f_save_user_items({commit,dispatch,state},payload){
      return new Promise(async (resolve,reject)=>{
        let response = {
          from:'f_save_user_items',
          page:NAME, 
          payload:payload
        }
        try { 
          let name = payload?.name
          let action = payload?.action
          let data = payload?.data
          let cash = payload?.cash 
          let company_key = payload?.company_key 
          let local_data = payload?.local_data 
          let user_details_data = await DB.getUserDetails()  
          let  user_details = user_details_data?user_details_data.user_details:null
          let  current_uid = user_details_data?user_details_data.current_uid:null
          let  action_request_uid = user_details_data?user_details_data.action_request_uid:null
          let  logged_uid = user_details_data?user_details_data.user_details:null
          
  
          if (!name || !data) {
            response.error = "no name"
            commit("ERROR",response)
            reject(response)
            return
          } 
          if (!action) {
            action = name
          } 
          commit('updateProcesses', {name: 'getting/'+action})  
          // let obj = data.val() 
          let obj = data
          let items = [] 

          //INITIATE ITEMS
          if (name.toUpperCase()===DATA.ITEMS.USER_DETAILS.values.toUpperCase()) {
            if (local_data) {
              items = data?data[0]:null
            } else {
              items = {
                key:data.key,
                ...obj,
              }  
            }
          }else{  
            let i = 1
            for (const key in obj) {
              let item = obj[key] 
              items.push({
                i:i,
                key:key,
                ...item
              })
              i++
            } 
          } 
 
          //SAVE DATA
          if (name.toUpperCase()===DATA.ITEMS.USER_DETAILS.values.toUpperCase()) {   
            //save companies
            let companies_ob = items?.companies
            const companies = companies_ob?Object.entries(companies_ob).map(([key,value])=>({
              ...value,
              key:key
            })):null
            commit("setUserItems",{
              name:DATA.ITEMS.MY_COMPANIES.values.toUpperCase(),
              data:companies
            })

            //save user
            let userLive = async (user)=>{ 
              // console.log(user,'user-------------------i-');
              // console.log(action_request_uid,'action_request_uid-------------------i-');
              // console.log(current_uid,'current_uid-------------------i-');
              
              if (action_request_uid == user.key) {
                // state.USER_DETAILS = user  
                commit("setUserItems",{
                  name:name.toUpperCase(),
                  data:user
                })   
              }else if (current_uid == user.key) {
                // state.USER_DETAILS = user  
                commit("setUserItems",{
                  name:name.toUpperCase(),
                  data:user
                })   
              }else{
                commit("ERROR",{
                  error:{
                    error:DATA.ITEMS.USER_LIVES.values,
                    user:user,
                    user_details_data:user_details_data, 
                  },
                  from:'f_save_user_items',
                  page:NAME, 
                })
              } 
            }
            userLive(items)
          }else if(name){
            commit("setUserItems",{
              name:name.toUpperCase(),
              data:items
            })
          }else{
            response.error = "name not found"
            commit("ERROR",response)
            reject(response)
          } 

          if (cash && !local_data) { 
            let addDB = null
            if (name.toUpperCase()===DATA.ITEMS.USER_DETAILS.values.toUpperCase()) {
              if (items) {
                items.key = items.uid
              }
              addDB =  DB.addItems({
                name:name,
                data:items,
                // company_key:company_key,
                clear:false,
              })
            } else {
              addDB =  DB.addItems({
                name:name,
                data:items,
                company_key:company_key,
                clear:company_key ?false:true,
              })
            }
            addDB.then(response=>{
              //success
            }).catch(error=>{
              commit("ERROR",{
                error:error,
                from:'f_save_user_items',
                page:NAME, 
              })
            }) 
          } 
          resolve({success:true})
        } catch (error) { 
          response.error = error 
          commit("ERROR",response)
          reject.apply(response)
        } 
      })
    },  

    //-----------------[ FI GET ]--------------\ 
    async fi_get_user_items({ commit,dispatch},payload) {
      return new Promise(async(resolve,reject)=>{
        let response = {
          from:'fi_get_user_items',
          page:NAME, 
          payload:payload
        }
        try {  
          let name = payload?payload.name:null
          let action = payload?payload.action:null
          let on = payload?payload.on:null 
          let cash = payload?payload.cash:null 
          let get = payload?payload.get:null 
          let company_key = payload.company_key 
          let is_not_array = false
          let ITEM_REF = null 
          if (name) { 
            setTimeout(async () => {
              if (!action) {action=name} 
              commit('updateProcesses', {
                add: true,
                name: 'getting/'+name
              }) 
              let ITEM_REF = null 
              if (name.toUpperCase()===DATA.ITEMS.USERS.values.toUpperCase()) { 
                ITEM_REF = REF_USERS  
              }else if (name.toUpperCase()===DATA.ITEMS.USER_STATUSES.values.toUpperCase()) { 
                ITEM_REF = REF_USER_STATUSES
              }else if (name.toUpperCase()===DATA.ITEMS.USER_DETAILS.values.toUpperCase()) {  
                ITEM_REF = REF_USER_DETAILS 
              }else if (name.toUpperCase()===DATA.ITEMS.USER_GROUPS.values.toUpperCase()) {
                ITEM_REF = REF_USER_GROUPS 
              }else if (name.toUpperCase()===DATA.ITEMS.PERMISSIONS.values.toUpperCase()) { 
                ITEM_REF = REF_PERMISSIONS
              }else if (name.toUpperCase()===DATA.ITEMS.VISITERS.values.toUpperCase()) { 
                ITEM_REF = REF_VISITERS
              } 
    
              let ref_action = {
                name:name, 
                action:action,  
                cash:cash, 
                on:on, 
                get:get, 
                company_key:company_key,   
                ref:ITEM_REF
              }
    
    
              //LOCAL GET
              if (get) { 
                let res = await DB.getItems({
                  name:name,
                  company_key:company_key,
                  // update_store:true,
                }) 
                let data_details = {
                  ...ref_action,
                  data:res?res.data:null,
                  res:res, 
                  local_data:true, 
                  is_not_array:is_not_array
                } 
                await dispatch('f_save_user_items',data_details) 
              }
              
              //GET REF
              if (ITEM_REF) { 
                if (on) {  
                  ITEM_REF.on('value', async snapshot => { 
                    let fi_data = snapshot
                    let data = snapshot.val()
                    let data_details = {
                      ...ref_action,
                      data:data,
                      fi_data:fi_data, 
                      is_not_array:is_not_array
                    }
                    await dispatch('f_save_user_items',data_details)  
                    resolve({data_details,success:true})   
                  }) 
                } else {   
                  ITEM_REF.once('value').then(async snapshot => {
                    let fi_data = snapshot
                    let data = snapshot.val()
                    let data_details = {
                      ...ref_action,
                      data:data,
                      fi_data:fi_data, 
                      is_not_array:is_not_array
                    } 
                    await dispatch('f_save_user_items',data_details) 
                    resolve({success:true,data:data,fi_data:fi_data}) 
                  }).catch(error=>{
                    response.error = error 
                    reject(response)
                  })
                }      
              }else{ 
                commit("ERROR",{
                  error:{
                    error:'name not found',
                    data:payload
                  },
                  from:'fi_get_user_items',
                  page:NAME, 
                })
              }    
            }, 10);
          } else {
            response.error = "no name"
            commit("ERROR",response)
            reject(response)
          }
        } catch (error) { 
          response.error = error
          commit("ERROR",response)
          reject(response)
        }

      })
    },  

  }
}
