import Vue from 'vue'
//import * as firebase from 'firebase'
import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
// import 'firebase/messaging'
// import 'firebase/functions'
import { store } from '../store'
import DATA from './DATA'
import DB from './DB';
let PAGE_NAME = 'INITIATE_FIREBASE'

new Vue({
  firebase,
  store,
  async created() {
    try {
      DB.deleteItems({
        name:DATA.ITEMS.USER_LIVES.values,
        key:DATA.APP.USER_TIMELINE.logged_out
      })
      firebase.initializeApp(DATA.APP.key)
  
      await store.dispatch('initialize_users_ref')
      await store.dispatch('initialize_items_ref')   
      await store.dispatch('initiate_locals',{
        names:[
          DATA.ITEMS.LOCAL_DEVICES.values, 
        ] 
      }) 
      
      //----------- LOGIN USER-----------------
      store.dispatch('setLoading', true)
      store.dispatch('setProcesses', {
        add: true,
        name: 'logging'
      })
      firebase.auth().onAuthStateChanged(async user => { 
        try { 
          let date = new Date() 
          store.dispatch('setLoggedTime',date)
          store.dispatch('setUser',user)
          store.dispatch('setProcesses', { 
            name: 'logging'
          })  
          if (user) {   
            store.dispatch('initializeUserByUidRef') 
            store.dispatch('connectUser',{user:user}) 
            await DB.addItems({
              name:DATA.ITEMS.USER_LIVES.values,
              data:{
                key:DATA.APP.USER_TIMELINE.current,
                date:date,
                uid:user.uid 
              },
            }) 
            await DB.deleteItems({
              name:DATA.ITEMS.USER_LIVES.values,
              key:DATA.APP.USER_TIMELINE.logged_out
            })

            //----------- GET LOCALS -----------------
            store.dispatch('initiate_locals',{
              // names:[
              //   DATA.ITEMS.LOCAL_QUOTATIONS.values,
              //   DATA.ITEMS.LOCAL_CASHUPS.values,
              //   DATA.ITEMS.LOCAL_DAYS_SHIFTS.values,
              // ] 
            }) 
            //----------- GET USER DATA ----------------- 
            store.dispatch('fi_get_user_items',{
              name:DATA.ITEMS.USERS.values,
              on:true,
            }) 
            store.dispatch('fi_get_user_items',{
              name:DATA.ITEMS.USER_STATUSES.values,
              on:true, 
            }) 
            store.dispatch('fi_get_user_items',{
              name:DATA.ITEMS.USER_GROUPS.values,
              on:true, 
              cash:true, 
              get:true, 
            })   
            store.dispatch('fi_get_item',{
              name:DATA.ITEMS.COMPANIES.values,
              on:true, 
              cash:true, 
              get:true, 
            })  
          }else{
            console.log('no user......');
            await DB.deleteItems({
              name:DATA.ITEMS.USER_LIVES.values,
              key:DATA.APP.USER_TIMELINE.current
            })
            store.dispatch('connectUser',{user:user}) 
          } 
        } catch (error) {
          store.dispatch('ERROR',{
            error:error,
            from:'onAuthStateChanged',
            page:PAGE_NAME
        })
        }                                  
      })                                                                               
      
      //SETTINGS
      store.dispatch('fi_get_user_items',{
        name:DATA.ITEMS.PERMISSIONS.values,
        on:true, 
        cash:true, 
        get:true, 
      })  
      store.dispatch('fi_get_item',{
        name:DATA.ITEMS.BILLING_PLANS.values,
        on:true, 
        cash:true, 
        get:true, 
      })     
      store.dispatch('fi_get_item',{
        name:DATA.ITEMS.BILLING_PLAN_FEATURES.values,
        on:true, 
        cash:true, 
        get:true, 
      })  

      
      store.dispatch('fi_get_item',{
        name:DATA.ITEMS.COUNTRIES.values,
        on:true, 
        cash:true, 
        get:true, 
      }) 
      store.dispatch('fi_get_item',{
        name:DATA.ITEMS.REGIONS.values,
        on:true, 
        cash:true, 
        get:true, 
      }) 
      store.dispatch('fi_get_item',{
        name:DATA.ITEMS.DISTRICTS.values,
        on:true, 
        cash:true, 
        get:true, 
      })  
      store.dispatch('fi_get_item',{
        name:DATA.ITEMS.ID_TYPES.values,
        on:true, 
        cash:true, 
        get:true, 
      })  
      store.dispatch('fi_get_item',{
        name:DATA.ITEMS.MEASUREMENT_UNITS.values,
        on:true, 
        cash:true, 
        get:true, 
      })
      store.dispatch('fi_get_item',{
        name:DATA.ITEMS.COMPANY_TYPES.values,
        on:true, 
        cash:true, 
        get:true, 
      })  
      store.dispatch('fi_get_item',{
        name:DATA.ITEMS.COMPANY_SIZES.values,
        on:true, 
        cash:true, 
        get:true, 
      })   
    } catch (error) {
      console.log(error,'error... ');
    }
     
  },
})
