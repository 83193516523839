<template>
    <div> 
        <v-divider></v-divider> 
        <mbs-page-tab 
            :items="MY_LINKS"/> 
        <v-divider></v-divider> 
        <v-divider></v-divider> 
        <v-divider></v-divider> 
        <v-container grid-list-xl> 
            <v-layout class="mt-1" align-center="" justify-center="">
                <v-col sm="12" cols="12"> 
                    <v-card > 
                        <mbs-item-manager
                            :table_title="'Manage Stock Takings....'"
                            :table_title_icon="'mdi-clipboard-search-outline'"
                            :add_vertical_lines="true"
                            add_item_title="START STOCK TAKING"
                            :add_item_action="{
                                action_do:'Start',
                                action_doing:'Starting'
                            }"
                            :table_tabs="tableTabs"
                            :row_click="true"
                            :top_label="false"
                            :filter_date="true"
                            :filter_date_value="'created_at'"
                            :filter_default_days="'90'"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM" 
                            :header_items="HEADER_ITEMS" 
                            :data_items="StockTakingOrdersData"   
                            :is_joined="true"
                            :path_item="SOCK_ORDER_PATH"   
                            :field_items="DATA.FIELDS.start_stock_taking"  

                            :select_items="SelectItems"  
                            :table_actions="TableActions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div>
            <!-- JOIN STOCK TAKE -->
            <div v-if="dialog_join_stock_take">
                <v-dialog 
                    v-model="dialog_join_stock_take"
                    scrollable   
                    :persistent="false" :overlay="false"
                    max-width="550px"
                    transition="dialog-transition" >
                    <v-card rounded="lg"  >
                        <v-card-title class="primary">
                            <div class="font-weight-bold b2--text">JOIN A STOCK TAKE</div> 
                            <v-spacer></v-spacer>
                            <v-btn icon  dark @click="dialog_join_stock_take=false"><v-icon>close</v-icon></v-btn>
                        </v-card-title>
                        <v-card outlined  class="mx-3" >
                            <v-layout row wrap class="ma-0" justify-center align-center column>
                                <v-icon size="100" class="mt-5">mdi-clipboard-search-outline</v-icon> 
                                <!-- <v-icon size="100" class="mt-5">mdi-tag-search</v-icon>  -->
                                <!-- <h2 class="grey--text text--darken-1  ">{{MBS.actions.TEXT_UP('JOIN A STOCK TAKE')}}</h2> -->
                            </v-layout>
                            <v-card outlined class="mx-5 my-5">
                                <form @submit.prevent="JOIN_SOCK_TAKING(input)">
                                    <v-layout row wrap class="mx-3 my-5">
                                        <mbs-input-fields  
                                            v-model="input" 
                                            :select_items="SelectItems"
                                            :field_items="DATA.FIELDS.join_stock_taking"  
                                            />   
                                    </v-layout> 
                                    <v-layout class="ma-0" justify-center align-center> 
                                        <v-btn :loading="inputLoading" color="primary" 
                                        large type="submit" class="mt-5 mb-2 px-9">JOIN</v-btn> 
                                        
                                    </v-layout>
                                </form> 
                            </v-card>
                        </v-card> 
                         
                    </v-card> 
                </v-dialog>  
            </div>  
            
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    import DB from '../../../../plugins/DB'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.STOCK_TAKINGS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,
                DATA:DATA, 
                dialog_join_stock_take:false,
                inputLoading:false,
                input:{},

                NAME_ITEMS:DATA.ITEMS.STOCK_TAKINGS.names,
                NAME_ITEM:DATA.ITEMS.STOCK_TAKINGS.name,
                VALUE_ITEMS:DATA.ITEMS.STOCK_TAKINGS.values,
                VALUE_ITEM:DATA.ITEMS.STOCK_TAKINGS.value,   
                tableTabs:[
                    {name:"All Stock Take",show_if:(this_,item)=>true},
                    {name:"Running...",show_if:(this_,item)=>!item.closed},
                    {name:"Completed",show_if:(this_,item)=>item.closed}, 
                ],
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },   
            /////////
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("stock",1,true) 
                return thisLinks
            }, 
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SelectedCompany: state=> state.items["SELECTED_COMPANY"],  
            }),
            ...mapState({ 
                StockOutlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedStockOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                StockOrders: state=> state.items[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()],
                JoinedStockOrders: state=> state.join[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()], 
                StockTakingOrders: state=> state.items[(DATA.ITEMS.STOCK_TAKING_ORDERS.values).toUpperCase()],
                JoinedStockTakingOrders: state=> state.join[(DATA.ITEMS.STOCK_TAKING_ORDERS.values).toUpperCase()], 
                LocalJoinedStockTaking: state=> state.items[(DATA.ITEMS.LOCAL_JOINED_STOCK_TAKING.values).toUpperCase()],
                LocalStockTakingItems: state=> state.items[(DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values).toUpperCase()],
            }),  
            StockOrdersData(){ 
                let StockOrders = this.StockOrders
                let JoinedStockOrders = this.JoinedStockOrders
                let items = JoinedStockOrders?JoinedStockOrders:StockOrders 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("created_at_server",false)) 
                return list
            }, 
            StockOutletsData(){  
                let items = this.JoinedStockOutlets?this.JoinedStockOutlets:this.StockOutlets  
                return items
            }, 
            StockTakingOrdersData(){  
                let items = this.JoinedStockTakingOrders?this.JoinedStockTakingOrders:this.StockTakingOrders   
                if(!items){return null}   
                let list = [...items]
                console.log(list,'.........ss');
                list.sort(this.MBS.actions.dynamicSort("operating_date",false)) 
                return list
            }, 
            RunningStockTaking(){
                let stock = this.StockTakingOrdersData
                if(!stock){return null}
                let filtered = stock.filter(item=>!item.closed)
                return filtered 
            },
            SelectItems(){ 
                let Outlets = this.StockOutletsData
                let StockTakingOrders = this.RunningStockTaking  
                return { 
                    Outlets:Outlets,
                    StockTakingOrders:StockTakingOrders, 
                }
            }, 
            
            SOCK_ORDER_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.stock_taking_orders,true) 
                return path
            }, 
            
            TableActions(){ 
                let actions =[ 
                    {type:"action",btn:true,icon:"mdi-clipboard-plus-outline",action:"start_stock_take",color:"secondary", outlined:false,  text:"Start Stock Take"}, 
                    {type:"action",btn:true,icon:"mdi-clipboard-search-outline",action:"join_stock_take",color:"secondary", outlined:false,  text:"Join Stock Take"},  
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    
                ] 
                return actions
            },
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',tile:true,icon:'mdi-clipboard-search-outline',color:"primary",size:35,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Starts",value:'created_at_',show:true},   
                    {id:0,name:"Closed",value:'closed_at_',show:true},   
                    {id:0,name:"Operating Date",value:'operating_date',show:true},            
                    {id:0,name:"Outlet",value:"outlet_name",show:true},         
                    {id:0,name:"User(s)",value:"users_size",show:true},     
                    {id:0,name:"items",value:'item_counted_size_',align:'right',show:true},   
                    {id:0,name:"Total Cost",value:'total_price_',align:'right',show:true},   
                    {id:0,name:"Created by",value:'created_user_name',align:'right',show:false},   
                    {id:0,name:"Closed by",value:'closed_user_name',align:'right',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View stock take",action:"view_order",icon:"mdi-eye"}, 
                        {name:"Join stock take",action:"join_stock_take",icon:"mdi-clipboard-search-outline"}, 
                        {name:"Close & Finish Stock Take",action:"close_stock_take",icon:"mdi-check-outline"},   
                    ]}, 
                ]
            },   
        },
        methods: { 
            TABLE_ACTION(action,item){
                try {  
                    if (action == "start_stock_take") {
                        this.START_SOCK_TAKING()
                    } else if (action == "join_stock_take") {
                        this.SELECT_JOIN_SOCK_TAKING()
                    } else if (action == "refresh") {
                        let mbs_text = this.MBS.text.item_action("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-ITEMS", 
                            } 
                        })
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try {  
                    console.log(action,item);
                    if (action == "start_stock_take") {
                        // this.START_SOCK_TAKING()
                    } else if (action == "join_stock_take") {
                        
                        this.JOIN_SOCK_TAKING({
                            ...item,
                            stock_taking_order_key:item.key
                        })
                    }else if (action == "view_order") {
                        console.log(action,'action...........');
                        console.log(item,'item...........');
                        let path = '/stock/stock-take/view/'+item?.key
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK(path,true))
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            START_SOCK_TAKING(input){
                try { 
                    // this.ADDING_ITEM = {...DATA.ITEMS.ITEM_KITS}
                    // this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    // this.ADDING_ITEM.fields =  DATA.FIELDS.Add_items_kit
                    // this.ADDING_ITEM.add_item_action =  {
                    //     action_do:'Create',
                    //     action_doing:'Creating'
                    // }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.STOCK_TAKINGS.name,
                            action:"add_item", 
                        })  
                    }, 50); 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'START_SOCK_TAKING',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SELECT_JOIN_SOCK_TAKING(input){
                try { 
                    this.dialog_join_stock_take = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_JOIN_SOCK_TAKING',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            JOIN_SOCK_TAKING(input){
                try { 
                    let stock_taking_order_key = input?input.stock_taking_order_key:null
                    let description = input?input.description:null
                    if (!stock_taking_order_key) {
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:"ERROR",
                            text:"You must select stock take first.",
                            btnYes:"Okay"
                        })
                        return
                    }
                    console.log(input);
                    let moment = this.MBS.date.moment
                    let joined_at = moment?moment().valueOf():null 
                    let details={
                        stock_taking_order_key:stock_taking_order_key,
                        joined_at:joined_at, 
                        left:false,
                        uid:this.us.uid
                    }
                    if (description) {
                        details.description = description
                    } 
                     
                    let mbs_text = this.MBS.text.item_action("Stock Taking","Join")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=JOIN-STOCK-TAKING",
                            data:details
                        }
                    }) 
                    // this.SUBMIT_STOCK_TAKING(details)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'START_SOCK_TAKING',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT_STOCK_TAKING(data){
                try { 
                    // if (this.LocalJoinedStockTaking) {
                    // }
                    DB.deleteItems({
                        name:DATA.ITEMS.LOCAL_JOINED_STOCK_TAKING.values 
                    }) 
                    let stock_taking_order_key = data?data.stock_taking_order_key:null
                    let uid = data?data.uid:null 
                    this.inputLoading = true 
                    this.MBS.actions.progressDialog({
                        show:true,
                        fixed:false,
                        title:"Updating"
                    })  
                    this.$store.dispatch("fi_update",{
                        action:this.VALUE_ITEM,
                        path:this.MBS.actions.COMPANY_LINK(DATA.PATHS.stock_taking_orders+stock_taking_order_key+"/users/"+uid,true),
                        data:data,
                        us:this.us
                    }).then(res=>{ 
                        let key = res?res.data?res.data.key:null:null 
                        return DB.addItems({
                            name:DATA.ITEMS.LOCAL_JOINED_STOCK_TAKING.values,
                            data:{
                                key:stock_taking_order_key,
                                ...data
                            } 
                        })  
                    })
                    .then(res=>{  
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-take/join",true))
                        this.MBS.events.$emit("SUBMIT_JOINED_STOCK_TAKING",PAGE_NAME)
                        this.inputLoading = false
                        this.dialog_stack_details = false 
                        this.input = {} 
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_success("Stock Taking","Joining")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{  
                        this.MBS.actions.error({
                            error:error,
                            from:'JOIN-STOCK-TAKING',
                            page:PAGE_NAME, 
                        })  
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_fail("Stock Taking","Joining")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    })
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_STOCK_TAKING',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            RELOAD_ITEMS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.STOCK_TAKING_ORDERS.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{
                        console.log(res,'res.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=RELOAD-ITEMS') {
                            if (action.YES) {
                                this.RELOAD_ITEMS()
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=JOIN-STOCK-TAKING') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.SUBMIT_STOCK_TAKING(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                
                            }
                        }
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            }) 
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            }, 
        },
        watch: { 
            us:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            loading:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            processes:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            responses:{ 
                handler:"FEEDBACKS",
                immediate:true
            } 
        },
    }
</script>
 
