<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">  
                    <v-card class="mb-5" color="primary">
                        <mbs-page-tab 
                            :items="MY_LINKS"/>   
                    </v-card> 
                    <v-layout justify-center>
                        <v-col cols="12" md="11" lg="10" xl="8">
                            <v-toolbar  rounded="" outlined    color="primary" class="m-2" dark> 
                                <!-- <h1 class="b2--text py-2 ">Profit And Loss</h1> -->
                                <template >
                                    <mbs-date-select 
                                        v-model="filter_date"
                                        color="white"
                                        title_color="secondary"
                                        :max_from_date="MBS.date.moment().format('yyyy-MM-DD')" 
                                        :max_to_date="MBS.date.moment().format('yyyy-MM-DD')"
                                        default_days="90" 
                                        />  
                                    <v-spacer></v-spacer>
                                    <v-divider vertical></v-divider> 

                                    <v-btn 
                                        icon @click="ON_PDF()">
                                        <v-icon>mdi-file-pdf-box </v-icon>
                                    </v-btn>
                                    <v-btn 
                                        icon @click="ON_EXCEL()">
                                        <v-icon>mdi-microsoft-excel </v-icon>
                                    </v-btn>
                                    <v-btn 
                                        icon @click="ON_EMAIL()">
                                        <v-icon>mdi-email-outline</v-icon>
                                    </v-btn>
                                    <v-btn 
                                        icon @click="ON_PRINTING()">
                                        <v-icon>mdi-printer</v-icon>
                                    </v-btn>
                                    <v-btn 
                                        icon @click="ON_REFRESH()">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </template>
                            </v-toolbar> 
                            <v-card :min-height="vsh-170" width="100%" color="transparent" flat> 
                                <mbs-profit-and-loss-temp1
                                    :expenses="ExpensesFiltered"
                                    :sale_orders="SaleOrdersFiltered"
                                    />  
                            </v-card>    
                        </v-col>
                    </v-layout> 
                    <br> 
                </v-col>  
            </v-layout>
        </v-container>  
        <div> 
            <mbs-auto-print
                v-if="autoPrint"
                :show="true"  
                :type="typePrint"  
                :data="itemPrint" /> 
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA' 
    import {mapState} from "vuex"
    let PAGE_NAME = "PROFIT-AND-LOSS"   
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.SALES.names,
                NAME_ITEM:DATA.ITEMS.SALES.name,
                VALUE_ITEMS:DATA.ITEMS.SALE_ORDERS.values,
                VALUE_ITEM:DATA.ITEMS.SALE_ORDERS.value,  

                FIELD_ITEMS:[
                    {type:"number",name:"code",value:"code",label:"Code",required:true,sm:12,md:12}, 
                    {type:"text",name:"name",value:"name",label:"Name",required:true,sm:12,md:12},   
                ],     
                table_actions:[  
                    // {type:"btn",action:"order_stock",color:"secondary", outlined:false,  text:"Order Stock"}, 
                    // {type:"btn",action:"received_stock",color:"secondary",  text:"Received Stock"}, 
                    // {type:"btn",action:"return_stock",color:"secondary",  text:"Return Stock"}, 
                    // {type:"btn",action:"transfer_stock",color:"secondary",  text:"Transfer Stock"}, 
                ],
                TOTAL_ITEM_VALUES:[ 
                    {name:'Total Items',value:'total_quantity'},
                    {name:'Total Sales Price',value:'total_sold_price',show_value:'total_sold_price_',money:true},
                ],
                expend_expenses:true,
                filter_date:null,

                itemPrint:null,
                typePrint:null,
                autoPrint:false,
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: { 
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()], 
                ExpensesAccounts: state=> state.items[(DATA.ITEMS.EXPENSE_ACCOUNTS.values).toUpperCase()], 
                JoinedExpensesAccounts: state=> state.join[(DATA.ITEMS.EXPENSE_ACCOUNTS.values).toUpperCase()], 
                Expenses: state=> state.items[(DATA.ITEMS.EXPENSES.values).toUpperCase()], 
                JoinedExpenses: state=> state.join[(DATA.ITEMS.EXPENSES.values).toUpperCase()], 
                SelectedCompany: state=> state.items["SELECTED_COMPANY"],  
            }), 
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
             
            moment(){  
                return this.MBS.date.moment
            },  
            /////////
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("reports",2,true) 
                return thisLinks
            }, 
            SaleOrdersData(){ 
                let SaleOrders = this.SaleOrders
                let JoinedSaleOrders = this.JoinedSaleOrders
                let items = JoinedSaleOrders?JoinedSaleOrders:SaleOrders 
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                return list
            },
            ExpensesAccountsData(){ 
                let ExpensesAccounts = this.ExpensesAccounts
                let JoinedExpensesAccounts = this.JoinedExpensesAccounts
                let items = JoinedExpensesAccounts?JoinedExpensesAccounts:ExpensesAccounts 
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                return list
            }, 
            ExpensesData(){ 
                let Expenses = this.Expenses
                let JoinedExpenses = this.JoinedExpenses
                let items = JoinedExpenses?JoinedExpenses:Expenses  
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                return list
            }, 
 
            SaleOrdersFiltered(){ 
                let items = this.SaleOrdersData  
                let filter_date = this.filter_date
                let from_date = filter_date?filter_date.from_date:null
                let to_date = filter_date?filter_date.to_date:null
                if(!items){return null}
                let filtered_items = items.filter(order=>{
                    let mDate = this.moment(order.local_created_at)
                    let between = mDate.isBetween(from_date,to_date,'day','[]') 
                    return !order.archive && between
                })
                return filtered_items
            },    
            ExpensesAccountsFiltered(){ 
                let allAccounts = this.ExpensesAccountsData 
                console.log(allAccounts,'......');
                return allAccounts
                let filter_date = this.filter_date
                let from_date = filter_date?filter_date.from_date:null
                let to_date = filter_date?filter_date.to_date:null
                if(!allAccounts){return null}
                let filtered_items = allAccounts.filter(element => {
                    let mDate = this.moment(element.created_at)
                    let between = mDate.isBetween(from_date,to_date,'day','[]')
                    return !element.archive && between
                }); 
                return filtered_items
            },  
            ExpensesFiltered(){ 
                let allAccounts = this.ExpensesData 
                // console.log(allAccounts,'......');
                // return allAccounts
                let filter_date = this.filter_date
                let from_date = filter_date?filter_date.from_date:null
                let to_date = filter_date?filter_date.to_date:null
                if(!allAccounts){return null}
                let filtered_items = allAccounts.filter(element => {
                    let mDate = this.moment(element.created_at)
                    let between = mDate.isBetween(from_date,to_date,'day','[]')
                    return !element.archive && between
                }); 
                return filtered_items
            },   
             
        },
        methods: {  
            ON_PDF(){
                try { 
                     this.MBS.actions.dialog(null,true,false,
                        "PDF OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_PDF',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EXCEL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EXCEL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK")  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EMAIL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EMAIL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_PRINTING(){
                try { 
                    let sale_orders = this.SaleOrders 
                    let expenses_accounts = this.ExpensesAccounts 
                    this.autoPrint = false
                    setTimeout(() => {
                        this.itemPrint = {
                            sale_orders:sale_orders,
                            expenses_accounts:expenses_accounts,
                        }
                        this.typePrint = 'profit_and_loss'
                        this.autoPrint = true  
                    }, 5);
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            ON_REFRESH(){
                try { 
                    return
                    let mbs_text = this.MBS.text.item_action("Sales Transactions","Refresh")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=RELOAD-ITEMS", 
                        } 
                    })
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            RELOAD_ITEMS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{
                        console.log(res,'res.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=RELOAD-ITEMS') {
                            if (action.YES) {
                                this.RELOAD_ITEMS()
                            } else {
                                
                            }
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            },
    
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
