<template>
    <div>
        <v-menu 
            v-model="menuDateSelect" 
            :close-on-content-click="false" 
            offset-y  offset-overflow
            :nudge-width="500" rounded="xl"
            >
            <template v-slot:activator="{ on, attrs }"> 
                <v-card   v-on="on" class="transparent"
                    width="100%" min-width="150"
                     min-height="20"  flat >
                    <v-layout fill-height align-center class="ma-0  ">
                        <v-icon size="" class="mr-3" :color="icon_color">mdi-network-pos</v-icon>
                        <v-spacer></v-spacer>
                        <div :class="color?color+'--text':'grey--text '" class="pb-1">
                            
                            <!-- <div 
                                class="font-weight-bold not-f3  text--darken-1">
                                {{moment(fromDate).format('Do, MMM YYYY')}} -
                                {{moment(toDate).format('Do, MMM YYYY')}}
                            </div>  -->
                            <span :class="title_color?title_color+'--text':''" class="font-weight-bold not-f3  text--darken-1">{{MBS.actions.SIZE(DayShiftsFiltered)}} DayShift(s)</span>
                            <div   class="font-weight-bold not-f3 ">
                                <span>{{moment(fromDate).format('yyyy/MM/DD')}} - {{moment(toDate).format('yyyy/MM/DD')}}</span>
                                (<span v-if="toNumber(difference)==0">Today</span>
                                <span v-else-if="difference==1">Yesterday</span>
                                <span v-else>Last {{difference}} Days</span>)
                            </div> 
                        </div>
                       <v-icon class="ml-2" :color="icon_color">arrow_drop_down</v-icon>
                    </v-layout>
                    <!-- <div class="mt- mx-"><hr></div>  -->
                </v-card> 
            </template> 

            <v-card  v-if="ud"
                color="lighten-1" class="b1">   
                <v-toolbar tile flat color="secondary">
                    <div :class="'white--text pl-3'">
                        <div class="not-f2 ">
                            <div v-if="DIFF(inputFromDate,inputToDate)==0">Today</div>
                            <div v-else-if="DIFF(inputFromDate,inputToDate)==1">Yesterday</div>
                            <div v-else>Last {{DIFF(inputFromDate,inputToDate)}} Days</div>
                        </div> 
                        <span class="font-weight-bol">{{MBS.actions.SIZE(DayShiftsFiltered)}} DayShift(s)</span>
                    </div>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="" dark text @click="DEFAULT()">Default</v-btn> -->
                    <v-btn color=""   dark text @click="menuDateSelect =false "><v-icon>close</v-icon></v-btn>
                </v-toolbar>
                
                <!-- //LAST DAYS -->
                <v-list dense class="mx-5" v-if="false">
                    <div  v-for="(item,index) in OptionalDays" :key="index" > 
                        <v-list-item @click="CHANGE_DATE(item)">
                            <div :class="DIFF(inputFromDate,inputToDate)==item.days?'secondary--text':''">{{item.name}}</div>
                        </v-list-item> 
                    </div> 
                </v-list>

                <!-- //DAYSHIFT TABLE -->
                <div class="ml-4">
                    <v-card class="mr-5 mt-2" tile>
                        <v-card dark color="primary  "  tile  > 
                            <v-layout row class="ma-0" align-center> 
                                <div class="ml-5 py-3">Select Dayshift</div>
                                <v-spacer></v-spacer>
                                <v-autocomplete v-if="MBS.actions.SIZE(OutletsFilter)>1"
                                    class="mt-1 mr-1"
                                    v-model="input_outlets"
                                    dense deletable-chips
                                    label="Select Outlet"
                                    multiple outlined
                                    small-chips clearable
                                    :items="OutletsFilter"
                                    prepend-inner-icon="mdi-sitemap"
                                    item-value="code"
                                    item-text="name"
                                    ></v-autocomplete>
                            </v-layout>
                        </v-card>
                        <v-data-table dense flat  
                            :height="vsh-360"
                            fixed-header
                            v-model="selected"
                            :hide-default-footer="false"
                            :headers="headers"
                            :items="DayShiftsFiltered"
                            :single-select="false"
                            item-key="key"
                            :show-select="true" >
                        </v-data-table> 
                    </v-card>

                    <v-simple-table v-if="false"
                        :height="vsh-350"
                        fixed-header
                        dense
                        >
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="ma-0 pa-0 " style="width:'5px'"> 
                                    <v-switch :inset="false"    style="height: ; margin: 5px;"
                                        v-model="item_multiple_select"
                                        label=""   hide-details
                                        class="ml-1"
                                    ></v-switch> 
                                </th>
                                <th class="text-left">
                                    Open
                                </th>
                                <th class="text-left">
                                    Close
                                </th>
                                <th class="text-left">
                                    Operational
                                </th>
                                <th class="text-left">
                                    Outlet
                                </th>
                                <th class="text-left">
                                    User
                                </th>
                                <!-- <th class="text-left">
                                    T. Sales
                                </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="pointer"
                            v-for="(item,index) in DayShiftsFiltered" :key="item.name" >
                                <td class=" text-center with-divider-lr  ma-0 pa-0 table-background1" style="width:40px;"> 
                                    <v-simple-checkbox @click.stop="" class="ma-0 pa-0"
                                        v-if="item_multiple_select" icon 
                                        color="primary" 
                                        :value="item.checked" 
                                        @input="INPUT_UPDATE($event, item,true)" 
                                        />  
                                    <span v-else icon >
                                        <v-icon v-if="item.expand">expand_more</v-icon>
                                        <v-icon v-else>chevron_right</v-icon>
                                    </span>  
                                </td>
                                <td class="with-divider">{{ item.local_created_at_}}</td>
                                <td class="with-divider">{{ item.time_closed}}</td>
                                <td class="with-divider">{{ item.operating_date_ }}</td>
                                <td class="">{{ item.outlet_name }}</td>
                                <td class="with-divider-lr">{{ item.user_name }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table> 
                </div> 

                <v-layout class="ml-3 mr-5 mb-1 mt-4"  >
                    <v-flex >
                        <v-menu
                            ref="menuFromDate"
                            v-model="menuFromDate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y 
                            max-width="290px"
                            min-width="auto" >
                            <template v-slot:activator="{ on, attrs }">
                                <v-card v-on="on" height="100%" color="primary" dark class="px-3 py-1 mb-">
                                    <v-layout align-center class="ma-0">
                                        <div>
                                            <div class="font-weight-bold not-f2">From</div>
                                            <div class="not-f">{{moment(inputFromDate).format('dddd, Do, MMMM YYYY')}}</div>
                                            <!-- <div>{{moment(inputFromDate).format('ddd, yyyy/MM/DD')}}</div> -->
                                        </div>
                                        <v-spacer></v-spacer>
                                        <v-icon>today</v-icon> 
                                    </v-layout> 
                                </v-card> 
                            </template>
                            <v-date-picker
                                v-model="inputFromDate" color="secondary"
                                no-title event-color="primary"
                                :max="max_from_date"
                                :min="min_from_date"
                                @input="menuFromDate = false"
                            ></v-date-picker>
                        </v-menu> 
                    </v-flex>
                    <v-flex >
                        
                        <v-menu
                            ref="menuFromDate"
                            v-model="menuToDate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y 
                            max-width="290px"
                            min-width="auto" >
                            <template v-slot:activator="{ on, attrs }">
                                <v-card v-on="on"  height="100%"    color="primary" dark class="px-3 py-1 ml-1 mb-">
                                    <v-layout align-center class="ma-0">
                                        <div>
                                            <div class="font-weight-bold not-f2">To</div>
                                            <div class="not-f">{{moment(inputToDate).format('dddd, Do, MMMM YYYY')}}</div>
                                            <!-- <div>{{moment(inputToDate).format('ddd, yyyy/MM/DD')}}</div> -->
                                        </div>
                                        <v-spacer></v-spacer>
                                        <v-icon>today</v-icon> 
                                    </v-layout> 
                                </v-card> 
                            </template>
                            <v-date-picker
                                v-model="inputToDate"
                                :max="max_to_date"
                                :min="min_to_date"
                                no-title color="secondary"
                                @input="menuToDate = false"
                            ></v-date-picker>
                        </v-menu>  
                    </v-flex>
                </v-layout>

                <v-divider></v-divider>
                <v-card-actions class="b1">
                    <v-spacer></v-spacer>
                    <v-btn text @click="menuDateSelect = false">Cancel</v-btn>
                    <v-btn text color="" @click="APPLY_DATE()">Apply</v-btn>
                </v-card-actions> 
            </v-card>  
        </v-menu>  
    </div>
</template> 
<script>
let NAME = 'DAY-SHIFT-SELECTOR'
import moment from 'moment'
import {mapState} from "vuex"
import DATA from '../../plugins/DATA';
import { toNumber } from '../../plugins/formatNumber';
export default { 
    props:['color','icon_color','title_color',
        'max_from_date','min_from_date','max_to_date','min_to_date',
        'default_days','optional_dates','on_start'], 
    data(){
        return{ 
            menuDateSelect:false, 
            select:{
                signOption:'',
                accountType:'',
                businessType:'',
                country:''
            },
            psw1: true, 
            input:{ 
            },
            input_outlets:null,
            item_multiple_select:false,
            inputPhone:'',
            inputLoading:false,
            date:"17/01/2020 - 17/01/2020",
            inputFromDate:moment().subtract(5,'day').format('yyyy-MM-DD'),
            inputToDate:moment().format('yyyy-MM-DD'), 
            menuFromDate:false,
            menuToDate:false, 
            fromDate: null,
            toDate: null,
            difference: null, 
            selected: [],
            headers: [ 
                { text: 'Open', value: 'local_created_at_', cellClass:'with-divider-lr' },
                { text: 'Close', value: 'time_closed', cellClass:'with-divider'  },
                { text: 'Operational', value: 'operating_date_' , cellClass:'with-divider' },
                { text: 'Outlet', value: 'outlet_name' , cellClass:'with-divider' },
                { text: 'User', value: 'user_name' , cellClass:'with-divider-' },
            ],
        }  
    },
    created(){
        this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
        
    },
    mounted(){
        this.input={}
        this.inputLoading=false
        if (this.default_days) {
            let item = {from:this.default_days,from_type:"days"} 
            this.CHANGE_DATE(item)
        } 
        this.fromDate = this.inputFromDate
        this.toDate = this.inputToDate 
        if (this.on_start) {
            this.APPLY_DATE() 
        }
    },
    computed:{
        vs(){
            return this.$vuetify.breakpoint
        }, 
        vsh(){
            return this.vs.height
        }, 
        moment(){ 
            return moment
        }, 
        lastDays(){ 
            return this.MBS.date.moment
        }, 
        ...mapState({
            loading:state=>state.load.loading,
            processes:state=>state.load.processes,
            responses:state=>state.load.responses,
        }),
        ...mapState({
            us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
            ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
        }),
        ...mapState({ 
            Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
            JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
            Departments: state=> state.items[(DATA.ITEMS.ITEM_DEPARTMENTS.values).toUpperCase()],
            JoinedDepartments: state=> state.join[(DATA.ITEMS.ITEM_DEPARTMENTS.values).toUpperCase()], 
            DayShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
            JoinedDayShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
            Cashups: state=> state.items[(DATA.ITEMS.CASHUPS.values).toUpperCase()],
            JoinedCashups: state=> state.join[(DATA.ITEMS.CASHUPS.values).toUpperCase()],
        }), 
        OutletsData(){ 
            let Outlets = this.Outlets
            let JoinedOutlets = this.JoinedOutlets
            let items = JoinedOutlets?JoinedOutlets:Outlets 
            return items
        }, 
        OutletsFilter(){ 
            let outlets = this.OutletsData 
            let items = [
                // {code:0,name:"All Outlets"}
            ]
            if (outlets) {
                items.push(...outlets)
            }
            return items
        }, 
        DayShiftsData(){ 
            let DayShifts = this.DayShifts
            let JoinedDayShifts = this.JoinedDayShifts
            let items = JoinedDayShifts?JoinedDayShifts:DayShifts  
            if(!items){return null}
            let list = [...items]
            list.sort(this.MBS.actions.dynamicSort("local_created_at",false))   
            return list 
        }, 
        DayShiftsFiltered(){
            const input_outlets = this.input_outlets
            const days = this.DayShiftsData
            const from_date = this.inputFromDate
            const to_date = this.inputToDate
            const difference = this.difference
            if(!days){return null}
            // if(!this.MBS.actions.SIZE(input_outlets)){return days}
            // if(input_outlets[0] == 0){return days}
            let filtered = days.filter(day=>{
                const index = input_outlets?.indexOf(day.outlet_code)
                const is_outlet =  this.MBS.actions.SIZE(input_outlets)?index != -1:true
                const open_date = day.local_created_at
                const close_date = day.local_close_at
                const open_moment = moment(moment(open_date).format("yyyy-MM-D"))
                const close_moment = moment(moment(close_date).format("yyyy-MM-D"))
                const is_open = open_moment.isBetween(from_date,to_date,'','[]')
                const is_close = close_moment.isBetween(from_date,to_date,'','[]')
                return is_outlet && (is_open || is_close)
            })  
            return filtered
        },
        DayShiftsKeys(){
            const dayshifts = this.DayShiftsFiltered
            if(!dayshifts){return null}
            let codes = []
            dayshifts.forEach(day => {
                codes.push(day.key) 
            });
            return codes
        },
        SelectedDayShiftsKeys(){
            const dayshifts = this.selected
            if(!dayshifts){return null}
            let codes = []
            dayshifts.forEach(day => {
                codes.push(day.key) 
            });
            return codes
        },
        OptionalDays(){ 
            let days = this.optional_days
            if (days) {
               return days 
            }
            return  [
                {name:"Today",days:0,from:"0",from_type:"days"},
                {name:"Last 7 Days",days:7,from:"7",from_type:"days"},
                {name:"Last 30 Days",days:30,from:"30",from_type:"days"},
                {name:"Last 3 Months",days:90,from:"90",from_type:"days"},
                {name:"Last 6 Months",days:180,from:"180",from_type:"days"},
                {name:"Last 12 Months",days:365,from:"12",from_type:"months"},
            ]
        },  
         
    },
    methods:{
        DEFAULT(default_days){
            try { 
                if (!default_days) {
                    default_days = this.default_days
                }
                if (default_days) {
                    let item = {from:default_days,from_type:"days"} 
                    this.CHANGE_DATE(item)
                }    
                this.APPLY_DATE() 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CHANGE_DATE',
                    page:NAME, 
                }) 
            }
            
        },
        APPLY_DATE(){
            try { 
                this.fromDate = this.inputFromDate
                this.toDate = this.inputToDate 
                this.difference = this.DIFF(this.fromDate,this.toDate)
                this.menuDateSelect = false  
                let send_data = {
                    from_date:this.fromDate,
                    to_date:this.toDate,
                    difference:this.difference,
                    outlet_code:this.OutletsFilter,
                    dayshifts:this.DayShiftsFiltered,
                    dayshifts_keys:this.DayShiftsKeys,
                    selected_dayshifts:this.selected,
                    selected_dayshifts_keys:this.SelectedDayShiftsKeys,
                }
                this.$emit('input', send_data )
                this.$emit('apply', send_data )

            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CHANGE_DATE',
                    page:NAME, 
                }) 
            }
            
        },
        CHANGE_DATE(item){
            try { 
                let from = item?item.from:null
                let from_type = item?item.from_type:null
                let to = item?item.to:null
                let to_type = item?item.to_type:null 
                if (from) {
                    this.inputFromDate = moment().subtract(from,from_type).format('yyyy-MM-DD') 
                }else {
                    this.inputFromDate = moment().format('yyyy-MM-DD') 
                }
                if (to) {
                    this.inputToDate = moment().subtract(to,to_type).format('yyyy-MM-DD') 
                }else {
                    this.inputToDate = moment().format('yyyy-MM-DD') 
                }
                 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CHANGE_DATE',
                    page:NAME, 
                }) 
            }
            
        },
        DIFF(from,to,type="days"){
            try { 
                let a = moment(from)
                let b = moment(to)
                let diff = b.diff(a,type) 
                return diff
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CHANGE_DATE',
                    page:NAME, 
                }) 
            }
            
        },

        //--------
        DIALOG_YES(action){ 
            if (action) {
                if (action.code ===NAME+"=ADD-SERVICE") {
                    if (this.input.check ) {
                        this.input.check = false 
                        this.$store.dispatch("fi_add",{
                            action:"Services",
                            path:"MBS/SETTINGS/Services/",
                            data:this.input
                        }) 
                    } 
                } 
                if (action.code ===NAME+"=REGISTER-TIMEOUT") {
                    
                }
                if (action.code ===NAME+"=GO-TO-PROFILE") {
                    this.MBS.actions.go('/profile')
                }
                if (action.code ===NAME+"=RELOAD-PAGE") {
                    this.RELOAD_PAGE()
                }
                if (action.code ===NAME+"=CANCEL-SIGNING") {
                    
                }
                
            }
        },  
         
         
    },
    watch:{ 
        us(value){ 
        },
        processes(value){ 
        },
        loading(value){ 
        },  
        dialog(value){ 
            this.inputLoading = false
        },
        fromDate(value){ 
            this.$emit('input', {
                from_date:value,
                to_date:this.toDate,
                difference:this.difference,
                outlet_code:this.OutletsFilter,
                dayshifts:this.DayShiftsFiltered,
                dayshifts_keys:this.DayShiftsKeys,
                selected_dayshifts:this.selected,
                selected_dayshifts_keys:this.SelectedDayShiftsKeys,
            })
        },
        toDate(value){ 
            this.$emit('input', {
                from_date:this.fromDate,
                to_date:value,
                difference:this.difference,
                outlet_code:this.OutletsFilter,
                dayshifts:this.DayShiftsFiltered,
                dayshifts_keys:this.DayShiftsKeys,
                selected_dayshifts:this.selected,
                selected_dayshifts_keys:this.SelectedDayShiftsKeys,
            })
        },  
    }
}
</script> 