<template>
    <div>   
        <v-container grid-list-xl>  
            <v-alert v-if="ThisDayShift?ThisDayShift.total_closing_cash?true:false:false"
                border="top" 
                colored-border
                color="warning" class="mt-9"
                elevation="2" >
                <v-layout  class="ma-0 py-2"   align-center>
                    <v-icon color="warning">warning</v-icon>
                    <div class="px-5">
                        The selected Day shift has already been closed. Click Read Report Button to view the Sales Report.
                    </div>  
                </v-layout> 
                <v-layout row wrap class="ma-0 px">
                    <v-spacer></v-spacer>
                    <v-btn @click="READ_REPORT()" color="warning" outlined>Read Report</v-btn>
                </v-layout>
            </v-alert>
            <v-card class="mb-5 mt- pb-5" color="transparent" flat v-else>  
                <v-toolbar  rounded="" outlined    color="primary" class="m-2" dark> 
                                <!-- <h1 class="b2--text py-2 ">Profit And Loss</h1> -->
                    <template > 
                        <v-toolbar-title> 
                            <v-list color="transparent" v-if="Joined_DayShift" >
                                <v-list-item>
                                    <v-list-item-action><v-icon>home</v-icon></v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{Joined_DayShift.outlet_name}}</v-list-item-title> 
                                        <v-list-item-subtitle>{{
                                            MBS.date.moment(Joined_DayShift.operating_date).format("dddd, Do, MMMM YYYY")
                                            }}</v-list-item-subtitle> 
                                    </v-list-item-content>                                                                                                             
                                </v-list-item>
                            </v-list>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn @click="dialog_shift_sales_report=!dialog_shift_sales_report"     
                            class="mt-" width="" color="secondary" >
                            DETAILED REPORT
                            <v-icon class="ml-2" size="35">mdi-clipboard-list-outline</v-icon>
                        </v-btn>
                        <v-divider vertical></v-divider> 

                        <v-btn 
                            icon @click="ON_PDF()">
                            <v-icon>mdi-file-pdf-box </v-icon>
                        </v-btn>
                        <v-btn 
                            icon @click="ON_EXCEL()">
                            <v-icon>mdi-microsoft-excel </v-icon>
                        </v-btn>
                        <v-btn 
                            icon @click="ON_EMAIL()">
                            <v-icon>mdi-email-outline</v-icon>
                        </v-btn>
                        <v-btn 
                            icon @click="ON_PRINTING()">
                            <v-icon>mdi-printer</v-icon>
                        </v-btn>
                        <v-btn 
                            icon @click="ON_PRINTING()">
                            <v-icon>mdi-reload</v-icon>
                        </v-btn>
                    </template>
                </v-toolbar>
                <mbs-epson-day-shift-temp1 v-if="!dialog_shift_sales_report"
                    class="mb-5"
                    report_type="X-READ"
                    :data="Joined_DayShift" /> 
            </v-card>
        </v-container>   
        <mbs-auto-print
            v-if="autoPrint"
            :show="true"  
            :auto_print="true"  
            :type="typePrint"  
            :data="itemPrint" />
        <mbs-day-shift-sales-report
            v-model="dialog_shift_sales_report"
            :day_shift="ThisDayShift"
            /> 
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    let PAGE_NAME = "X-READ"   
    export default {
        name:PAGE_NAME, 
        props:['id','day_key','type'], 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,
                dialog_shift_sales_report:false,

                itemPrint:null,
                typePrint:null,
                autoPrint:false,
                DATA:DATA,  
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 
            us(){
                return this.$store.getters.getUser
            },
            ud(){
                return this.$store.getters.getUserDetails
            },
            loading(){  
                return this.MBS.actions.loading
            },
            processes(){  
                return this.MBS.actions.processes
            }, 
            responses(){  
                return this.MBS.actions.responses
            }, 
            /////////   
            ThisDayShift(){ 
                let items = this.$store.getters.getDayShiftByKey(this.day_key)  
                return items
            },   
            Joined_DayShift(){ 
                let item = this.ThisDayShift 
                if(!item){return null}
                let joined = this.MBS.actions.JOIN_DAY_SHIFT(item) 
                return joined
            },   
        },
        methods: { 
            TABLE_ACTION(action,item){
                try { 
                    if (action == "order_stock") {
                        let link = this.MBS.actions.COMPANY_LINK("/stock/stock-control/sales-return",true)
                        this.MBS.actions.go(link)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    let link = "/stock/stock-control/"+(item?item.key:null)
                    const path = this.MBS.actions.COMPANY_LINK(link,true)  
                    if (action == "view_order") {
                        let receipt_key = item?item.key:null
                        this.MBS.actions.go("/sales-receipt/"+receipt_key)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_PDF(){
                try { 
                     this.MBS.actions.dialog(null,true,false,
                        "PDF OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_PDF',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EXCEL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EXCEL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK")  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EMAIL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EMAIL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_PRINTING(){
                try { 
                    let lastSale = this.ThisDayShift 
                    this.autoPrint = false
                    setTimeout(() => {
                        this.itemPrint = lastSale
                        this.typePrint = 'sale_order'
                        this.autoPrint = true  
                    }, 5);
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            READ_REPORT(){
                try {  
                    this.MBS.actions.go(-1)
                    let salesReport = this.MBS.actions.COMPANY_LINK("/day-shift-report/"+this.day_key,true)
                    setTimeout(() => { 
                        this.MBS.actions.go(salesReport)
                    }, 10);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'READ_REPORT',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            },
    
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
