<template>
  <v-layout height="600" class="">     
    <!-- <v-btn @click="DELETE_ALL()" color="success">Delete All</v-btn> -->
    <v-layout v-if="false" fill-height> 
        <mbs-page-spinner :show="true" />  
    </v-layout>     
    <v-container v-else grid-list-lg> 
        <!-- Getting Started --> 
        <v-layout class="b1  " v-if="!ud">
            <v-container grid-list-xs >
                <v-layout row wrap justify-center align-center class="ma-5"> 
                    <div> 
                        <v-img src="/logo/k-pos.svg"  size="50" /> 
                    </div>
                </v-layout>
                <div class="text-center font-weight-bold not-f6 pt-5">
                    {{MBS.actions.TEXT_UP('Get Started with KipCount')}}
                </div>
                <div class="text-center py-2"><strong>{{DATA.APP.NAME}}</strong> Account is required to access {{DATA.APP.NAME}} Products.</div>
                <br> 
                <v-card tile outlined>
                <v-row>
                    <v-col
                    cols="12" sm="6"
                    :class="vs.name=='xs'?'py-0':'py-0 pr-0'">
                        <v-card height="100%" tile color="c4 lighten-2" flat>
                        <v-layout 
                            justify-center 
                            column align-center 
                            fill-height
                            class="px-5 py-9">
                            <v-avatar 
                            color="b1"
                            size="120" > 
                            <v-avatar 
                                class="white"
                                size="110" >  
                                <v-avatar 
                                    class="b1"
                                    size="100" >  
                                    <v-icon large color="primary">{{'mdi-account'}}</v-icon> 
                                </v-avatar>
                            </v-avatar> 
                            </v-avatar>
                            <div
                            class="pt-5 not-f6 font-weight-bold">{{MBS.actions.TEXT_UP('Existing User')}}</div>
                            <div class="text-center pt-2 ">
                            Use your <strong>{{DATA.APP.NAME}} Account</strong> to login to <strong>{{DATA.APP.NAME}} POS</strong> web App
                            </div>
                            <v-spacer></v-spacer>
                            <br>
                            <v-btn @click="LOGIN()" dark class="px-9" color="primary" tile>Login</v-btn> 
                        </v-layout>
                        </v-card>
                    </v-col>
                    <v-col
                    cols="12" sm="6" :class="vs.name=='xs'?'':'py-1 pl-0'">
                        <v-card height="100%" tile color="" flat>
                        <v-layout 
                            justify-center 
                            column align-center 
                            fill-height
                            class="px-5 py-8">

                            <v-avatar 
                            color="b1"
                            size="120" > 
                            <v-avatar 
                                class="white"
                                size="110" >  
                                <v-avatar 
                                    class="b2 "
                                    size="100" >  
                                    <v-icon large color="primary">{{'mdi-account'}}</v-icon> 
                                </v-avatar>
                            </v-avatar> 
                            </v-avatar>
                            <div
                            class="pt-5 not-f6 font-weight-bold">{{MBS.actions.TEXT_UP('New User')}}</div>
                            <div class="text-center pt-2 ">
                            For the new users, you must register with <strong>{{DATA.APP.NAME}}</strong> to have access.
                            </div>
                            <v-spacer></v-spacer>
                            <br>
                            <v-btn to="/signup" dark class="px-9" color="primary" tile>Sign Up</v-btn> 
                        </v-layout>
                        </v-card>
                    </v-col>
                </v-row> 
                </v-card>
                <br><br>
            </v-container> 
        </v-layout> 
        <v-layout row wrap v-else> 
            <!-- //others -->
            <v-flex xs6 sm4 lg3 v-for="(link, index) in MY_LINKS" :key="index">
                <v-hover>
                    <template v-slot="{ hover  }">
                        <v-card  
                            :to="link.link"
                            :elevation="hover ?12:''"
                            rounded="lg"  hover
                            height="100%"> 
                            <div v-if="MBS.actions.TEXT_UP(link.name) === MBS.actions.TEXT_UP('My profile')">
                                <v-img v-if="ud?ud.photoURL:false" 
                                    aspect-ratio="1.8" 
                                    :src="ud.photoURL"
                                    lazy-src="https://picsum.photos/10/6?image=3"  >
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                                /> 
                                        </v-row>
                                    </template>
                                </v-img>
                                    
                                <v-responsive  v-else   
                                    class="primary darken-1"
                                    aspect-ratio="1.8" > 
                                    <v-layout fill-height justify-center align-center>
                                        <v-avatar
                                            size="80"
                                            color="primary darken-2" > 
                                            <span class="white--text headline">
                                                {{us.displayName ? us.displayName[0] : ""}}
                                            </span>  
                                        </v-avatar>
                                    </v-layout>
                                </v-responsive> 
                            </div> 

                            <!--Not my profile--> 
                            <v-responsive v-else aspect-ratio="1.5"  >
                                <v-card height="100%" :color="link.image_background_color" tile>
                                    <v-layout v-if="false" class="ma-0" align-center justify-center fill-height > 
                                        <v-card color="transparent" 
                                            :width="link.image_size?link.image_size+'%':'50%'" tile flat>
                                            <v-img   
                                                position="center"
                                                :src="link.image?link.image:MBS.data.noImg" > 
                                                <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                        indeterminate
                                                        color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>  
                                        </v-card>
                                    </v-layout>  
                                    <v-layout v-else-if="link.icon" class="ma-0" fill-height justify-center align-center>
                                        <v-icon size="60">{{link.icon}}</v-icon>
                                    </v-layout>
                                </v-card>
                            </v-responsive>
                            <v-card-title >
                                <v-layout class="ma-0">
                                    <div class="headline" >{{link.name}}</div> 
                                    <v-spacer></v-spacer> 
                                    <!-- <v-tooltip bottom  > 
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" icon >
                                                <v-icon color="primary">info_outline</v-icon>
                                            </v-btn> 
                                        </template>
                                        <span>More Info</span>
                                    </v-tooltip>   -->
                                </v-layout>
                            </v-card-title> 
                        </v-card> 
                    </template>
                </v-hover>
            </v-flex>
        </v-layout>
        <br>
        <!-- <v-layout justify-center align-baseline class="pt-2">
            <div><v-icon class="mt-2 mr-1" color="b2 ">$mbs</v-icon></div>
            <div class="b2--text">V{{DATA.APP.VERSION}}</div> 
        </v-layout> -->
    </v-container>  
  </v-layout> 
</template>

<script>
import DATA from '../../plugins/DATA'
import {mapState } from "vuex" 
const { exec } = require('child_process');
let PAGE_NAME = "MY-ACCOUNT"
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_SHOW:false, 
                DATA:DATA
            }
        },
        mounted() { 
            this.MBS.actions.header() 
            this.MBS.actions.toolbar({
                show:true,
                height:60, 
                flat:true,
                color:'primary',
                textColor:'white', 
                side:'right'
            }) 
            this.FEEDBACKS(500) 
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            }, 
            nav(){ 
                return this.$store.getters.getMBS_nav
            },  
            MY_LINKS(){
                let links = this.MBS.actions.LINKS 
                if(!links){return null}
                let items = links.filter(link=>{
                    return link.link !="/" && link.company_link
                })
                console.log(items,'items....');
                return items
            }, 
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SelectedCompany: state=> state.items['SELECTED_COMPANY'], 
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
                Devices: state=> state.items[(DATA.ITEMS.DEVICES.values).toUpperCase()],
                JoinedDevices: state=> state.join[(DATA.ITEMS.DEVICES.values).toUpperCase()],

                LocalDevices: state=> state.locals[(DATA.ITEMS.LOCAL_DEVICES.values).toUpperCase()],
            }), 
            DeviceData(){
                return this.JoinedDevices?this.JoinedDevices:this.Devices
            },
            AttachedDevice(){
                let devices = this.LocalDevices
                console.log(devices,'local devices......');
                if(!devices){return null}
                let filtered = devices.find(pc=>pc.key == 'attached_device')
                return filtered
            },
        },
        methods: { 
            
            async DELETE_ALL(){
                for (let index = 0; index <1580; index++) {
                    console.log('delete'+index);
                    await this.$store.dispatch("fi_delete",{
                        action:"paths",
                        path:"/"+index
                    }) 
                    console.log('delete'+index);
                } 
            },
            LOGIN(){
                this.MBS.events.$emit('LOGIN')
            },
            clicked(){
                console.log( chrome.runtime.sendMessage());
                // chrome.tabs.query({ active: true, currentWindow: true }, function(tabs) {
                //     chrome.tabs.sendMessage(tabs[0].id, { action: 'open-notepad' }, function(response) {
                //     console.log(response,'response');
                //     });
                // });
            },
            //LOAD
            async EXIT_PAGE(){
                this.PAGE_SHOW = true
                let device = this.AttachedDevice
                let device_company = device?device.company:null
                let device_company_key = device_company?device_company.company_key:null
                let selected_company = this.SelectedCompany
                let selected_company_key = selected_company?selected_company.company_key:null

                
                console.log(selected_company,'selected_company....');
                console.log(device_company,'device_company....');
                console.log(device,'device....');

                if (!this.us) {
                    this.MBS.actions.go("/")
                }else{
                    if (!selected_company && !device_company) {
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:"SELECT COMPANY",
                            text:"You must select a company first. For more information contact +265993259202",
                            btnYes:"SELECT COMPANY",
                            action:{
                                code:"=GO",
                                to:"/account/companies"
                            }
                        }) 
                    }else if (!selected_company && device_company) {
                        await this.MBS.actions.SELECT_COMPANY(device_company)
                        // this.MBS.actions.go('/')
                    }else if (selected_company && device_company) {
                        if (device.computer_usage_option_code == "till_computer") {
                            this.MBS.actions.go(this.MBS.actions.COMPANY_LINK('/sale',true))
                        }else if (device.computer_usage_option_code == "order_computer") {
                            this.MBS.actions.go(this.MBS.actions.COMPANY_LINK('/orders',true))
                        }else if (device.computer_usage_option_code == "warehouse_computer") {
                            this.MBS.actions.go(this.MBS.actions.COMPANY_LINK('/warehouse',true))
                        }
                        // await this.MBS.actions.SELECT_COMPANY(company)
                        // this.MBS.actions.go('/')
                    }
                }
            },
            FEEDBACKS(time=400){ 
                setTimeout(()=>{
                    let us = this.us 
                    let ps = this.processes
                    let ld = this.loading
                    let res = this.responses 

                    //CHECK USER STATE 
                    if (ps) {
                        let logging = ps.find(process=>{
                            return process.name == 'logging'
                        }) 

                        if (logging) {
                            this.PAGE_SHOW =false
                        }else{
                            this.EXIT_PAGE()
                        }     
                    }else{
                       this.EXIT_PAGE()
                    }

                     
                },time)
            }
        },
        watch: { 
            us(v){
                this.FEEDBACKS()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            },
            SelectedCompany(value){
                if (value) {
                    // console.log(value,'...');
                    // this.MBS.actions.dialog({})
                }
            },  
        },
    }
</script>
 
