<template>
    <v-dialog 
        :fullscreen="false"
        :value="show"                  
        :persistent="false" >
         
        <v-layout row wrap class="ma-0 white">  
            <!-- //LEFT SIDE -->
            <v-flex md3 sm4 xs12 > 
                <v-card tile flat :height="vsh-100" class="" >
                    <v-layout column fill-height>
                        <v-card class="ml-9 pt-5 mb-2" flat @click="FINISH_SESSION({canceled:true})">
                            <v-layout row wrap align-center class="my-0">
                                <v-btn icon outlined>
                                    <v-icon>arrow_back</v-icon>
                                </v-btn>
                                <div class="ml-3 font-weight-bold">Back</div>
                            </v-layout> 
                        </v-card>
                        <v-divider></v-divider>
                        <v-card height="100%" tile flat class="ml-9 mbs-scroll-y">
                            <v-card-title primary-title class="pb-0">
                                Print
                            </v-card-title>
                            <v-card-text>
                                <v-layout  class="ma-0">
                                    <v-card outlined tile  @click="PRINT()">
                                        <v-layout column class="ma-0 pa-5">
                                            <v-icon>print</v-icon>
                                            <div class="font-weight-bold">Print</div> 
                                        </v-layout>  
                                    </v-card>
                                    <div class="ml-3">Copies: </div>
                                    
                                    <v-text-field class="ml-2 mbs-border-0"
                                        type="number" readonly
                                        outlined filled dense
                                        v-model="numberOfCopies" 
                                    ></v-text-field>
                                </v-layout> 
                            </v-card-text>
                            <v-card-title primary-title class="pb-0">
                                Printer
                            </v-card-title>
                            <v-card-text> 
                                <v-autocomplete 
                                    outlined  
                                    :required ="true"
                                    v-model="selected_printer_name"  
                                    :items="AllPrinters"
                                    item-text="name" 
                                    item-value="name"
                                    label="Select" 
                                    :filter="(item, queryText, itemText) => { 
                                        let index = (a,b)=>{
                                            if(!a||!b){return false}
                                            a = ''+a; b = ''+b
                                            return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                        } 
                                        let first = index(itemText,queryText) 
                                        return first  
                                    }"
                                    class="mbs-border-0">
                                    <!-- <template v-slot:prepend-item  v-if="MBS.actions.SIZE(FIELD_APPEND_ACTIONS(item.action))>0 && !search_input">
                                        <v-row class="ma-0 pl-3 pr-2">
                                            <v-col cols="12" class="px-0"
                                                :sm="MBS.actions.SIZE(FIELD_APPEND_ACTIONS(item.action))==1?'12':'6'" 
                                                v-for="(action,index) in FIELD_APPEND_ACTIONS(item.action)" :key="index">
                                                <v-btn 
                                                    :to="action.link"
                                                    color="primary" 
                                                    @click="FIELDS_ACTION(action)"
                                                    class=" my-2" width="97%"  
                                                    depressed  >
                                                    <v-icon v-if="action.icon">{{action.icon}}</v-icon>
                                                    <div class="ml-2 font-weight-bol">
                                                        {{action.name}}
                                                    </div>
                                                </v-btn> 
                                            </v-col> 
                                        </v-row>
                                    </template> -->

                                    <!-- <template v-slot:no-data  >
                                        <v-row class="ma-0 pl-3 pr-2" v-if="MBS.actions.SIZE(FIELD_NO_DATA_SEARCH_ACTIONS(item.action))>0 && search_input"> 
                                            <v-col cols="12" class="px-0" 
                                                :sm="MBS.actions.SIZE(FIELD_NO_DATA_SEARCH_ACTIONS(item.action))==1?'12':'6'" 
                                                v-for="(action,index) in FIELD_NO_DATA_SEARCH_ACTIONS(item.action)" :key="index">
                                                <v-btn 
                                                    :to="action.link"
                                                    color="primary" 
                                                    @click="FIELDS_ACTION(action,search_input)"
                                                    class=" my-2" width="97%"  
                                                    depressed  >
                                                    <v-icon v-if="action.icon">{{action.icon}}</v-icon> 
                                                    <div class="ml-2 font-weight-bol">
                                                        {{action.action_name}} "{{search_input}}" {{action.name}}
                                                    </div>
                                                </v-btn> 
                                            </v-col>  
                                        </v-row> 
                                        <v-row class="ma-0 pl-3 pr-2" v-if="MBS.actions.SIZE(FIELD_NO_DATA_ACTIONS(item.action))>0"> 
                                            <v-col cols="12" class="px-0"
                                                :sm="MBS.actions.SIZE(FIELD_NO_DATA_ACTIONS(item.action))==1?'12':'6'" 
                                                v-for="(action,index) in FIELD_NO_DATA_ACTIONS(item.action)" :key="index">
                                                <v-btn 
                                                    :to="action.link"
                                                    color="primary" 
                                                    @click="FIELDS_ACTION(action,search_input)"
                                                    class=" my-2" width="97%"  
                                                    depressed  >
                                                    <v-icon v-if="action.icon">{{action.icon}}</v-icon> 
                                                    <div class="ml-2 font-weight-bol">
                                                        {{action.name}}
                                                    </div>
                                                </v-btn> 
                                            </v-col>  
                                        </v-row>
                                    </template>  -->
                                    <template v-slot:item="data" v-if="true">
                                        <template v-if="(typeof data.item !=='object')" >
                                        <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>

                                        <!-- //ITEM ICON -->
                                        <template >
                                            <v-list-item-avatar 
                                                :tile="true"
                                                :color="'grey'" > 
                                                <v-icon v-if="data.item.isDefaultPrinter">mdi-printer-check</v-icon>
                                                <v-icon v-else-if="data.item.is_active">mdi-printer</v-icon>
                                                <v-icon v-else-if="data.item.is_company_printers">mdi-printer-pos-cog</v-icon>
                                                <v-icon v-else>mdi-printer-alert</v-icon>
                                            </v-list-item-avatar> 
                                        </template>
                                        <v-list-item-content>
                                            <!-- //ITEM TITLES -->
                                            <template >
                                                <v-list-item-title >
                                                    {{data.item['name']}}
                                                </v-list-item-title> 
                                            </template>
                                            <!-- //ITEM SUBTITLES -->
                                            <template >
                                                <v-list-item-subtitle >
                                                    <span v-if="data.item.name =='Other Installed Printer'">Choose Installed Printer  
                                                    </span>
                                                    <span v-else-if="data.item.is_active">Configured & available</span>
                                                    <span v-else-if="data.item.is_company_printers">Configured but not available</span>
                                                    <span v-else>Only available</span> 

                                                    <strong class="secondary--text ml-5" v-if="data.item.printer_type_name">{{data.item.printer_type_name}}</strong>  
                                                    <strong class="secondary--text ml-5" v-else>{{BrowserOS?BrowserOS.name+' '+BrowserOS.versionName:'...'}}</strong> 
                                                </v-list-item-subtitle> 
                                            </template> 
                                        </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>   
                            </v-card-text>
                            <v-card-title primary-title class="pb-0">
                                Settings
                            </v-card-title>
                            <v-card outlined tile min-height="150" class="mr-5 ml-3" >
                                
                            </v-card> 
                        </v-card>
                        <v-spacer></v-spacer>
                        <v-divider></v-divider>
                        <v-card-actions class="pa-4">
                            <v-btn  @click="FINISH_SESSION({canceled:true})" width="48%" color="primary" tile>Cancel</v-btn>
                            <v-btn :loading="inputLoading"  @click="PRINT()" width="48%" color="secondary" tile>Print</v-btn> 
                        </v-card-actions>
                    </v-layout>
                </v-card>
            </v-flex> 
            <!-- //RIGHT SIDE -->
            <v-flex class="py-" >
                <v-card outlined tile :height="vsh-110"  class="mbs-scroll-y pa-4 b2"> 
                    {{ type }}..
                    <v-layout justify-center align-center v-if="printSVG">
                        <br> <br> 
                        <img id="print_svg" :src="printSVG" alt="Loading...."> 
                    </v-layout>
                    <div v-else-if="type=='sale_order'||type=='bill'" >
                        <mbs-pdf-sales-temp1 
                            v-if="displayOption==='pdf_view'"
                            v-model="image_loaded"
                            @image_loaded="IMAGE_LOADED"
                            :data="data"
                            :type="type"
                            :title="title"
                            :merge_doc_type="merge_doc_type"
                            /> 
                        <mbs-sales-temp1 v-else
                            :data="data"
                            :type="type"
                            :title="title"
                            :merge_doc_type="merge_doc_type"
                            />  
                    </div>
                    <div v-else-if="type == 'sales_items'" >  
                        <mbs-epson-sales-items-temp1 
                            :data="data"
                            :type="type"
                            :title="title"
                            :merge_doc_type="merge_doc_type"
                            />  
                    </div> 
                    <div v-else-if="type=='quotation'" >
                        {{ title }}
                        <mbs-pdf-sales-temp1 
                            v-if="displayOption==='pdf_view'"
                            v-model="image_loaded"
                            @image_loaded="IMAGE_LOADED"
                            :data="data"
                            :type="type"
                            :title="'Quotation'"
                            :merge_doc_type="merge_doc_type"
                            /> 
                        <mbs-sales-temp1 v-else
                            :data="data"
                            :type="type"
                            :title="'Quotation'"
                            :merge_doc_type="merge_doc_type"
                            />  
                            <!-- :title="title" -->
                    </div>
                    <div v-else-if="type == 'docket'" >  
                        <mbs-sales-temp1  
                            :data="data"
                            :document_type="type" 
                            :title="title"
                            :merge_doc_type="merge_doc_type"
                            />
                    </div>
                    <div v-else-if="type == 'payment'" >  
                        <mbs-sales-temp1  
                            :data="data"
                            :document_type="type" 
                            :title="title"
                            :merge_doc_type="merge_doc_type"
                            />
                    </div>
                    <div v-else-if="type == 'cashup'" > 
                        <mbs-epson-cashup-temp1
                            :data="data"
                            :type="type"
                            :title="title"
                            :merge_doc_type="merge_doc_type"
                            />  
                    </div>
                    <div v-else-if="type == 'z_read'" >  
                        <mbs-epson-day-shift-temp1
                            :report_type="title"
                            :data="data"
                            :type="type"
                            :title="title"
                            :merge_doc_type="merge_doc_type"
                            />  
                    </div>
                    <mbs-receipt-temp1 v-else-if="type == 'profit_and_loss'" 
                        :sale_orders="data?data.sale_orders:null"
                        :expenses_accounts="data?data.expenses_accounts:null"
                        :type="type"
                        />  
                    <v-overlay color="white" opacity="0.7" v-if="inputLoading" >
                        <mbs-page-spinner  /> 
                    </v-overlay>
                </v-card>  
            </v-flex>
        </v-layout> 
    </v-dialog>
</template>

<script>
    import DATA from "../../plugins/DATA"
    import {mapState} from "vuex"
    import browser from 'browser-detect'
    import Bowser from "bowser";
    let NAME = 'AUTO-PRINTER'
    export default {
        name:NAME,
        props:['printing_action','data','merge_doc_type','type','display_option',
        'title','auto_print','auto_printer_name',], 
        data(){
            return{
                show:true,
                DATA:DATA,
                printSVG:null,
                inputLoading:false,
                printingOption:true,
                numberOfCopies:1,
                selected_printer_name:'Other Installed Printer',
                image_loaded:false,
            } 
        }, 
        mounted(){    
            if (!this.ConnectedPrinters) {
                this.UPDATE_CONNECTED_PRINTERS()
            }
            if (this.auto_printer_name) {
                this.selected_printer_name = this.auto_printer_name
            }
            if (this.auto_print) {  
                if (this.displayOption ==="pdf_view" && !this.image_loaded) {
                    return
                } 

                // this.PRINT()

                // this.MBS.events.$emit("PRINT_PDF",{
                //     save:true,
                //     auto_print:true,
                // })

            }   

            console.log(this.data,'data...............');
        },
        computed:{
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },  
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                K_CONNECTOR: state=> state.items['K_CONNECTOR'],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                Devices: state=> state.items[(DATA.ITEMS.DEVICES.values).toUpperCase()],
                JoinedDevices: state=> state.join[(DATA.ITEMS.DEVICES.values).toUpperCase()],
                ConnectedPrinters: state=> state.items[(DATA.ITEMS.CONNECTED_PRINTERS.values).toUpperCase()],
                PrinterTypes: state=> state.app[(DATA.ITEMS.PRINTER_TYPES.values).toUpperCase()]
            }),  
            DataItems(){
                return this.JoinedDevices?this.JoinedDevices:this.Devices
            },
            Printers(){
                let devices = this.DataItems  
                let printers = []
                if (devices) {
                    printers = devices.filter(item=>{
                        return this.MBS.actions.TEXT_UP(item.device_type)
                         == this.MBS.actions.TEXT_UP(DATA.ITEMS.PRINTERS.value)
                    })  
                }
                return [ 
                    ...printers,
                    {
                        code:0,
                        name:"Other Installed Printer",
                        isDefaultPrinter:false,
                        priority:1
                    },
                ]
            },
            AllPrinters(){
                let printers = this.Printers  
                let connected_printers = this.ConnectedPrinters  
                if(!printers && connected_printers){return null}
                let new_printers = []
                let find_printer=(name,printers)=>{
                    if(!printers){return null}
                    return printers.find(printer=>printer.name==name)
                }
                
                
                if(connected_printers){
                    connected_printers.forEach(printer => {
                        let company_printer = find_printer(printer.name,printers)
                        if (company_printer) {
                            new_printers.push({
                                ...company_printer,
                                ...printer,
                                is_installed:true,
                                is_company_printers:true,
                                is_active:true,
                                priority:printer.isDefaultPrinter?1:2
                            })
                        }else{
                            new_printers.push({ 
                                ...printer,
                                is_installed:true, 
                                priority:printer.isDefaultPrinter?1:4
                            })
                        }
                    });
                }

                if(printers){
                    printers.forEach(printer => {
                        if (!find_printer(printer.name,connected_printers)) {
                            new_printers.push({
                                ...printer,
                                is_company_printers:true,
                                priority:printer.isDefaultPrinter?1:3
                            })
                        }
                    });
                }  
                let list = [...new_printers]
                list.sort(this.MBS.actions.dynamicSort("priority",true))   
                return list  
            },
            SelectedPrinter(){
                let Printers = this.AllPrinters 
                let selected_printer_name = this.selected_printer_name 
                if(!Printers){return null}
                let printer = Printers.find(item=>{
                    return item.name == selected_printer_name
                }) 
                return printer
            },
            Browser(){
                let browser = Bowser.getParser(window.navigator.userAgent)
                return browser
            },
            BrowserData(){
                let browser = this.Browser 
                return browser?browser.parsedResult?browser.parsedResult.browser:null:null
            }, 
            BrowserOS(){
                let browser = this.Browser
                return browser?browser.parsedResult?browser.parsedResult.os:null:null
            },
            DocumentSettings(){
                let settings = this.CompanySettings 
                let template_settings = settings?settings.template_settings:null
                let receipt = template_settings?template_settings.receipt:null 
                let invoice = template_settings?template_settings.invoice:null 
                let quotation = template_settings?template_settings.quotation:null 

                let data = this.data
                let order_type = data?data.order_type:null
                let account_type = data?data.account_type:null

                if (order_type == DATA.ITEMS.QUOTATIONS.value) {
                   return quotation 
                }else if (order_type == DATA.ITEMS.SALE_ORDERS.value) {
                    if (account_type == 'on_account') {
                        return invoice 
                    } 
                }
                return receipt
            },
            displayOption(){
                let settings = this.DocumentSettings  
                let display_options = settings?settings.display_options:null 
                return display_options
            },
        },
        methods:{
            RESET_ALL_DATA(){ 
                try { 
                    Object.assign(this.$data, this.$options.data())  
                    this.show=false 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'RESET_ALL_DATA',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 
            PRINT(){ 
                try {   
                    let SelectedPrinter = this.SelectedPrinter 
                    let printer_name = SelectedPrinter?SelectedPrinter.name:null 
                    let printer_type_name = SelectedPrinter?SelectedPrinter.printer_type_name:null  
                    console.log(SelectedPrinter,'SelectedPrinter....');
                    if (this.display_option ==="pdf" ) {
                        this.MBS.events.$emit("PRINT_PDF",{
                            save:true,
                            auto_print:true,
                        })
                        return
                    }
                    if (!SelectedPrinter||printer_name=="Other Installed Printer") {
                        this.WEB_PRINT()
                    }else if (printer_type_name=="PDF") {
                        this.MBS.events.$emit("PRINT_PDF",{
                            save:true,
                            auto_print:true,
                        })
                    }else if (printer_type_name=="Epson TM series") {
                        this.EPSON_PRINT()
                    } else {
                        this.EPSON_PRINT()
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PRINT',
                        page:NAME, 
                    }) 
                    this.show = false
                    this.inputLoading = false 
                } 
            }, 
            async UPDATE_K_CONNECTOR(){ 
                try { 
                    await this.MBS.actions.K_CONNECT() 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_K_CONNECTOR',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            async UPDATE_CONNECTED_PRINTERS(){ 
                try { 
                    let res = await this.MBS.actions.GET_CONNECTED_PRINTERS() 
                    let data = res?res.data:null   
                    await this.$store.dispatch("add_items",{
                        name:DATA.ITEMS.CONNECTED_PRINTERS.values,
                        data:data
                    })  
                } catch (error) { 
                }
            },
            WEB_PRINT(){ 
                try {  
                    this.inputLoading = true 
                    let print_template = document.getElementById('print_template') 
                    console.log(print_template,'print_template.....');
                    setTimeout(async () => {
                        if (!print_template) {
                            setTimeout(() => {
                                this.PRINT()
                            }, 10); 
                        }else {
                            // this.printJS('print_template',"html" )
                            let res = await this.printJS(
                               { 
                                   printable: 'print_template', 
                                   type: 'html',  
                                   targetStyles:"*",
                                //    style:'width:40%;',
                                //    maxWidth:"40%"
                                }
                            )
                            setTimeout(() => { 
                                this.inputLoading = false  
                                this.FINISH_SESSION({
                                    printer_response:res, 
                                    canceled:false
                                })
                            }, 1500);
                        }  
                    }, 10);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PRINT',
                        page:NAME, 
                    }) 
                    this.show = false
                    this.inputLoading = false 
                } 
            }, 
            async EPSON_PRINT(){ 
                try {  
                    console.log(this.type,'this.type...');
                    let selected_printer = this.SelectedPrinter
                    if (selected_printer) {
                        selected_printer={
                            ...selected_printer,
                            printer_name:selected_printer.printer_name?selected_printer.printer_name:selected_printer.name
                        }
                    }
                    let data = this.data
                    let order_account_type =  data?data.order_account_type:null 
                    let print_request = null
                    let printer_response = null
                    if (this.type=="z_read") {
                        let res = await this.MBS.actions.EPSON_DAY_SHIFT_REQUEST({
                            report_type:this.type,
                            day_shift:data,
                            printer:selected_printer
                        }) 
                        print_request = res?res.data:null  
                        printer_response = await this.MBS.actions.EPSON_PRINT_LINES(print_request)  
                    }else if (this.type=="cashup") {
                        let res = await this.MBS.actions.EPSON_CASHUP_REQUEST({
                            data:data,
                            report_title:this.type,
                            printer:selected_printer
                        }) 
                        print_request = res?res.data:null  
                        printer_response = await this.MBS.actions.EPSON_PRINT_LINES(print_request)  
                    }else if (this.type=="payment") {
                        let res = await this.MBS.actions.EPSON_SALE_PAYMENT_REQUEST({
                            SaleOrder:data,
                            sale_order:data,
                            printer:selected_printer,
                            print_title:this.title,
                        }) 
                        print_request = res?res.data:null  
                        printer_response = await this.MBS.actions.EPSON_PRINT_LINES(print_request)  
                    }else if (this.type=="docket") {
                        let res = await this.MBS.actions.EPSON_DOCKET_REQUEST({
                            SaleOrder:data,
                            sale_order:data,
                            printer:selected_printer,
                            print_title:this.title,
                        }) 
                        let print_requests = res?.data 
                        console.log(res,'res................');
                        if (print_requests) {
                            print_requests.forEach(async doc => {
                                printer_response = await this.MBS.actions.EPSON_PRINT_LINES({...doc,title:this.title}) 
                                
                            });
                        } 
                    }else if (this.type=="sales_items") {
                        console.log(data,'data............');
                        let res = await this.MBS.actions.EPSON_DAY_ITEM_REQUEST({
                            data:data,
                            report_title:this.type,
                            printer:selected_printer
                        }) 
                        print_request = res?res.data:null  
                        printer_response = await this.MBS.actions.EPSON_PRINT_LINES(print_request)  
                    }else {
                        let res = await this.MBS.actions.EPSON_SALE_ORDER_REQUEST({ 
                            SaleOrder:data,
                            sale_order:data,
                            printer:selected_printer,
                        }) 
                        print_request = res?res.data:null   
                        if(order_account_type=="on_account") {
                            printer_response = await this.MBS.actions.EPSON_PRINT_LINES({...print_request,title:"DUPLICATE INVOICE"})
                        }else if(order_account_type=="payment_option") {
                            printer_response = await this.MBS.actions.EPSON_PRINT_LINES({...print_request,title:"DUPLICATE SALES RECEIPT"})
                        }else {
                            printer_response = await this.MBS.actions.EPSON_PRINT_LINES({...print_request,title:"DUPLICATE.. SALES RECEIPT"}) 
                        } 
                        // let data = print_res?print_res.data:null
                        // let allReceiptCount = data?data.allReceiptCount:null
                        // let fiscalReceiptCount = data?data.fiscalReceiptCount:null
                        // let order_key =allReceiptCount?allReceiptCount+"/"+fiscalReceiptCount:null
                        // if (order_key && order) {
                        //     order.order_key = order_key
                        // }    
                    }
                    this.FINISH_SESSION({
                        printer_response:printer_response,
                        print_request:print_request,
                        canceled:false
                    })
                } catch (error) {
                    let message = error.message 
                    console.log(error,'error...ZZZ ');
                    if (message) {
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:"PRINTING ERROR",
                            text:message,
                            btnYes:"OK", 
                            action:{
                                // code:PAGE_NAME+"=CLEAR-SALES"
                            }
                        }) 
                    } else {
                        message = 'Kipcount Connecter is offline. Please make to start the connecter and try again.'
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:"PRINTING ERROR",
                            text:message,
                            btnYes:"OK", 
                            action:{
                                // code:PAGE_NAME+"=CLEAR-SALES"
                            }
                        }) 
                    }
                    this.MBS.actions.error({
                        error:error,
                        from:'EPSON_PRINT',
                        page:PAGE_NAME, 
                    }) 
                }
            },

            FINISH_SESSION(payload){ 
                try {  
                    this.$emit('finish_print',this.printing_action,payload)  
                    this.RESET_ALL_DATA()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FINISH_SESSION',
                        page:NAME, 
                    }) 
                }
            }, 

            IMAGE_LOADED(event){
                if (this.auto_print) {
                    setTimeout(() => { 
                        this.PRINT()
                    }, 500);
                } 
            }, 
        },
        watch:{
            show(value){ 
                // if (value == false) {
                //     this.$emit('finish_print',this.printing_action)
                // }
            },   

        } 
    }
</script>
