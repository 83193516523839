<template>
    <div>    
        <v-divider></v-divider>  
        <mbs-page-tab 
            :items="FULL_LINKS"/>   
        <v-divider></v-divider>  
        <v-divider></v-divider>  
        <v-divider></v-divider>  
        <v-container grid-list-xl> 
            <v-layout class="mt-1" align-center="" justify-center="">
                <v-col sm="12" cols="12"> 
                    <v-card :min-height="vsh/2" >  
                        <!-- <v-btn @click="TABLE_ACTION('delete_all')" color="success">Delete All</v-btn> -->
                        <!-- <v-btn @click="RELOAD_PAUSE_ITEMS()" color="success">RELOAD_PAUSE_ITEMS</v-btn>
                        <v-btn @click="RELOAD_ITEMS()" color="success">RELOAD_ITEMS</v-btn> -->
                         
                        <!-- <v-layout class="ma-5">
                            JOINING:
                            {{isJoining}}
                        </v-layout>
                        <v-layout class="ma-5">
                            CALCULATING:
                            {{isCalculating}}
                        </v-layout> -->
                        <mbs-item-manager  v-model="input_table"
                            :table_title="'Manage Items'"
                            :table_title_icon="'mdi-tag'"
                            :add_vertical_lines="true"
                            :row_click="true"
                            :show_select="false"
                            :height="null"
                            :fixed_header="false"
                            add_item_title="CREATE ITEM"
                            :add_item_action="{
                                action_do:'Create',
                                action_doing:'Creating'
                            }"
                            :table_tabs="TABLE_TABS"
                            :top_label="false"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"
                            :field_items="ADDING_ITEM?ADDING_ITEM.fields:DATA.FIELDS.items"
                            :filters="FILTERS"
                            :header_items="CALCULATED_HEADER(HEADER_ITEMS)"
                            :header_items2="CALCULATED_HEADER(HEADER_ITEMS2)"
                            :data_items="ItemsData"  
                            :is_joined="true"  
                            :path_item="PATH_ITEM"  
                            :select_items="Selectors"  
                            :total_item_values="TOTAL_ITEM_VALUES"  

                            :table_actions="TableActions" 
                            @ON_ITEM_SELECTED="selected_items=$event"
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                            @ADDED="ADDED"
                            @EDITED="EDITED"
                            @DELETED="DELETED"
                        />  
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  


        <div v-if="ADDING_ITEM">
            <mbs-item-manager 
                :show="false" 
                :add_item_action="ADDING_ITEM.add_item_action"
                :edit_item_action="ADDING_ITEM.edit_item_action"
                :name_items="ADDING_ITEM.names"
                :name_item="ADDING_ITEM.name"
                :value_items="ADDING_ITEM.values"
                :value_item="ADDING_ITEM.value"
                :field_items="ADDING_ITEM.fields"   
                :data_items="ItemsData"  
                :is_joined="true" 
                :path_item="ADDING_ITEM.path"  
                :select_items="Selectors"  
                @ADDED="ADDED"
                @EDITED="EDITED"
                @DELETED="DELETED"
            /> 
        </div>
        <!-- DIALOGS -->
        <div>
            <!-- SCAN ITEM -->
            <v-dialog
                v-model="dialog_scanned_item"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_scanned_item">
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">SCANNED ITEM</div> 
                        <v-spacer></v-spacer>
                        <v-btn dark icon @click="dialog_scanned_item = false"><v-icon>mdi-close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <br>
                        <v-card height="300" outlined  v-if="!SCANNED_ITEM"> 
                            <v-layout fill-height align-center justify-center>
                                <v-icon size="100">mdi-barcode-scan</v-icon>
                            </v-layout>
                        </v-card> 
                        <v-card height="300" outlined  v-else-if="SCANNED_ITEM.item_code_not_found"> 
                            <v-layout column fill-height align-center justify-center>
                                <div>
                                    <v-layout justify-center align-center>
                                        <v-icon size="80">mdi-barcode-scan</v-icon>
                                        <div class="not-f8 font-weight-bold ml-4 grey--text text--darken-2">{{ SCANNED_ITEM.code }}</div> 
                                    </v-layout>

                                </div>
                                <v-btn @click="CREATE_SCANNED_ITEM(SCANNED_ITEM.code)" large color="primary" class="mt-7"><v-icon class="mr-2">mdi-tag-plus</v-icon> CREATE THIS ITEM</v-btn>
                            </v-layout>
                        </v-card> 
                        <v-card    outlined  v-else>  
                            <v-layout column fill-height align-center justify-center class="pa-5">
                                <div class="mt-5 my-2">

                                    <div>
                                        <v-layout row wrap align-center>
                                            <!-- <div>
                                                <v-avatar
                                                    size="80"
                                                    color="grey" tile >
                                                </v-avatar>
                                            </div> -->
                                            <v-icon size="80">mdi-barcode-scan</v-icon>
                                            <v-divider class="mx-5" vertical ></v-divider>
                                            <div class="not-f7 font-weight- ml-4 ">
                                                <div class="mb-1">
                                                   <span class="font-weight-bold grey--text text--darken-3">{{SCANNED_ITEM.name}} </span>
                                                </div>
                                                <div> 
                                                    <span class="font-weight-">{{SCANNED_ITEM.code}} </span>
                                                </div>  
                                                 
                                            </div> 
                                        </v-layout>
                                    </div>
                                    <v-divider class="my-5"   ></v-divider>
                                    <div >
                                        <v-layout row wrap align-center>
                                            <!-- <div>
                                                <v-avatar
                                                    size="80"
                                                    color="grey" tile >
                                                </v-avatar>
                                            </div> -->
                                            <v-icon size="80">mdi-tag</v-icon>
                                            <v-divider class="mx-5" vertical ></v-divider>
                                            <div class="not-f6"> 
                                                <div> 
                                                    Cost Price: MWK <span class="font-weight-bold">{{SCANNED_ITEM.cost_price_}} </span>
                                                </div>
                                                <div> 
                                                    Retail Price: MWK  <span class="font-weight-bold">{{SCANNED_ITEM.retail_price}} </span>
                                                </div>
                                                <div> 
                                                    Wholesale Price: MWK <span class="font-weight-bold">{{SCANNED_ITEM.wholesale_price_}} </span>
                                                </div>
                                            </div> 
                                        </v-layout>
                                    </div>
                                </div>
                                <v-btn @click="EDIT_SCANNED_ITEM(SCANNED_ITEM)" large color="primary" class="mt-9"><v-icon class="mr-2">mdi-pencil</v-icon> EDIT THIS ITEM</v-btn>
                            </v-layout>
                        </v-card> 
                    </v-card-text> 
                </v-card> 
            </v-dialog> 
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import {mapState,mapGetters } from "vuex"
    import { mdiFormatListGroupPlus } from '@mdi/js';
    let PAGE_NAME = DATA.ITEMS.ITEMS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,
                dialog_scanned_item:false,
                SCANNED_ITEM:null,
                input_table:{show_select:false},
                selected_items:null,

                NAME_ITEMS:DATA.ITEMS.ITEMS.names,
                NAME_ITEM:"_"+DATA.ITEMS.ITEMS.name,
                VALUE_ITEMS:DATA.ITEMS.ITEMS.values,
                VALUE_ITEM:DATA.ITEMS.ITEMS.value,  
                DATA:DATA,
                TOTAL_ITEM_VALUES:[ 
                    // {name:'Closing Stock (QTY)',value:'number_quantity',show_value:"number_quantity"},
                    // {name:'Total Cost',value:'number_total_cost',show_value:'number_total_cost_',money:true},
                ], 
                ADDING_ITEM:null,
            }
        },
        created() {
            try { 
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES); 
                this.MBS.events.$on('ON_QR_SCAN', this.ON_QR_SCAN);    
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                // console.log(this.SelectedCompany);
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
                this.FEEDBACKS(1000)  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            /////////
            selectedCompany(){  
                return this.$store.getters.getSelectedCompany
            },  
            isCalculating(){  
                return this.$store.getters.isCalculating("ITEMS")
            },  
            isJoining(){  
                return this.$store.getters.isJoining("ITEMS")
            },  
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("stock",1,true) 
                return thisLinks
            }, 
            FULL_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("manage",2,true) 
                return thisLinks
            },
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true) 
                return path
            },  
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemCategories: state=> state.items[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],
                ItemDepartments: state=> state.items[(DATA.ITEMS.ITEM_DEPARTMENTS.values).toUpperCase()],
                Brands: state=> state.items[(DATA.ITEMS.BRANDS.values).toUpperCase()],
                Manufacturers: state=> state.items[(DATA.ITEMS.MANUFACTURERS.values).toUpperCase()], 
                TaxGroups: state=> state.app[(DATA.ITEMS.TAX_GROUPS.values).toUpperCase()], 
                SelectedCompany: state=> state.items["SELECTED_COMPANY"],  
                PricingTiers: state=> state.items[(DATA.ITEMS.PRICING_TIERS.values).toUpperCase()], 
                JoinedPricingTiers: state=> state.join[(DATA.ITEMS.PRICING_TIERS.values).toUpperCase()], 
                DefaultPricingTiers: state=> state.app['DEFAULT_PRICING_TIERS'], 
                ItemOptionTypes: state=> state.app['ITEM_OPTION_TYPES'], 
                DownloadRequests: state=> state.items[(DATA.ITEMS.DOWNLOAD_REQUESTS.values).toUpperCase()], 
            }),  

            PricingTiersData(){
                return this.JoinedPricingTiers?this.JoinedPricingTiers:this.PricingTiers
            }, 
            AllPricingTiers(){
                let defaults = this.DefaultPricingTiers
                let tiers = this.PricingTiersData 
                let all = []
                if (defaults) {
                    all.push(...defaults)
                }if (tiers) {
                    tiers.forEach(tier => {
                        let index = all.findIndex(item=>item.key == tier.key)
                        if (index==-1) {
                            all.push(tier) 
                        } else {
                            all[index] = {
                                ...all[index],
                                ...tier
                            }
                        }
                    });
                }
                return all
            }, 
            ItemsDownloadRequest(){
                let drs = this.DownloadRequests
                if(!drs){return null}
                let dr = drs.find(dr=>dr.name=="items")
                return dr
            },
            TableActions(){
                let dr = this.ItemsDownloadRequest 
                let dr_on = dr?.on
                let selected_items = this.selected_items
                let input_table = this.input_table
                let show_select = input_table?input_table.show_select:false
                let main_action = [
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false, indicator_r:dr_on,  text:"Refresh"},   
                    // {type:"action",btn:true,icon:'mdi-tag-plus',action:"add_item",color:"secondary", outlined:false,  text:"Create"},   
                    {type:"action",btn:true,icon:'mdi-tag-plus',action:"add_action_item",color:"secondary", outlined:false,  text:"Create Item"},   
                    {type:"action",btn:true,icon:'mdi-store-cog',action:"stock_settings",color:"secondary", outlined:false,  text:"Stock Settings"}, 
                    // {type:"action",btn:true,icon:'mdi-sort-alphabetical-variant',action:"filter_items",color:"", outlined:true, btn_icon:true,class:"ml-3 mr-",  text:""},   
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                    {type:"action",icon:"mdi-database-refresh",action:"refresh_with_outlet_stock",color:"secondary", outlined:false,  text:"Refresh with Outlets"}, 
                ]

                 
                let selected_items_action = [
                    {type:"action",btn:true,icon:"mdi-close",action:"cancel_selected_items",color:"secondary", outlined:false,  text:"Cancel Selected Items"},  
                ]
                if(this.MBS.actions.SIZE(selected_items)){
                    selected_items_action.push(
                        {type:"action",btn:true,icon:'mdi-delete',action:"archive_selected_items",color:"secondary", outlined:false,  text:"Archive Selected Items"}
                    )
                }
                return  show_select?selected_items_action:main_action
            },
            TABLE_TABS(){
                return [
                    {
                        name:"All Items",show_if:(this_,item)=>{ 
                            let stock = this.toNumber(item["number_quantity"])>0?true:false
                            return true
                        },
                        indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}
                        }
                    }}, 
                    {
                        name:"Stock",show_if:(this_,item)=>{ 
                            let stock = this.toNumber(item["number_quantity"])>0?true:false
                            return stock
                        },
                        indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}
                        }
                    }},  
                    {
                        name:"Out of Stock",show_if:(this_,item)=>{ 
                            let stock = this.toNumber(item["number_quantity"])>0?true:false
                            return  !stock
                        },
                        indicator:{
                        color:'primary',
                        value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    }},  
                ]
            },
            FILTERS(){ 
                return [ 
                    {
                        type:"autocomplete",value:"department_code",prepend_inner_icon:'mdi-format-list-group',clearable:true,filled:false,multiple:true
                        ,chips:true,deletable_chips:true,label:"Department",
                        select:{
                            name:'ItemDepartments',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"category_code",prepend_inner_icon:'mdi-shape-plus',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Category",
                        select:{
                            name:'ItemCategories',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },   
                    {
                        type:"autocomplete",value:"manufacturer_code",prepend_inner_icon:'mdi-factory',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Manufacturer",
                        select:{
                            name:'Manufacturers',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },   
                    {
                        type:"autocomplete",value:"item_code",search_value:"code",barcode:true,prepend_inner_icon:'mdi-barcode',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Barcode",
                        select:{
                            name:'Items',
                            value:'code',
                            text:'code'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"key",barcode:false,prepend_inner_icon:'mdi-tag-text',clearable:true,filled:false,
                        multiple:true,chips:true,deletable_chips:true,label:"Item Name",
                        select:{
                            name:'Items',
                            value:'key',
                            text:'name'
                        }, 
                        required:false,sm:12,md:12,step_name:"Name"
                    }, 
                    
                        
                ]
            }, 
            HEADER_ITEMS(){ 
                let tiers = this.AllPricingTiers
                let headers = [
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"No.",value:'no',class:' b ',show:false},  
                    {id:0,name:"",value:'icon_image',color:"",background_color:"2",size:35,tile:true,cellClass:' ',class:' b ',align:'',show:true},   
                    {id:0,name:"Barcode",value:"code_",align:"center",cellClass:' ',class:' b darken-4 ',show:true},   
                    {id:0,name:"Name",value:"name",cellClass:' table-background33',class:' b ',show:true}, 
                    {id:0,name:"Common Name",value:"common_name",cellClass:' table-background33',class:' b ',show:false}, 
                    {id:0,name:"Department",value:"item_department_name",cellClass:'',class:' b ',show:true}, 
                    {id:0,name:"Department Code",value:"item_department_code",cellClass:'',class:' b ',show:false},
                    {id:0,name:"Category",value:"category_name",cellClass:'',class:' b ',show:true}, 
                    {id:0,name:"Category Code",value:"category_code",cellClass:'',class:' b ',show:false}, 
                    {id:0,name:"Brand",value:"brand_name",cellClass:'',class:' b ',show:false}, 
                    {id:0,name:"Brand Code",value:"brand_code",cellClass:'',class:' b ',show:false}, 
                    {id:0,name:"Manufacturers",value:"manufacturer_name",cellClass:'',class:' b ',show:false}, 
                    {id:0,name:"Manufacturer Code",value:"manufacturer_code",cellClass:'',class:' b ',show:false}, 
                    {id:0,name:"Cost Price",value:"cost_price",class:' b ',show:false}, 
                    {price_tiers:true,show:false}, 
                    {id:0,name:"Open Stock",value:"all_opening_stocks",align:"center",class:' b ',show:false}, 
                    {id:0,name:"Received",value:"number_stock_receives",align:"center",class:' b ',show:false}, 
                    {id:0,name:"Transferred",value:"number_stock_transfers",align:"center",class:' b ',show:false}, 
                    {id:0,name:"Return Stock",value:"number_stocks_returns",align:"center",class:' b ',show:false}, 
                    {id:0,name:"Adjustments",value:"number_stock_adjustments",align:"center",class:' b ',show:false}, 
                    {id:0,name:"Return Sales",value:"number_returned_sales",align:"center",class:' b ',show:false}, 
                    {id:0,name:"Sold",value:"number_sales",align:"center",cellClass:'',class:' b ',show:true}, 
                    {id:0,name:"Stock",value:"number_quantity",align:"center",cellClass:'',class:' b ',show:true},  
                    // {id:0,name:"Average Cost",value:"number_average_cost_",align:"right",show:true}, 
                    {id:0,name:"T. Cost",value:"number_total_cost_",align:"right",class:' b ',show:false}, 
                    {id:0,name:"Tax Group",value:"tax_group_name",align:"center",class:' b ',show:false}, 
                    {id:0,name:"Tax Group Code",value:"tax_group_code",align:"center",class:' b ',show:false}, 
                    {id:0,name:"action",align:"right",show:true,cellClass:'',class:' b ',item:[  
                        // {name:"Edit ",action:"edit_item",icon:"mdi-pencil"},
                        {name:"Select Item",action:"select_item",icon:"mdi-checkbox-marked"},
                        {name:"Edit Item",action:"edit_action_item",icon:"mdi-pencil"},
                        {name:"Update Item Prices",action:"edit_item_price",icon:"mdi-tag"},
                        {name:"Manage Item Options",action:"manage_item_options",icon:"style"},
                        {name:"Department / Category",action:"edit_items_department_and_category",icon:"mdi-format-list-group"}, 
                        {name:"Add Kit / Service",action:"add_item_kit",icon:"mdi-group"},
                        {name:"Archive Item",action:"archive_item",icon:"mdi-delete"},
                    ]}, 
                ] 
                return headers 
            },
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"No.",value:'no',show:true},  
                    {id:0,name:"Barcode",value:"code",align:"center",show:true},      
                    {id:0,name:"Name",show:true}, 
                    {id:0,name:"Category",value:"category_code",show:true}, 
                    {id:0,name:"Brand",value:"brand_code",show:true}, 
                    {id:0,name:"Cost",value:"cost_price",align:true,show:true}, 
                    {price_tiers:true,show:false}, 
                    {id:0,name:"Tax",value:"tax",show:true}, 
                    {id:0,name:"action",align:"right",show:true,item:[  
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Delete "+this.NAME_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ]
            },  
            CALCULATED_HEADER(){
                let tiers = this.AllPricingTiers
                return headers=>{
                    if(!headers){return null}
                    let items = []
                    headers.forEach(header => {
                        if (header.price_tiers) {
                           if (tiers) {
                            tiers.forEach(tier => {
                                items.push({
                                    name:tier.name, 
                                    value:tier.key+"_",  
                                    align:"right",
                                    class:' b ',
                                    // show:tier.default?true:false
                                    show:false
                                })
                            });
                           } 
                        } else {
                            items.push(header)
                        } 
                    });
                    return items
                }
            },  
            Selectors(){ 
                let Items = this.ItemsData
                let Outlets = this.OutletsData
                let ItemCategories = this.ItemCategories
                let ItemDepartments = this.ItemDepartments
                let Brands = this.Brands
                let Manufacturers = this.Manufacturers
                let TaxGroups = this.TaxGroups
                let PricingTiers = this.AllPricingTiers
                let ItemOptionTypes = this.ItemOptionTypes
                return  {   
                    Items: Items,
                    Outlets: Outlets,
                    ItemCategories: ItemCategories,
                    ItemDepartments: ItemDepartments,
                    Brands: Brands,
                    Manufacturers: Manufacturers, 
                    TaxGroups: TaxGroups, 
                    PricingTiers: PricingTiers, 
                    ItemOptionTypes: ItemOptionTypes, 
                }
            },
            AllItems(){ 
                let Items = this.Items
                let JoinedItems = this.JoinedItems
                let CalculatedItems = this.CalculatedItems
                // if (CalculatedItems) {
                //     return CalculatedItems
                // }else  
                if (JoinedItems) {
                    return JoinedItems
                } 
                return Items
            },
            ItemsData(){ 
                let items = this.AllItems
                if(!items){return null}
                let filtered = items.filter(item=>!item.item_kits) 
                return filtered
            },
            OutletsData(){  
                let filtered = this.JoinedOutlets?this.JoinedOutlets:this.Outlets
                return filtered
            } 
        },
        methods: {  
            TABLE_ACTION(action,item){
                try { 
                    let route = this.route
                    let fullPath = route?route.fullPath:null
                     console.log(action,'action');
                    if (action == "add_action_item") {
                        this.CREATE_ITEM()
                    }else if (action == "refresh") {
                        let mbs_text = this.MBS.text.item_action("Item Data","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-ITEMS", 
                            } 
                        })
                    }else if (action == "delete_all") {
                        let mbs_text = this.MBS.text.item_action("All Item","Delete")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=DELETE-ALL-ITEMS", 
                            } 
                        })
                    }else if (action == "refresh_with_outlet_stock") {
                        let mbs_text = this.MBS.text.item_action("Items with stock outlets","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=REFRESH-WITH-OUTLETS", 
                            } 
                        })
                    }else if (action == "received_stock") {
                        this.MBS.actions.go(fullPath+"/receive")
                    }else if (action == "return_stock") {
                        this.MBS.actions.go(fullPath+"/return")
                    }else if (action == "transfer_stock") {
                        this.MBS.actions.go(fullPath+"/transfer")
                    }else if (action == "manage_customer_groups") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/office/people/customers/groups",true))
                    }else if (action == "stock_settings") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/settings",true))
                    }else if (action == "cancel_selected") {
                        this.CANCEL_SELECTED_ITEMS(item)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ITEM_ACTION(action,item,option){
                try {     
                    // console.log(action,'***************');
                    if (action == "on_row_clicked") {
                         
                    }else if (action == "select_item") {
                         
                    }else if (action == "edit_item") {
                         
                    }else if (action == "edit_action_item") {
                        this.EDIT_ITEM(item)
                    }else if (action == "add_new_item") {
                         this.CREATE_ITEM()
                    }else if (action == "add_item_kit") {
                         this.ITEM_ADD_KITS(item)
                    }else if (action == "edit_item_price") {
                         this.EDIT_ITEM_PRICE(item)
                    }else if (action == "manage_item_options") {
                         this.MANAGE_ITEM_OPTIONS(item)
                    }else if (action == "edit_items_department_and_category") {
                         this.EDIT_ITEM_DEPARTMENT_AND_CATEGORY(item)
                    }else{}
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CANCEL_SELECTED_ITEMS(items){
                try {     
                    console.log('oo...............');
                    this.MBS.events.$emit('CANCEL_SELECTED_ITEMS')
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CANCEL_SELECTED_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            CREATE_ITEM(input,barcode){
                try {     
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.items
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'add',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"add_item",
                            input:{
                                name:input,
                                code:barcode,
                            }
                        })  
                    }, 50);
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDIT_ITEM(item){
                try {      
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.items
                    this.ADDING_ITEM.edit_item_action =  {
                        action_do:'Edit',
                        action_doing:'Editing'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"edit_item",
                            item:item,
                            // input:{
                            //     name:input,
                            //     code:barcode,
                            // }
                        })  
                    }, 50);
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ITEM_ADD_KITS(item){
                try {    
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEM_KITS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.Add_items_kit
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Create',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEM_KITS.name,
                            action:"edit_item",
                            item:item
                        })  
                    }, 50); 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ADD_KITS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDIT_ITEM_PRICE(item){
                try {    
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.edit_item_price
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Create',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"edit_item",
                            item:item
                        })  
                    }, 50);
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_ITEM_PRICE',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            MANAGE_ITEM_OPTIONS(item){
                try {    
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.Add_items_options
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Create',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"edit_item",
                            item:item
                        })  
                    }, 50);
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'MANAGE_ITEM_OPTIONS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDIT_ITEM_DEPARTMENT_AND_CATEGORY(item){
                try {    
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.edit_items_department_and_category
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Create',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"edit_item",
                            item:item
                        })  
                    }, 50); 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_ITEM_DEPARTMENT_AND_CATEGORY',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            
            CREATE_SCANNED_ITEM(code){
                try { 
                    this.dialog_scanned_item = false 
                    this.CREATE_ITEM("",code)
                    // setTimeout(() => {
                    //     this.MBS.events.$emit("ITEM_ACTION",{
                    //         NAME_ITEM:this.NAME_ITEM,
                    //         action:"add_item",
                    //         input:{code:code}
                    //     })  
                    // }, 50);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_SCANNED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDIT_SCANNED_ITEM(item){
                try { 
                    this.dialog_scanned_item = false
                    this.EDIT_ITEM(item)
                    // setTimeout(() => {
                    //     this.MBS.events.$emit("ITEM_ACTION",{
                    //         NAME_ITEM:this.NAME_ITEM,
                    //         action:"edit_item",
                    //         item:item
                    //     })  
                    // }, 50);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_SCANNED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            
            async RELOAD_ITEMS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    let dr = this.ItemsDownloadRequest 
                    let dr_on = dr?.on
                    let settings = this.CompanySettings
                    let activate_stock_level = settings?settings.activate_stock_level:null
                    let on_item = activate_stock_level?true:false
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })

                    //---OFF ITEMS
                    if (dr_on) {
                        await this.$store.dispatch('off_refs',{name:DATA.ITEMS.ITEMS.values})
                        console.log("removed..............");
                    } 
                    let res = await this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.ITEMS.values,
                        on:on_item, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }) 
                    console.log(res,'res.......');
                    this.MBS.actions.progressDialog()
                    let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.ITEMS.values,"Refresh")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo, 
                    })
                } catch (error) {
                    console.log(error,'error.......');
                    this.MBS.actions.progressDialog()
                    let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.ITEMS.values,"Refresh")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo, 
                    }) 
                }

            },
            REFRESH_WITH_OUTLETS(){
                try {
                    let SelectedCompany = this.SelectedCompany           
                    let company_key = SelectedCompany?SelectedCompany.company_key:null  
                    let moment = this.MBS.date.moment
                    let local_created_at = moment?moment().valueOf():null 
                    let created_uid = this.us.uid
                    this.inputLoading = true 
                    this.MBS.actions.progressDialog({
                        show:true,
                        fixed:false,
                        title:"Reloading.."
                    }) 
                    let data = {
                        company_key:company_key, 
                        local_created_at:local_created_at, 
                        created_uid:created_uid, 
                    }
                    this.MBS.actions.POST_REQUEST({
                        action:this.VALUE_ITEM,
                        path:"refreshItemsWithOutletsStocks",
                        data:data, 
                    }).then(res=>{
                        this.inputLoading = false
                        this.MBS.actions.progressDialog()  
                        console.log(res,'res-----------------------******');  
                        let mbs_text = this.MBS.text.item_action_success("Items with stock outlets","Refreshing")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error......');
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_fail("Items with stock outlets","Refreshing")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }) 
                } catch (error) {
                    console.log(error,'error.......'); 
                } 
            },
            RELOAD_PAUSE_ITEMS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"off_refs..."
                    })
                    this.$store.dispatch('off_refs',{
                        name:DATA.ITEMS.ITEMS.values,
                        on:true, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{
                        console.log(res,'off_refs res.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.ITEMS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.ITEMS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },
            SELECT_SCANNED_ITEM(item_key, item_code){
                try {  
                    let items = this.AllItems 
                    this.SCANNED_ITEM = {code:item_code,key:item_key}
                    if (items) { 
                        let item_selected = items.find(item=>{
                            return  item_key?item.key == item_key:item.code == item_code
                        })
                        if (!item_selected) {
                            this.SCANNED_ITEM.item_code_not_found = true
                        } else {
                            this.SCANNED_ITEM = item_selected
                        }
                        this.dialog_scanned_item = true 
                    }
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_SCANNED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            async DELETE_ALL_ITEMS(){
                try {
                    let items = this.ItemsData
                    let size = this.MBS.actions.SIZE(items)
                    let progress = 0

                     
                    if(!items){
                        console.log(items,size,"no items.......");
                    } 
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Deleting ("+size+"/"+progress+") ..."
                    })

                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    let delete_items = []
                    
                    for (const item of items) {
                        progress = progress+1
                        this.MBS.actions.progressDialog({ 
                            show:true,
                            text:"Deleting ("+size+"/"+progress+") ..."
                        })
                        delete_items.push({
                            path:path+item.key,
                            key:item.key
                        })
                    }

                    console.log(delete_items,'delete_items............');  
                    let res = await this.$store.dispatch('fi_delete',{
                        action:DATA.ITEMS.ITEMS.values+"__", 
                        multiple:true,
                        path:path, 
                        data:delete_items
                    })  
                    console.log(res,'res....');
                    this.MBS.actions.progressDialog()
                } catch (error) {
                    
                }

            }, 
            ADDED(res){
                try { 
                    let dr = this.ItemsDownloadRequest 
                    let dr_on = dr?.on
                    if(!dr_on){
                        this.RELOAD_ITEMS() 
                    }
                    console.log(res,'--------------added');
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADDED',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDITED(res){
                try {
                    let dr = this.ItemsDownloadRequest 
                    let dr_on = dr?.on
                    if(!dr_on){
                        this.RELOAD_ITEMS() 
                    }
                    console.log(res,'--------------edited');
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDITED',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            DELETED(res){
                try {
                    let dr = this.ItemsDownloadRequest 
                    let dr_on = dr?.on
                    if(!dr_on){
                        this.RELOAD_ITEMS() 
                    }
                    console.log(res,'DELETED....................');
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETED',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            //---------------[MAIN FUNCTIONS]------------ 
            ON_QR_SCAN(code){
                try {
                    if (code) { 
                        //check cashup
                        // if (!this.LocalCashup) { 
                        //     this.MBS.actions.dialog({
                        //         show:true,
                        //         fixed:true,
                        //         title:"OPEN REGISTER FIRST",
                        //         text:"You must open the Register first before you can scan.",
                        //         btnYes:"OK"
                        //     })
                        //     return
                        // }
                        
                        let size = this.MBS.actions.SIZE(code)
                        if (size<3) { 

                        }else{
                            this.SELECT_SCANNED_ITEM(null,code) 
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=RELOAD-ITEMS') {
                            if (action.YES) {
                                this.RELOAD_ITEMS()
                            } else {
                                
                            }
                        }  
                    } 
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=DELETE-ALL-ITEMS') {
                            if (action.YES) {
                                this.DELETE_ALL_ITEMS()
                            } else {
                                
                            }
                        }  
                    } 
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=REFRESH-WITH-OUTLETS') {
                            if (action.YES) {
                                this.REFRESH_WITH_OUTLETS()
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            if (!gettingItems) {
                                this.PAGE_LOAD =true
                            }  
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        beforeDestroy(){
            try {
                this.MBS.events.$off('ON_QR_SCAN', this.ON_QR_SCAN);  
                this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'beforeDestroy',
                    page:PAGE_NAME, 
                })
            }
        },
        watch: {  
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            }, 
            selected_items(value){
                console.log(value,'selected_items.................');
            }, 
        },
    }
</script>
 
