<template>
    <div>   
        <v-container grid-list-xl> 
            <div> 
                <br>
                <v-layout class="ma-0">
                    <h1 class="grey--text text--darken-1">Return Sold Items</h1>
                    <v-spacer></v-spacer>
                    <!-- <v-btn @click="REFRESH_ITEMS()" color="primary"> Refresh sold items <v-icon>mdi-refresh</v-icon></v-btn> -->
                </v-layout>
                <br>
                <form id="form_order_details" @submit.prevent="CREATE_ORDER()" >
                    <h4 class="grey--text text--darken-1">Order Details</h4>
                    <v-row>
                        <v-col cols="12" md="4">
                            <div>
                                Adding details for this order helps you stay on top of all your orders and means your staff can easily 
                                identify incoming items.</div>
                        </v-col>
                        <v-col cols="12" md="8"  > 
                            <mbs-page-spinner v-if="!show_input"
                                height="500"/>  
                            <mbs-input-fields v-else
                                :dense="true"
                                v-model="input"
                                :field_items="SalesReturnFields"
                                :select_items="SelectItems"
                                :disabled_fields="DisabledFields"
                                />    
                            <v-layout row wrap>
                                <v-flex xs7 class="mx-0 pl-2 pr-1">
                                    <v-btn @click="FIND_RECEIPTS({show:true})"    
                                    class="mt-5" width="100%" color="primary"  >
                                        FIND
                                        <v-icon>{{mdiTextBoxSearch}}</v-icon>
                                    </v-btn>  
                                </v-flex>
                                <v-flex xs5 class="pl-0">
                                    <v-btn @click="SHOW_DOCUMENT({join_sale_order:CurrentSaleOrder})"     
                                        class="mt-5" width="100%" color="secondary" :disabled="!CurrentSaleOrder">
                                        VIEW 
                                        <v-icon class="ml-2" size="32">mdi-clipboard-list-outline</v-icon>
                                    </v-btn> 
                                </v-flex> 
                            </v-layout>
                        </v-col> 
                    </v-row> 
                    <br><br><br>
                    <hr>
                    <br><br><br>
                    
                    <v-row>
                        <v-col cols="12">
                            <v-card class="mb-4" tile> 
                                <v-toolbar color="primary" tile dark>
                                    <v-toolbar-title> Previous Returns</v-toolbar-title>
                                    <v-spacer></v-spacer> 
                                    <span class="pt-2 mr-1 not-f3 font-weight-bold ">MWK</span>
                                    <div class="secondary--text font-weight-bold not-f7">{{ MBS.actions.money(0) }}</div>
                                </v-toolbar>
                                <v-list > 
                                    <template  v-for="(order,index) in CurrentSalesReturns"> 
                                        <v-list-group  :key="index" >
                                            <template v-slot:activator>
                                                <v-list-item-action><v-icon>mdi-cart-arrow-up</v-icon></v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ order?.updated_user_name }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ order?.outlet_name }}</v-list-item-subtitle>
                                                    <v-list-item-subtitle class="primary--text">
                                                        {{ order?.local_created_at_  }}, <span><strong>{{ MBS.actions.SIZE(order?.items) }}</strong> Items,</span>
                                                        (<span class="not-f2 mt-1 mr-1 ">MWK</span>{{ MBS.actions.money(order?.total_refund_amount) }})
                                                        <!-- <v-layout row wrap>
                                                        </v-layout> -->
                                                    </v-list-item-subtitle> 
                                                </v-list-item-content> 
                                                <v-list-item-action>
                                                   
                                                </v-list-item-action>
                                            </template> 
                                            <v-card class="mx-5" outlined flat tile>
                                                <div v-for="(item,index) in order?.joined_items" :key="index" class="ml-2"> 
                                                    <v-divider inset v-if="index!=0" ></v-divider> 
                                                    <v-list-item dense @click="" >
                                                        <v-list-item-action > 
                                                            <v-avatar  tile 
                                                                size="35" rounded 
                                                                color="b2" class="white--text" >
                                                                <v-icon>mdi-tag</v-icon> 
                                                            </v-avatar>   
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                            <v-list-item-title > 
                                                                {{item.name}}  
                                                            </v-list-item-title> 
                                                            <v-list-item-subtitle  >
                                                                Return Quantity: {{item.return_quantity }}  
                                                            </v-list-item-subtitle> 
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-layout  class="ma-0">
                                                                
                                                                <span class="not-f2 mt-1 mr-1">MWK</span><span>{{MBS.actions.money( (item.return_quantity * item.sold_price)-item.discount) }}</span>
                                                            </v-layout>
                                                        </v-list-item-action>
                                                         
                                                    </v-list-item>    
                                                </div>   
                                            </v-card>
                                        </v-list-group> 
                                        <!-- <v-divider  :key="'d_'+index"></v-divider> -->
                                    </template>
                                </v-list> 
                            </v-card>
                        </v-col>

                        <h4 class="grey--text text--darken-1 ml-3">Sold Items</h4>
                        <v-col cols="12" lg="12" xl="3">
                            <v-layout class="ma-0" >
                                <div>
                                    Choose items to add to this order, or choose a CSV file of items to import.
                                </div> 
                            </v-layout>
                        </v-col>
                        <v-col cols="12" lg="12" xl="9">
                            
                            <v-card :height="vsh-170" outlined rounded="sm">
                                <v-layout fill-height column class="ma-0">
                                    <div v-if="false">
                                        <v-toolbar rounded="lg" dark color="primary" class="ma-">
                                            <v-autocomplete 
                                                v-model="search_item"
                                                :disabled="inputLoading"
                                                :items="ItemData" dense
                                                append-icon="search"
                                                outlined :rounded="false"
                                                label="Search or Scan to Add Item"
                                                color="blue-grey lighten-2" 
                                                item-text="name"
                                                item-value="key" 
                                                :filter="(item, queryText, itemText) => { 
                                                    let first = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 
                                                    if(!item){return first}
                                                    if(!item.group){return first}
                                                    let second = item.group.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 
                                                    return first || second 
                                                }"
                                                >
                                                <template v-slot:selection="data">
                                                    {{ data.item.name }}
                                                </template>
                                                <template v-slot:item="data">
                                                    <template v-if="(typeof data.item !=='object')" >
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                    </template>
                                                    <template v-else>
                                                    <v-list-item-avatar tile color="grey">
                                                        <img :src="data.item.image" v-if="data.item.image">
                                                        <v-icon v-else>local_offer</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.code_"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete> 
                                            <v-btn :disabled="inputLoading"  class="ml-5" color="secondary">Import <v-icon class="ml-2">mdi-microsoft-excel </v-icon></v-btn> 
                                        </v-toolbar> 
                                    </div>


                                    <!-- //TABLE VIEW -->
                                    <div v-else-if="true"> 
                                        <v-simple-table  class="  mr-2 ml-4" dense :height="vsh-300" fixed-header >
                                            <thead>  
                                                <tr class="font-weight-bold "> 
                                                    <th class="ma-0 pa-0 " style="width:'5px'"> 
                                                        <v-switch :inset="false"    style="height: ; margin: 5px;"
                                                            v-model="item_multiple_select"
                                                            label=""   hide-details
                                                            class="ml-1"
                                                        ></v-switch> 
                                                    </th>
                                                    <th class=" text-left b  ">
                                                        ITEM NAME
                                                    </th> 
                                                    <th class="text-center  b  ">
                                                        Sold  
                                                    </th>
                                                    <th class="text-center  ">
                                                        PRICE
                                                    </th>
                                                    <th class="text-center  ">
                                                        Disc
                                                    </th>
                                                    <th class="text-center  ">
                                                        Total Paid
                                                    </th>
                                                    <th class="text-center  ">
                                                        Return
                                                    </th>
                                                    <th class="text-center  ">
                                                        Refund
                                                    </th>
                                                    <th class="text-center  b  "> 
                                                    </th>  
                                                </tr> 
                                                <!-- <v-divider /> -->
                                            </thead>
                                            <tbody> 
                                                <tr 
                                                    v-for="(item,index) in selected_items" 
                                                    :key="item.key" 
                                                    :class="(item.checked?'checked':'')+(item.key=='tip'?'b1 b2--text':'pointer')" 
                                                    @click="ON_EDIT_SELECTED_ITEM(item,index)"
                                                    > 
                                                    
                                                    <!-- v-for="(item,index) in selected_items" 
                                                    :key="item.key" 
                                                    :class="(item.checked?'checked':'')+(item.key=='tip'?'b1 b2--text':'pointer')"
                                                    
                                                    @mousedown="startLongClick"
                                                    @mouseup="endLongClick"
                                                    >  -->
                                                    <td class=" text-center with-divider-lr  ma-0 pa-0 table-background1" style="width:40px;"> 
                                                        <v-simple-checkbox @click.stop="" class="ma-0 pa-0"
                                                            v-if="item_multiple_select" icon 
                                                            color="primary" 
                                                            :value="item.checked" 
                                                            @input="INPUT_UPDATE($event, item,true)" 
                                                            />  
                                                        <span v-else icon >
                                                            <v-icon v-if="item.expand">expand_more</v-icon>
                                                            <v-icon v-else>chevron_right</v-icon>
                                                        </span>  
                                                    </td>
                                                    <td class=" with-divider ">
                                                        {{ item.name }} 
                                                        <span v-if="item.selected_item_option_names">
                                                            ({{ item.selected_item_option_names }})
                                                        </span>
                                                    </td>
                                                    <td class=" text-center with-divider "> 
                                                        {{ item.quantity }}
                                                    </td>  
                                                    <td class=" text-right with-divider "> 
                                                        {{ MBS.actions.money(item.sold_price) }}
                                                    </td>  
                                                    <td class=" text-right with-divider "> 
                                                        {{ MBS.actions.money(item.discount) }}
                                                    </td> 
                                                    <td class=" text-right with-divider "> 
                                                        {{MBS.actions.money(CALCULATE_PRICE(item))}} 
                                                    </td>  
                                                    <td class=" text-center pa-0 with-divider table-background1" style="width:100px;">
                                                        <!-- autofocus  -->
                                                        <input type="number" v-if="show_value_input"
                                                            @click.stop="" 
                                                            @input="INPUT_UPDATE($event, item,)" 
                                                            ref="ref_payment_cash"
                                                            id="payment_cash"
                                                            :value="item.return_quantity"    
                                                            class="mbs-excel-input"   
                                                            @focus="is_payment_cash_focused=$event"
                                                            @blur="is_payment_cash_focused=null"
                                                        />
                                                    </td> 
                                                    <td class=" text-right with-divide">
                                                        {{MBS.actions.money(CALCULATE_REFUND_PRICE(item))}}
                                                    </td>
                                                    <td class=" text-center with-divider-lr  px-2">
                                                        <v-btn disabled icon class="ma-0 pa-0" @click.stop="CONFIRM_REMOVE_ITEM(item,index)"><v-icon>delete</v-icon></v-btn>
                                                    </td>
                                                </tr>
                                                <v-divider />  
                                                <v-divider />
                                            </tbody>
                                        </v-simple-table> 
                                    </div>  
                                    <v-spacer></v-spacer>
                                    <v-divider></v-divider> 
                                    
                                    <input ref="btn_submit_order" type="submit" v-show="false">
                                    <v-card @click="SUBMIT()"  
                                        :disabled="SizeItem.return==0 || inputLoading" 
                                        :flat="SizeItem.return<1" 
                                           
                                        class="mx-5 my-4 v-btn" :color="SizeItem.return==0?'grey darken-1':'primary'" dark >
                                        <v-layout class="ma-0 py-2 px-4" align-center v-if="!inputLoading">
                                            <div class="font-weight-bold not-f7">Return</div>
                                            <div class="pl-2 font-weight-bold not-f3">{{SizeItem.return}}/{{SizeItem.receipt}} Items</div>
                                            <v-spacer></v-spacer>
                                            <div class="font-weight-bold not-f7">TOTAL REFUND MWK {{
                                                MBS.actions.money(CALCULATE_TOTAL_REFUND_PRICE(selected_items))
                                                }}</div> 
                                        </v-layout> 
                                        <v-layout v-else class="ma-0 pa-2" justify-center>
                                            <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                        </v-layout>
                                    </v-card>
                                </v-layout> 
                            </v-card>   
                            
                        </v-col> 
                    </v-row> 
                </form>
            </div> 
        </v-container>  

        <div> 
            <!-- EDIT ITEM DIALOG -->
            <v-dialog
                v-model="dialog_edit_selected_item"
                :scrollable="true"  
                :persistent="false" :overlay="true"
                max-width="800px"
                transition="dialog-transition" >
                <v-card height="100%" rounded="sm" color="b1" v-if="current_selected_item" @click="LOG(current_selected_item)" >
                    <v-card-title class="primary pa-2" >
                        <v-list-item dark>
                            <v-list-item-action>
                                <v-icon>mdi-tag</v-icon> 
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ current_selected_item.name }}</v-list-item-title> 
                                <v-list-item-subtitle>{{ current_selected_item.category_name }}</v-list-item-subtitle>  
                            </v-list-item-content> 
                            <v-list-item-action>
                                <v-layout align-center >
                                    <div class="mr-1 not-f3 mt-2">MWK</div> 
                                    <span  class="not-f8 font-weight-bold"> 
                                        {{MBS.actions.money(CALCULATE_PRICE(current_selected_item))}} 
                                    </span>
                                </v-layout>
                            </v-list-item-action>
                        </v-list-item>
                    </v-card-title>
                    <div outlined class="mbs-borders mx-2 mt-2 mb-2 pa-2" :style="'--size:1px; --color:grey; --conner:3px'">
                        <form @submit.prevent="UPDATE_SELECTED_ITEM({
                            item:current_selected_item,
                            self_contain:true
                        })">
                            <v-card-text class="b1">
                                <v-row>
                                    <v-col cols="12" sm="6" class="py-0">  
                                        <!-- <div>{{ FindPricing(current_selected_item)?.name }}</div> -->
                                        <div >{{current_selected_item.pricing_tier?.name}}</div> ..dd
                                        <v-text-field dense color="primary"   
                                            :disabled="CompanySettings?CompanySettings.edit_selling_price?false:true:true"
                                            filled outlined v-mask="currencyMask"
                                            v-model="current_selected_item.sold_price"  
                                            outline
                                        ></v-text-field>  
                                    </v-col>

                                    <v-col cols="12" sm="6" class="py-0 " >
                                        <div>Quantity (QTY)</div>
                                            <v-text-field dense autofocus
                                                filled outlined 
                                                v-model="current_selected_item.quantity"
                                                @focus="FOCUS_INPUT({value:'current_selected_item_quantity',
                                                ref:'ref_current_selected_item_quantity'})"
                                                outline
                                            ></v-text-field> 
                                    </v-col>
                                     
                                    <v-col cols="12" sm="12" class="py-0" v-if="CompanySettings?CompanySettings.allow_selling_discount:false" >
                                        <div>Item Discount</div>
                                            <v-text-field dense  
                                                :disabled="CompanySettings?CompanySettings.allow_selling_discount?false:true:true"
                                                filled outlined 
                                                v-model="current_selected_item.discount"
                                                @focus="FOCUS_INPUT({value:'current_selected_item_discount',
                                                ref:'ref_current_selected_item_discount'})"
                                                outline
                                            ></v-text-field> 
                                    </v-col> 
                                </v-row> 
                            </v-card-text> 

                             
                            <v-layout row wrap class="ma-0  mt-4">
                                <v-flex sm12 md6> 
                                    <v-card height="100%" hover class="mr-2 "> 
                                        <v-layout justify-center align-center fill-height class="mbs-border primary lighten-5  pa-2" :style="'--size:1px; --color:grey; --conner:3px'"> 
                                            <mbs-keyboard 
                                                :type="'number'"
                                                :row="'5'"
                                                :key_function="true"
                                                />
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                                <v-flex sm12 md6>
                                    <v-card height="100%" hover>
                                        <v-layout column  class="ma-0" fill-height justify-center align-center>  
                                            <v-spacer></v-spacer>
                                            <v-btn @click="CONFIRM_REMOVE_ITEM(current_selected_item)"
                                                class="my-2 font-weight-bold" 
                                                color="primary" :height="vs.smAndDown?'60':'70'" width="96%">
                                                <v-layout justify-center align-center> 
                                                    <v-icon class="mr-2">delete</v-icon>
                                                    <div class="not-f5 mt-1">Remove Item</div> 
                                                </v-layout>
                                            </v-btn>  
                                            <v-btn type="submit"
                                                class="my-2 font-weight-bold"   
                                                color="secondary" :height="vs.smAndDown?'60':'70'" width="96%">
                                                <v-layout justify-center align-center> 
                                                    <v-icon class="mr-2">mdi-upload</v-icon>
                                                    <div class="not-f5 mt-1">Update Item</div> 
                                                </v-layout>
                                            </v-btn>   
                                        </v-layout> 
                                    </v-card>
                                </v-flex> 
                            </v-layout>   
                        </form>
                    </div> 
                </v-card> 
            </v-dialog> 
            
            <!-- ITEM OPTION DIALOG -->
            <v-dialog
                v-model="dialog_item_options"
                :scrollable="true"  
                :persistent="false" :overlay="true"
                max-width="400px"
                transition="dialog-transition" >
                <v-card height="100%" rounded="sm" color="b" v-if="current_selected_item">
                    <v-card-title class="primary pa-2" >
                        <v-list-item dark>
                            <v-list-item-action>
                                <v-icon>mdi-palette-swatch</v-icon> 
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ input_item_option?input_item_option.option_name:".." }}</v-list-item-title>  
                            </v-list-item-content> 
                            <v-list-item-action>
                                <v-btn icon @click="dialog_item_options=!dialog_item_options"><v-icon>close</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-card-title>
                    <v-card-text>  
                        <v-list v-if="input_item_option?.option_type == 'multiple_selection'" class="pb-3"> 
                            <v-list-item  v-for="(item_option,index) in input_item_option?.item_options"  
                                :key="index"
                                @click="CHECK_SELECTED_ITEM_OPTION(item_option)"
                            >
                                <v-list-item-action >
                                    <v-icon v-if="IsOptionSelected(item_option)">mdi-checkbox-marked</v-icon> 
                                    <v-icon v-else>mdi-checkbox-blank-outline</v-icon> 
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{item_option.option_name}}</v-list-item-title>  
                                </v-list-item-content>  
                            </v-list-item> 
                        </v-list>
                        <v-list v-else class="pb-3"> 
                            <v-list-item  v-for="(item_option,index) in input_item_option?.item_options"  
                                :key="index"
                                @click="ON_SELECT_ITEM_OPTIONS(selected_item_payload,item_option,[item_option])"
                            >
                                <!-- <v-list-item-action>
                                    <v-icon>mdi-check-box</v-icon> 
                                </v-list-item-action> -->
                                <v-list-item-content>
                                    <v-list-item-title>{{item_option.option_name}}</v-list-item-title>  
                                </v-list-item-content>  
                            </v-list-item> 
                        </v-list>
                    </v-card-text>
                    <v-card-actions v-if="input_item_option?.option_type == 'multiple_selection'">
                        <v-flex >
                            <v-btn @click="ON_SELECT_ITEM_OPTIONS(selected_item_payload,item_option,input_temporary_option_selected)" width="100%" class="mr-" color="primary">Continue</v-btn> 
                        </v-flex>
                        <v-btn @click="dialog_item_options=false" class="ml-1 px-5" color="secondary">Cancel</v-btn>
                    </v-card-actions>
                </v-card> 
            </v-dialog>

            <!-- //FIND RECEIPTS -->
            <mbs-receipt-finder
                v-model="input_receipts"
                :show="receipt_finder?.show"
                :title="receipt_finder?.title"
                :key="receipt_finder?.key" 
                @find_closed="FIND_CLOSED"
                @find_opened="FIND_OPENED"
                @find_selected="FIND_SELECTED"
            /> 
            <!-- //SHOW DOCUMENT -->
            <div v-if="dialog_doc_show"> 
                <mbs-dialog-doc-show
                    v-model="dialog_doc_show" 
                    :title="input_show_doc.title"
                    :data="input_show_doc.data"
                    :data_key="input_show_doc.data_key"
                    :document_type="input_show_doc.document_type"
                    :height="input_show_doc.height"
                    :top_header="input_show_doc.top_header"
                    :bottom_header="input_show_doc.bottom_header"
                />  
            </div>
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import {mapState,mapGetters } from "vuex"
    import { mdiTextBoxSearch } from '@mdi/js';

    let PAGE_NAME = "TRANSFER-STOCK"  
    export default {
        name:PAGE_NAME, 
        props:["receipt_key"],
        data() { 
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10, 
                search_item:'', 
                dialog_edit_selected_item:false,
                dialog_item_options:false,
                selected_items:null,
                inputLoading:false,
                input:{},
                search_input:null, 
                mask_money:"### ### ###", 
                DATA:DATA, 
                sale_order_key:null, 
                show_input:true, 
                show_value_input:true, 
                receipt_finder:{
                    show:false,
                    title:'',
                },
                dialog_doc_show:false,
                input_item_option:null,
                input_receipts:null,
                current_selected_item:null,
                item_multiple_select:null,
                mdiTextBoxSearch: mdiTextBoxSearch, 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.MBS.actions.toolbar({
                    show:true, 
                }) 
                this.FEEDBACKS(1000) 
                this.INITIATE_RECEIPTS()

            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            /////////  
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemCategories: state=> state.items[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],
                Brands: state=> state.items[(DATA.ITEMS.BRANDS.values).toUpperCase()],
                Manufacturers: state=> state.items[(DATA.ITEMS.MANUFACTURERS.values).toUpperCase()], 

                StockOrders: state=> state.items[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()],
                JoinedStockOrders: state=> state.join[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()],
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                QuotationsAndInvoices: state=> state.items[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                JoinedQuotationsAndInvoices: state=> state.join[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                Suppliers: state=> state.items[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()],
                SupplierCategories: state=> state.items[(DATA.ITEMS.SUPPLIER_CATEGORIES.values).toUpperCase()],
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                PaymentMethods: state=> state.items[(DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values).toUpperCase()], 
                SelectedCompany: state=> state.items["SELECTED_COMPANY"], 
            }), 
            ItemData(){ 
                let Items = this.Items
                let JoinedItems = this.JoinedItems
                //let CalculatedItems = this.CalculatedItems
                // if (CalculatedItems) {
                //     return CalculatedItems
                // }else 
                
                if (JoinedItems) {
                    return JoinedItems
                } 
                return Items
            },
            SalesOrdersData(){ 
                let SaleOrders = this.SaleOrders
                let JoinedSaleOrders = this.JoinedSaleOrders  
                let items = JoinedSaleOrders?JoinedSaleOrders:SaleOrders
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                return list
            },
            CurrentSaleOrder(){
                const input_receipts = this.input_receipts
                const sale_order = input_receipts?.sale_order
                const join_sale_order = input_receipts?.join_sale_order

                console.log(input_receipts,'input_receipts.................');
                
                return join_sale_order?join_sale_order:sale_order

            },
            CurrentSalesReturns(){
                const input_receipts = this.input_receipts
                const sales_returns = input_receipts?.sales_returns
                const join_sale_returns = input_receipts?.join_sale_returns 
                return join_sale_returns?join_sale_returns:sales_returns

            },
           
            RequestForQuotations(){
                let items = this.JoinedQuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.REQUEST_FOR_QUOTATIONS.value)  
                });
                return filtered_items
            }, 
            Quotations(){
                let items = this.JoinedQuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.QUOTATIONS.value)  
                });
                return filtered_items
            }, 
            Invoices(){
                let items = this.JoinedQuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.INVOICES.value)  
                });
                return filtered_items
            },  
            ReceivedOrders(){
                let items = this.JoinedStockOrders
                if(!items){return null}
                let filtered_items = items.filter(item => {
                    return this.MBS.actions.TEXT_UP(item.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.STOCK_RECEIVES.value)  
                });
                return filtered_items
            },  
            SupplierCategories(){ 
                return this.$store.getters.getSupplierCategories
            },
            SaleOrders(){ 
                return this.$store.getters.getSaleOrders
            },
            SizeItem(){ 
                let items = this.selected_items
                if(!items){return 0}
                let items_size=this.MBS.actions.SIZE(items)
                let return_size=0
                let receipt_size=0
                items.forEach(element => {
                    let quantity = this.toNumber(element.quantity)
                    let return_quantity = this.toNumber(element.return_quantity)
                    receipt_size = receipt_size+quantity
                    return_size = return_size+return_quantity
                });
                return {
                    items:items_size,
                    receipt:receipt_size,
                    return:return_size,
                }
            },  
            CALCULATE_TOTAL_QT_PRICE(){
                return items=>{
                    let total_price = 0 
                    try { 
                        if (!items) {return 0}
                        items.forEach(item => { 
                            let sold_price = item.sold_price?this.toNumber(item.sold_price):0
                            let discount = item.discount? this.toNumber(item.discount):0
                            let quantity = item.quantity?this.toNumber(item.quantity):1
                            total_price = total_price + (quantity*(sold_price-discount))
                        });  
                        return total_price
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'CALCULATE_TOTAL_QT_PRICE',
                            page:PAGE_NAME, 
                        }) 
                        return 0
                    } 
                } 
            }, 
            CALCULATE_TOTAL_REFUND_PRICE(){
                return items=>{
                    let total_price = 0 
                    try { 
                        if (!items) {return 0}
                        items.forEach(item => { 
                            let sold_price = item.sold_price?this.toNumber(item.sold_price):0
                            let discount = item.discount? this.toNumber(item.discount):0
                            let return_quantity = this.toNumber(item.return_quantity)
                            total_price = total_price + (return_quantity*(sold_price-discount))
                        });  
                        return total_price
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'CALCULATE_TOTAL_REFUND_PRICE',
                            page:PAGE_NAME, 
                        }) 
                        return 0
                    } 
                } 
            }, 
            SelectItems(){
                let Suppliers = this.Suppliers
                let Outlets = this.Outlets
                let PaymentMethods = this.PaymentMethods
                let RequestForQuotations = this.RequestForQuotations
                let Quotations = this.Quotations
                let Invoices = this.Invoices
                let ReceivedOrders = this.ReceivedOrders
                let SupplierCategories = this.SupplierCategories
                let SalesOrdersData = this.SalesOrdersData 
                let JoinedSaleOrders = this.JoinedSaleOrders 
                return {
                    Suppliers:Suppliers,
                    Outlets:Outlets,
                    PaymentMethods:PaymentMethods,
                    RequestForQuotations:RequestForQuotations,
                    Quotations:Quotations,
                    Invoices:Invoices,
                    ReceivedOrders:ReceivedOrders,
                    SupplierCategories:SupplierCategories,
                    SaleOrders:SalesOrdersData,
                }
            },
            DisabledFields(){
                // let sale_order_key = this.sale_order_key 
                // if (sale_order_key) {
                //     return ['customer_name','teller_name','till_name','outlet_name','transaction_date']
                // }
                // return ['customer_name','teller_name','till_name','outlet_name','transaction_date']
            },
            SalesReturnFields(){
                const order = this.CurrentSaleOrder 
                const key = order?.key
                const order_id_type = order?.order_id_type? order?.order_id_type:" "
                const order_id = order?.order_id
                const order_id_generator = order?.order_id_generator 
                console.log(order,'CurrentSaleOrder.............');
                
                let fields = [
                    {type:"",value:"code",label:"code",
                            auto_code:{
                                values:DATA.CODE.system, 
                                onstart:true,
                                disabledIfCode:true
                            },
                        required:true,sm:12,md:6}, 
                    {disabled:true,type:"text",value:"receipt_number",label:this.MBS.actions.TEXT_UP1(order_id_type)+" No.",required:false,sm:12,md:6},  
                    {disabled:true,type:"text",value:"dayshift",label:"Day Shift & Date",required:false,sm:12,md:6},  
                    {type:"date",value:"return_date",onstart:'today',label:"Return Date",autofocus:true,required:true,sm:12,md:6},  
                    {disabled:true,type:"text",value:"customer_name",label:"Customer Name",required:false,sm:12,md:6},  
                    {disabled:true,type:"text",value:"teller_name",label:"Teller Name",required:false,sm:12,md:6}, 
                    {disabled:true,type:"text",value:"till_name",label:"Till Name",required:false,sm:12,md:6},  
                    {disabled:true,type:"text",value:"outlet_name",label:"Outlet Name",required:false,sm:12,md:6},  
                    {disabled:true,type:"text",value:"payment_method_name",label:"Payment",required:false,sm:12,md:12},  
                    {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12}, 
                ]
                return fields
            } 
        },
        methods: { 
            INITIATE_RECEIPTS(){
                try {  
                    const receipt_key = this.receipt_key
                    const input_receipts = this.input_receipts
                    const sale_order = input_receipts?.join_sale_order?input_receipts?.join_sale_order:input_receipts?.sale_order
                    if (!receipt_key) {
                        this.FIND_RECEIPTS({show:true,fixed:true}) 
                    }else if(receipt_key&&!sale_order){
                        this.FIND_RECEIPTS({show:false,fixed:true,receipt_key:receipt_key}) 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'INITIATE_RECEIPTS',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            ADD_SALE_ORDER(order){
                try {   
                    this.show_input = false
                    if (order) {
                        this.selected_items = [] 
                        setTimeout(() => {
                            if (order.joined_items  ) {
                                order.joined_items  .forEach(element => {
                                    this.selected_items.push({
                                        ...element,
                                        return_quantity:''
                                    })
                                }); 
                            }
                            const key = order?.key
                            const local_created_at_ = order?.local_created_at_
                            const day_shift = order?.day_shift
                            const operating_date = day_shift?.operating_date
                            const order_id_type = order?.order_id_type
                            const order_id = order?.order_id
                            const order_id_generator = order?.order_id_generator
                            let receipt_number = ""+key
                            if (order_id_generator !="timestamp") {
                                receipt_number = key+" ("+order_id+")"
                            }
                            this.input = {
                                ...this.input,  
                                receipt_number:receipt_number,
                                customer_name : order.customer_name,
                                teller_name : order.teller_name,
                                till_name : order.till_name,
                                outlet_name : order.outlet_name,
                                payment_method_name:order.payment_method_name,
                                transaction_date : order.local_created_at_,
                                dayshift : operating_date+" ("+local_created_at_+")",
                                description : order.description,
                                sale_order:order
                            }   
                        }, 1);
                        setTimeout(() => {
                            console.log(this.input,222);
                            this.show_input = true 
                        }, 1000);
                    }else{
                        this.show_input = false
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_SALE_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            FIND_CLOSED(data){
                try {  
                    console.log(data,'closed............');
                    const receipt = data?.receipt
                    const key = receipt?.key
                    const day_shift_key = receipt?.day_shift_key
                    if (!receipt) {
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            icon:mdiTextBoxSearch,
                            title:"ERROR",
                            text:"No Receipt selected. You must select receipt or invoice to return sales.",
                            btnNo:"Cancel",
                            btnYes:"Find Receipt / Invoice",
                            action:{
                                code:PAGE_NAME+"=FIND-RECEIPT"
                            }
                        })
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FIND_CLOSED',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            FIND_SELECTED(data){
                try {  
                    console.log(data,'closed............');
                    const receipt = data?.receipt
                    const key = receipt?.key
                    const day_shift_key = receipt?.day_shift_key 

                    if (day_shift_key & key) {
                        this.MBS.actions.go(-1)
                        setTimeout(() => {
                            this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/sales-return/"+key,true)) 
                        }, 10);
                    }
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FIND_CLOSED',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            FIND_OPENED(data){
                try {  
                    console.log(data,'opened............');
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FIND_OPENED',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            ON_EDIT_SELECTED_ITEM(item){
                try {  
                    if (item?.key=='tip') {
                        return
                    }
                    if (!this.longClickDetected) {
                        this.current_selected_item = null 
                        setTimeout(() => {
                            this.dialog_edit_selected_item = true
                            this.current_selected_item = {...item}   
                        }, 30);
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EDIT_SELECTED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },

            INPUT_UPDATE(event,item,check_item){
                try { 
                    let target = event?event.target:null
                    let value = target?target.value:null
                    if(check_item){
                        item = {
                            ...item,
                            checked:event?true:false
                        }
                        value = item.return_quantity
                    }
                    this.UPDATE_SELECTED_ITEM({
                        item:item,
                        return_quantity:value,
                        discount:item.discount,
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'INPUT_UPDATE',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            async UPDATE_SELECTED_ITEM(payload){
                try {
                    const selected_items = this.selected_items
                    this.dialog_edit_selected_item = false
                    let item = payload?payload.item:null
                    let return_quantity = this.toNumber(payload.return_quantity)
                    const quantity = this.toNumber(item.quantity)
                    let discount = payload?payload.discount:null
                    let self_contain = payload?payload.self_contain:null
                    if (self_contain) {
                        return_quantity = this.toNumber(item.return_quantity)
                        discount = item.discount
                    }
                    let index = selected_items.findIndex(it=>it.key == item.key)

                    console.log(quantity,return_quantity,item,'+++++++++++++++++++++');
                    
                    if (quantity < return_quantity) {
                        this.show_value_input = false
                        setTimeout(() => {
                            this.selected_items[index]['return_quantity'] = this.selected_items[index]['return_quantity'] 
                            this.show_value_input = true
                        }, 1000); 
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:'Error',
                            text:"Returning Item can not be more than sold item(s).",
                            btnYes:"Okay"
                        })
                    } else {
                        this.selected_items[index]['return_quantity'] = return_quantity 
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_SELECTED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
            },





            AT_SEARCH_INPUT(input){
                try {  
                    this.search_input = input
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'AT_SEARCH_INPUT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            TABLE_ACTION(action,item){
                try {
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT(){
                try {
                    let btn_submit_order = this.$refs.btn_submit_order
                    if (btn_submit_order) { 
                        btn_submit_order.click()
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ADD_ITEM(item_key){
                try {
                    let items = this.ItemData
                    if (items) {
                        let item_selected = items.find(item=>{
                            return item.key == item_key
                        })
 
                        if (!item_selected) {
                            console.log(item_key,'not found');
                            return 
                        }
                        if (!this.selected_items) {
                            this.selected_items = []
                        }

                        let item = this.selected_items.find(item=>{
                            return item.key == item_key
                        }) 
                        if (item) {
                            item.quantity = (item.quantity*1)+1
                        } else {
                            this.selected_items.push({
                                ...item_selected, 
                                quantity:1,
                            })   
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            REMOVE_ITEM(selected_item){
                try {
                    let items = this.selected_items
                    if (items) {
                        let filtered_item = items.filter(item=>{
                            return item.key != selected_item.key
                        })
                        this.selected_items = filtered_item

                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let sold_price = item.sold_price?this.toNumber(item.sold_price):0
                    let discount = item.discount? this.toNumber(item.discount):0
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CALCULATE_REFUND_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let sold_price = item.sold_price?this.toNumber(item.sold_price):0
                    let discount = item.discount? this.toNumber(item.discount):0
                    let return_quantity = this.toNumber(item.return_quantity)
                    return return_quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_REFUND_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CREATE_ORDER(){
                try {
                    let input = this.input  
                    let description = input?input.description:null
                    let return_date = input?input.return_date:null
                    let code = input?input.code:null  
                    let sale_order = this.CurrentSaleOrder
                    let sale_order_key = sale_order?sale_order.key:null 
                    let day_shift_key = sale_order?sale_order.day_shift_key:null 
                    let cashup_key = sale_order?sale_order.cashup_key:null 
                    let company_key = sale_order?sale_order.company_key:null 
                    let outlet_code = sale_order?sale_order.outlet_code:null 
                    let selected_items = this.selected_items
                    let total_refund_amount = this.CALCULATE_TOTAL_REFUND_PRICE(selected_items)
                    let items_size = this.MBS.actions.SIZE(selected_items)

                    let moment = this.MBS.date.moment
                    let update_key = moment?moment().valueOf():null
                    let key =  update_key

                    console.log(sale_order,"sale order........");
                    console.log(selected_items,"selected_items........");

                    if (items_size<1) {
                        console.log("error..");
                    }
                    
                    let stock_order_items = []
                    selected_items.forEach(element => {
                        let quantity = this.toNumber(element.quantity)
                        let discount = this.toNumber(element.discount)
                        let return_quantity = this.toNumber(element.return_quantity)
                        let sold_price = this.toNumber(element.sold_price)
                        if (return_quantity>0) {
                            stock_order_items.push({
                                item_key:element.item_key, 
                                sold_price:sold_price, 
                                price:sold_price, 
                                discount:discount, 
                                return_quantity:return_quantity, 
                                sold_quantity:quantity
                            }) 
                        }
                    });
                    let order_details = {
                        code:code,  
                        sale_order_key:sale_order_key,  
                        day_shift_key:day_shift_key,  
                        cashup_key:cashup_key,  
                        company_key:company_key,  
                        outlet_code:outlet_code,  
                        order_type:DATA.ITEMS.RETURNED_SALES.value,
                        items:stock_order_items,
                        local_created_at:key,
                        total_refund_amount:total_refund_amount,
                    }  
                    if (description) {
                        order_details.description = description
                    }if (return_date) {
                        order_details.return_date = return_date
                    }  
 
                    console.log(order_details,'order_details...');
                    let mbs_text = this.MBS.text.item_action(DATA.ITEMS.ITEMS.names,"Return Sold")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=CREATE-ORDER", 
                            data:order_details
                        }
                    })
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ORDER',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT_ORDER(order){
                try {  
                    let order_key = order.sale_order_key 
                    let day_shift_key = order.day_shift_key
                    let code = order.code
                    let company_key = order.company_key
                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.sales_return+code,true,company_key)  
                    let details = {
                        action:DATA.ITEMS.RETURNED_SALES.values,
                        data:order,
                        path:path
                    }
                    console.log(details,"dx ........");
                    if (!order || !path) {
                        console.log(order,'order error');
                        return
                    } 
                     
                    this.inputLoading = true
                    this.MBS.actions.progressDialog({
                        show:true,
                        title:"Uploading..",
                    }) 
                    this.$store.dispatch("fi_update",details).then(res=>{ 
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.ITEMS.names,"Returned")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                        this.MBS.actions.go(-1)  
                    }).catch(error=>{
                        this.MBS.actions.error({
                            error:error,
                            from:'SUBMIT_ORDER',
                            page:PAGE_NAME, 
                        })  
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_failed(DATA.ITEMS.QUOTATIONS.value,"Transferred")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            
            REFRESH_ITEMS(){
                let mbs_text = this.MBS.text.item_action("Sales Transactions","Refresh")
                this.MBS.actions.dialog({
                    show:true,
                    title:mbs_text.title,
                    text:mbs_text.text,
                    btnYes:mbs_text.btnYes,
                    btnNo:mbs_text.btnNo,
                    action:{
                        code:PAGE_NAME+"=RELOAD-ITEMS", 
                    } 
                })
            },
            RELOAD_ITEMS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{
                        console.log(res,'res.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },
             
            FIND_RECEIPTS(payload){
                this.MBS.events.$emit('ON_FIND_RECEIPTS',{
                    show:payload?.show,
                    fixed:payload?.fixed,
                    receipt_key:payload?.receipt_key,
                })
            },
            SHOW_DOCUMENT(payload){  
                try {   
                    const  this_document = (payload?.join_sale_order)?(payload?.join_sale_order):(payload?.sale_order)
                    if(!this_document){return } 
                    this.input_show_doc = {}
                    // this.input_show_doc.document_type = 'receipt'
                    this.input_show_doc.data_key = this_document?.key
                    this.input_show_doc.data = {...this_document,merge_doc_type:"receipt"}
                    this.dialog_doc_show = true 
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SHOW_DOCUMENT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=CREATE-ORDER') {
                            if (action.YES) {
                                setTimeout(() => {
                                    console.log(action.data);
                                    this.SUBMIT_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=RELOAD-ITEMS') {
                            if (action.YES) {
                                this.RELOAD_ITEMS()
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=FIND-RECEIPT') {
                            if (action.YES) {
                                this.FIND_RECEIPTS({show:true,receipt_key:this.receipt_key,fixed:true})
                            } else { 
                               this.MBS.actions.go('/') 
                               console.log('go.. home...');
                               
                            }
                        }   
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })  
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: {
            search_item(value){ 
                if (value) {
                    this.ADD_ITEM(value) 
                }
            },
            CurrentSaleOrder(order){  
                this.ADD_SALE_ORDER(order) 
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
