<template>
    <div>    
        <v-layout row wrap class="ma- read"  >   
            <!-- FIELD -->  
            <template v-for="(item, index) in FIELD_ITEMS"> 
                <div v-if="!item.value" :key="index">
                    <v-subheader>
                        {{item.subtitle}} 
                    </v-subheader>
                </div>
                <v-col v-else cols="12" :sm="typeof item.sm ==='function'?item.sm(this):item.sm" :md="typeof item.md ==='function'?item.md(this_):item.md"  
                    :class="'py-'+(py?py:'2')+' px-'+(px?px:'')"  
                    :key="index"  v-show="VisibleField(item)" >
                    <span v-if="VisibleField(item)">
                        <div v-if="item.label_top" :key="1+'-'+index" class="not-f3">{{item.label_top}}</div>
                        
                        <!-- //loading -->
                        <div v-if="false">
                            <!-- <v-text-field  
                                :outlined="outlined"
                                :filled="filled!=undefined?filled:true" 
                                :dense="dense!=undefined?dense:item.label_top?true:item.dense?true:false"
                                :append-icon="item.append_icon"
                                :prepend-icon="item.prepend_icon"
                                :prepend-inner-icon="item.prepend_inner_icon" 
                                :required ="item.required?true:false" 
                                :type="item.type"  
                                :autofocus="item.autofocus?true:false" 
                                :label="'loading...'"
                                :error="ITEM_ERROR(item,input[item.value])?true:false"
                                :disabled="true"
                            />  -->
                        </div>
                        <div v-else>
                            <div v-if="item.value=='code'" >
                                <v-text-field autocomplete="one-time-code"  
                                    :outlined="outlined"
                                    :filled="filled!=undefined?filled:true" 
                                    :dense="dense!=undefined?dense:item.label_top?true:item.dense?true:false"
                                    :append-icon="item.append_icon" 
                                    :required ="item.required?true:false"  
                                    :change="UPDATE_MODEL()" 
                                    :loading="inputCodeLoading" 
                                    :type="item.type"
                                    v-model="inputCode" 
                                    :label="item.label"
                                    :error="ITEM_ERROR(item,inputCode)?true:false"
                                    :disabled="
                                        (update_item && !item.auto_code.updatable)
                                        || (isAutoCode(inputCode) && item.auto_code.disabledIfCode)
                                        || DisableField(item)
                                    " >
                                    <template v-slot:append v-if="item.auto_code && !isAutoCode(inputCode)">
                                        <v-btn  v-if="!update_item"
                                            :loading="inputCodeLoading"
                                            :color="ITEM_ERROR(item,inputCode)?'error':item.auto_code.color?item.auto_code.color:'primary'" 
                                            @click="setAutoCode(item.auto_code.values,item.auto_code.notify)"
                                            class=" " width=""  
                                            depressed  >
                                            <v-icon>spellcheck</v-icon>
                                            <div class="ml-1 font-weight-bold">
                                                Auto
                                            </div>
                                        </v-btn> 
                                    </template>
                                </v-text-field>   
                                <!-- //show error code -->
                                <!-- <span v-if="ITEM_ERROR(item,inputCode)" class="error--text font-italic">{{ITEM_ERROR(item,inputCode)}}</span> -->
                            </div>
                            <span  v-else-if="item.type=='key_name'">  
                                <v-text-field
                                    :outlined="outlined"
                                    :filled="filled!=undefined?filled:true" 
                                    :dense="dense!=undefined?dense:item.label_top?true:item.dense?true:false"
                                    :append-icon="item.append_icon"
                                    :prepend-icon="item.prepend_icon"
                                    :prepend-inner-icon="item.prepend_inner_icon" 
                                    :required ="item.required?true:false"  
                                    :type="item.type" autocomplete="one-time-code"  
                                    :autofocus="item.autofocus?true:false" 
                                    v-model.lazy="input_" 
                                    :label="item.label"
                                    :error="ITEM_ERROR(item,input[item.value])?true:false"
                                    :disabled="DisableField(item)"
                                />
                            </span>
                            <v-text-field  v-else-if="item.type=='currency'||item.type=='money'"  
                                :outlined="outlined"
                                :filled="filled!=undefined?filled:true" 
                                :dense="dense!=undefined?dense:item.label_top?true:item.dense?true:false"
                                :append-icon="item.append_icon"
                                :prepend-icon="item.prepend_icon"
                                :prepend-inner-icon="item.prepend_inner_icon" 
                                :required ="item.required?true:false"  
                                :autofocus="item.autofocus?true:false"
                                v-mask="item.mask=='numberMask'?numberMask:currencyMask"
                                @focus="ON_FOCUSING(item)"
                                :change="UPDATE_MODEL(input,item)" 
                                :type="item.type" autocomplete="one-time-code"  
                                v-model.lazy="input[item.value]" 
                                :label="item.label" 
                                :disabled="DisableField(item)" />  
                            <v-textarea  v-else-if="item.type=='textarea'"  
                                :outlined="outlined"
                                :filled="filled!=undefined?filled:true" 
                                :dense="dense!=undefined?dense:item.label_top?true:item.dense?true:false"
                                :append-icon="item.append_icon"
                                :prepend-icon="item.prepend_icon"
                                :prepend-inner-icon="item.prepend_inner_icon" 
                                :autofocus="item.autofocus?true:false"
                                :required ="item.required?true:false"  
                                @focus="ON_FOCUSING(item)"
                                :change="UPDATE_MODEL(input,item)" 
                                :type="item.type" autocomplete="one-time-code"  
                                v-model="input[item.value]" 
                                :label="item.label" 
                                :disabled="DisableField(item)" />  
                            <v-checkbox  v-else-if="item.type=='checkbox'||item.type=='boolean'"  
                                :outlined="outlined"
                                :filled="filled!=undefined?filled:true" 
                                :dense="dense!=undefined?dense:item.label_top?true:item.dense?true:false"
                                :append-icon="item.append_icon"
                                :prepend-icon="item.prepend_icon"
                                :prepend-inner-icon="item.prepend_inner_icon" 
                                :required ="item.required?true:false"  
                                @focus="ON_FOCUSING(item)"
                                :change="UPDATE_MODEL(input,item)" 
                                :type="item.type" autocomplete="one-time-code"  
                                :autofocus="item.autofocus?true:false"
                                v-model="input[item.value]" 
                                :label="item.label" 
                                :disabled="DisableField(item)" />  
                            <!-- <v-select v-else-if="item.type=='select'" 
                                :outlined="outlined"
                                :filled="filled!=undefined?filled:true" 
                                :dense="dense!=undefined?dense:item.label_top?true:item.dense?true:false"
                                :append-icon="item.append_icon"
                                :prepend-icon="item.prepend_icon"
                                :prepend-inner-icon="item.prepend_inner_icon" 
                                :required ="item.required?true:false"
                                v-model="input[item.value]" 
                                @focus="ON_FOCUSING(item)"
                                :change="UPDATE_MODEL(input,item)" 
                                :items="SELECT_ITEMS[item.select?item.select.name:'']"
                                :item-value="item.select?item.select.value:''"
                                :item-text="item.select?item.select.text:''"
                                :label="item.label"
                                :disabled="DisableField(item)" /> -->
                            <v-combobox v-else-if="item.type=='option_text'" 
                                :outlined="outlined"
                                :filled="filled!=undefined?filled:true" 
                                :dense="dense!=undefined?dense:item.label_top?true:item.dense?true:false"
                                :append-icon="item.append_icon"
                                :prepend-icon="item.prepend_icon"
                                :prepend-inner-icon="item.prepend_inner_icon" 
                                :required ="item.required?true:false"
                                :autofocus="item.autofocus?true:false"
                                v-model="input[item.value]" 
                                @focus="ON_FOCUSING(item)"
                                @change="AT_INPUT_CHANGE"
                                :change="UPDATE_MODEL(input,item)" 
                                :items="SELECT_ITEMS[item.select?item.select.name:'']"
                                :item-value="item.select?item.select.value:''"
                                :item-text="item.select?item.select.text:''"
                                :label="item.label"
                                :error="ITEM_ERROR(item,input[item.value])?true:false"
                                :disabled="DisableField(item)" />
                            <v-select v-else-if="item.type=='select_gender'" 
                                :outlined="outlined"
                                :filled="filled!=undefined?filled:true" 
                                :dense="dense!=undefined?dense:item.label_top?true:item.dense?true:false"
                                :append-icon="item.append_icon"
                                :prepend-icon="item.prepend_icon"
                                :prepend-inner-icon="item.prepend_inner_icon" 
                                :autofocus="item.autofocus?true:false"
                                v-model="input[item.value]" 
                                @change="AT_INPUT_CHANGE"
                                @focus="ON_FOCUSING(item)"
                                :change="UPDATE_MODEL(input,item)" 
                                :required ="item.required?true:false"
                                :items="['Male','Female']" 
                                :label="item.label"
                                :disabled="DisableField(item)" />
                            <v-select v-else-if="item.type=='select_marital_status'" 
                                :outlined="outlined"
                                :filled="filled!=undefined?filled:true" 
                                :dense="dense!=undefined?dense:item.label_top?true:item.dense?true:false"
                                :append-icon="item.append_icon"
                                :prepend-icon="item.prepend_icon"
                                :prepend-inner-icon="item.prepend_inner_icon" 
                                :required ="item.required?true:false"
                                :autofocus="item.autofocus?true:false"
                                v-model="input[item.value]" 
                                @change="AT_INPUT_CHANGE"
                                @focus="ON_FOCUSING(item)"
                                :change="UPDATE_MODEL(input,item)" 
                                :items="['Single','Married']" 
                                :label="item.label"
                                :disabled="DisableField(item)" />
                            <v-select v-else-if="item.type=='yes_or_no'" 
                                :outlined="outlined"
                                :filled="filled!=undefined?filled:true" 
                                :dense="dense!=undefined?dense:item.label_top?true:item.dense?true:false"
                                :append-icon="item.append_icon"
                                :prepend-icon="item.prepend_icon"
                                :prepend-inner-icon="item.prepend_inner_icon" 
                                :required ="item.required?true:false"
                                :autofocus="item.autofocus?true:false"
                                v-model="input[item.value]" 
                                @change="AT_INPUT_CHANGE"
                                @focus="ON_FOCUSING(item)"
                                :change="UPDATE_MODEL(input,item)" 
                                :items="['Yes','No']" 
                                :label="item.label"
                                :disabled="DisableField(item)" />
                            <v-autocomplete v-else-if="item.type=='autocomplete'" 
                                :outlined="outlined" height="100%"
                                :filled="filled!=undefined?filled:true" 
                                :dense="dense!=undefined?dense:item.label_top?true:item.dense?true:false"
                                :multiple="typeof item.multiple ==='function'?item.multiple(input):item.multiple" 
                                :chips="item.chips"  
                                :clearable="item.clearable"  
                                :deletable-chips="item.deletable_chips" 
                                :append-icon="item.append_icon"
                                :prepend-icon="item.prepend_icon"
                                :prepend-inner-icon="item.prepend_inner_icon"
                                :required ="item.required?true:false"
                                :autofocus="item.autofocus?true:false"
                                v-model="input[item.value]" 
                                @update:search-input="AT_SEARCH_INPUT"
                                @change="AT_INPUT_CHANGE"
                                :items="SELECT_ITEMS?SELECT_ITEMS[item.select?item.select.name:'']:null"
                                :item-value="item.select?item.select.value:''"
                                :item-text="item.select?item.select.text:''"
                                :label="item.label"
                                :filter="item.search?item.search.filter:(item, queryText, itemText) => { 
                                    let index = (a,b)=>{
                                        if(!a||!b){return false}
                                        a = ''+a; b = ''+b
                                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                    } 
                                    let first = index(itemText,queryText) 
                                    return first  
                                }"
                                :disabled="DisableField(item)" >
                                <template v-slot:prepend-item  v-if="MBS.actions.SIZE(FIELD_APPEND_ACTIONS(item.action))>0 && !search_input">
                                    <v-row class="ma-0 pl-3 pr-2">
                                        <v-col cols="12" class="px-0"
                                            :sm="MBS.actions.SIZE(FIELD_APPEND_ACTIONS(item.action))==1?'12':'6'" 
                                            v-for="(action,index) in FIELD_APPEND_ACTIONS(item.action)" :key="index">
                                            <v-btn 
                                                :to="action.link"
                                                color="primary" 
                                                @click="FIELDS_ACTION(action)"
                                                class=" my-2" width="97%"  
                                                depressed  >
                                                <v-icon v-if="action.icon">{{action.icon}}</v-icon>
                                                <div class="ml-2 font-weight-bol">
                                                    {{action.name}}
                                                </div>
                                            </v-btn> 
                                        </v-col> 
                                    </v-row>
                                </template>
                                <template v-slot:no-data  >
                                    <v-row class="ma-0 pl-3 pr-2" v-if="MBS.actions.SIZE(FIELD_NO_DATA_SEARCH_ACTIONS(item.action))>0 && search_input"> 
                                        <v-col cols="12" class="px-0" 
                                            :sm="MBS.actions.SIZE(FIELD_NO_DATA_SEARCH_ACTIONS(item.action))==1?'12':'6'" 
                                            v-for="(action,index) in FIELD_NO_DATA_SEARCH_ACTIONS(item.action)" :key="index">
                                            <v-btn 
                                                :to="action.link"
                                                color="primary" 
                                                @click="FIELDS_ACTION(action,search_input)"
                                                class=" my-2" width="97%"  
                                                depressed  >
                                                <v-icon v-if="action.icon">{{action.icon}}</v-icon> 
                                                <div class="ml-2 font-weight-bol">
                                                    {{action.action_name}} "{{search_input}}" {{action.name}}
                                                </div>
                                            </v-btn> 
                                        </v-col>  
                                    </v-row> 
                                    <v-row class="ma-0 pl-3 pr-2" v-if="MBS.actions.SIZE(FIELD_NO_DATA_ACTIONS(item.action))>0"> 
                                        <v-col cols="12" class="px-0"
                                            :sm="MBS.actions.SIZE(FIELD_NO_DATA_ACTIONS(item.action))==1?'12':'6'" 
                                            v-for="(action,index) in FIELD_NO_DATA_ACTIONS(item.action)" :key="index">
                                            <v-btn 
                                                :to="action.link"
                                                color="primary" 
                                                @click="FIELDS_ACTION(action,search_input)"
                                                class=" my-2" width="97%"  
                                                depressed  >
                                                <v-icon v-if="action.icon">{{action.icon}}</v-icon> 
                                                <div class="ml-2 font-weight-bol">
                                                    {{action.name}}
                                                </div>
                                            </v-btn> 
                                        </v-col>  
                                    </v-row>
                                </template> 
                                <template v-slot:item="data" v-if="item.search">
                                    <template v-if="(typeof data.item !=='object')" >
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
        
                                    <!-- //ITEM ICON -->
                                    <template v-if="item.search.item_icon">
                                        <v-list-item-avatar 
                                            :tile="item.search.item_icon.tile"
                                            :color="item.search.item_icon.background_color?item.search.item_icon.background_color:'grey'" > 
                                            <v-icon v-if="item.search.item_icon.name" :color="item.search.item_icon.color">
                                                {{item.search.item_icon.name}}
                                            </v-icon>
                                        </v-list-item-avatar> 
                                    </template>
                                    <v-list-item-content>
                                        <!-- //ITEM TITLES -->
                                        <template v-for="(title,index) in item.search.item_title">
                                            <v-list-item-title :key="'title'+index">
                                                {{data.item[title]}}
                                            </v-list-item-title> 
                                        </template>
                                        <!-- //ITEM SUBTITLES -->
                                        <template v-for="(subtitle,index) in item.search.item_subtitle">
                                            <v-list-item-subtitle :key="'sub'+index">
                                                {{data.item[subtitle]}}
                                            </v-list-item-subtitle> 
                                        </template> 
                                    </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                            <v-select v-else-if="item.type=='select'" 
                                :outlined="outlined"
                                :filled="filled!=undefined?filled:true" 
                                :dense="dense!=undefined?dense:item.label_top?true:item.dense?true:false"
                                :append-icon="item.append_icon"
                                :prepend-icon="item.prepend_icon"
                                :prepend-inner-icon="item.prepend_inner_icon"
                                :required ="item.required?true:false"
                                :autofocus="item.autofocus?true:false"
                                v-model="input[item.value]" 
                                @change="AT_INPUT_CHANGE"
                                @update:search-input="AT_SEARCH_INPUT"
                                :items="SELECT_ITEMS?SELECT_ITEMS[item.select?item.select.name:'']:null"
                                :item-value="item.select?item.select.value:''"
                                :item-text="item.select?item.select.text:''"
                                :label="item.label"
                                :filter="item.search?item.search.filter:(item, queryText, itemText) => { 
                                    let index = (a,b)=>{
                                        if(!a||!b){return false}
                                        a = ''+a; b = ''+b
                                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                    } 
                                    let first = index(itemText,queryText) 
                                    return first  
                                }"
                                :disabled="DisableField(item)" >
                                <template v-slot:prepend-item  v-if="MBS.actions.SIZE(FIELD_APPEND_ACTIONS(item.action))>0 && !search_input">
                                    <v-row class="ma-0 pl-3 pr-2">
                                        <v-col cols="12" class="px-0"
                                            :sm="MBS.actions.SIZE(FIELD_APPEND_ACTIONS(item.action))==1?'12':'6'" 
                                            v-for="(action,index) in FIELD_APPEND_ACTIONS(item.action)" :key="index">
                                            <v-btn 
                                                :to="action.link"
                                                color="primary" 
                                                @click="FIELDS_ACTION(action)"
                                                class=" my-2" width="97%"  
                                                depressed  >
                                                <v-icon v-if="action.icon">{{action.icon}}</v-icon>
                                                <div class="ml-2 font-weight-bol">
                                                    {{action.name}}
                                                </div>
                                            </v-btn> 
                                        </v-col> 
                                    </v-row>
                                </template>
                                <template v-slot:no-data  >
                                    <v-row class="ma-0 pl-3 pr-2" v-if="MBS.actions.SIZE(FIELD_NO_DATA_SEARCH_ACTIONS(item.action))>0 && search_input"> 
                                        <v-col cols="12" class="px-0" 
                                            :sm="MBS.actions.SIZE(FIELD_NO_DATA_SEARCH_ACTIONS(item.action))==1?'12':'6'" 
                                            v-for="(action,index) in FIELD_NO_DATA_SEARCH_ACTIONS(item.action)" :key="index">
                                            <v-btn 
                                                :to="action.link"
                                                color="primary" 
                                                @click="FIELDS_ACTION(action,search_input)"
                                                class=" my-2" width="97%"  
                                                depressed  >
                                                <v-icon v-if="action.icon">{{action.icon}}</v-icon> 
                                                <div class="ml-2 font-weight-bol">
                                                    {{action.action_name}} "{{search_input}}" {{action.name}}
                                                </div>
                                            </v-btn> 
                                        </v-col>  
                                    </v-row> 
                                    <v-row class="ma-0 pl-3 pr-2" v-if="MBS.actions.SIZE(FIELD_NO_DATA_ACTIONS(item.action))>0"> 
                                        <v-col cols="12" class="px-0"
                                            :sm="MBS.actions.SIZE(FIELD_NO_DATA_ACTIONS(item.action))==1?'12':'6'" 
                                            v-for="(action,index) in FIELD_NO_DATA_ACTIONS(item.action)" :key="index">
                                            <v-btn 
                                                :to="action.link"
                                                color="primary" 
                                                @click="FIELDS_ACTION(action,search_input)"
                                                class=" my-2" width="97%"  
                                                depressed  >
                                                <v-icon v-if="action.icon">{{action.icon}}</v-icon> 
                                                <div class="ml-2 font-weight-bol">
                                                    {{action.name}}
                                                </div>
                                            </v-btn> 
                                        </v-col>  
                                    </v-row>
                                </template> 
                                <template v-slot:item="data" v-if="item.search">
                                    <template v-if="(typeof data.item !=='object')" >
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
        
                                    <!-- //ITEM ICON -->
                                    <template v-if="item.search.item_icon">
                                        <v-list-item-avatar 
                                            :tile="item.search.item_icon.tile"
                                            :color="item.search.item_icon.background_color?item.search.item_icon.background_color:'grey'" > 
                                            <v-icon v-if="item.search.item_icon.name" :color="item.search.item_icon.color">
                                                {{item.search.item_icon.name}}
                                            </v-icon>
                                        </v-list-item-avatar> 
                                    </template>
                                    <v-list-item-content>
                                        <!-- //ITEM TITLES -->
                                        <template v-for="(title,index) in item.search.item_title">
                                            <v-list-item-title :key="'title'+index">
                                                {{data.item[title]}}
                                            </v-list-item-title> 
                                        </template>
                                        <!-- //ITEM SUBTITLES -->
                                        <template v-for="(subtitle,index) in item.search.item_subtitle">
                                            <v-list-item-subtitle :key="'sub'+index">
                                                {{data.item[subtitle]}}
                                            </v-list-item-subtitle> 
                                        </template> 
                                    </v-list-item-content>
                                    </template>
                                </template>
                            </v-select>
                            <v-menu  v-else-if="item.type=='date'" 
                                v-model="menu_date_picker[item.value]" 
                                :required ="item.required?true:false"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto" >
                                <template v-slot:activator="{ on, attrs }"> 
                                    <v-text-field 
                                        :outlined="outlined"
                                        :filled="filled!=undefined?filled:true" 
                                        :dense="dense!=undefined?dense:item.label_top?true:item.dense?true:false" 
                                        :required ="item.required?true:false"
                                        v-model="input[item.value]" 
                                        :label="item.label"  
                                        :append-icon="item.append_icon?item.append_icon:'mdi-calendar'"
                                        :prepend-icon="item.prepend_icon"
                                        :prepend-inner-icon="item.prepend_inner_icon"
                                        readonly  
                                        v-bind="attrs"
                                        v-on="on"
                                        :disabled="DisableField(item)"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    :required ="item.required?true:false"
                                    v-model="input[item.value]" 
                                    :max="item.max?item.max:''"
                                    :min="item.min?item.min:''"
                                    @input="menu_date_picker[item.value] = false"
                                    ></v-date-picker>
                            </v-menu>
                            <v-dialog v-else-if="item.type=='time'" 
                                v-model="dialog_time_picker[item.value]" 
                                persistent
                                width="590px" >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :outlined="outlined"
                                        :filled="filled!=undefined?filled:true" 
                                        :dense="dense!=undefined?dense:item.label_top?true:item.dense?true:false"
                                        v-model="input[item.value]" 
                                        :label="item.label" 
                                        :append-icon="item.append_icon?item.append_icon:'mdi-calendar'"
                                        :prepend-icon="item.prepend_icon"
                                        :prepend-inner-icon="item.prepend_inner_icon"
                                        readonly  
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="dialogInput[item.value]=input[item.value]"
                                        :disabled="DisableField(item)"
                                    ></v-text-field>
                                </template>
                                <v-card v-if="dialog_time_picker[item.value]">
                                    <v-time-picker 
                                        v-model="dialogInput[item.value]"
                                        :max="item.max"
                                        :min="item.min"
                                        format="24hr"
                                        :landscape="$vuetify.breakpoint.smAndUp"
                                        full-width > 
                                    </v-time-picker>
                                    <v-divider class="mt-2"></v-divider>
                                    <v-card-actions > 
                                        <v-spacer></v-spacer> 
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="dialog_time_picker[item.value]= false" >
                                            Cancel
                                        </v-btn>
                                        <v-btn 
                                            color="primary" text
                                            @click="SAVE_DIALOG_TIME_PICKER(item.value)" >
                                            SAVE
                                        </v-btn> 
                                    </v-card-actions> 
                                </v-card>
                            </v-dialog>
                            <v-menu  v-else-if="item.type=='time1'" 
                                v-model="menu_time_picker[item.value]" 
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto" >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :outlined="outlined"
                                    :filled="filled!=undefined?filled:true" 
                                    :dense="dense!=undefined?dense:item.label_top?true:item.dense?true:false"
                                    v-model="input[item.value]" 
                                    @focus="ON_FOCUSING(item)"
                                    :label="item.label" :change="UPDATE_MODEL(input,item)" 
                                    :append-icon="item.append_icon?item.append_icon:'mdi-calendar'"
                                    :prepend-icon="item.prepend_icon"
                                    :prepend-inner-icon="item.prepend_inner_icon"
                                    readonly  
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="DisableField(item)"
                                ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-model="input[item.value]" 
                                    :max="item.max?item.max:''"
                                    :min="item.min?item.min:''"
                                    @input="menu_time_picker[item.value] = false"
                                    ></v-time-picker>
                            </v-menu> 
                            <v-card v-else-if="item.type=='add_items'" outlined class="mt-1">
                                <v-layout column class="pa-2">
                                    <v-simple-table
                                        fixed-header >
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th  class="text-left" v-for="(field,index) in item.fields" :key="index">
                                                        {{field.name}}
                                                    </th> 
                                                    <th class="text-right"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                v-for="(kit,index) in JoinedAddKits(item)"
                                                :key="index" >
                                                    <td v-for="(field,index) in item.fields" :key="'td-'+index" >
                                                        <span v-if="field.select">{{ kit[field.select.text]}}</span>
                                                        <span v-else>{{ kit[field.value]}}</span>  
                                                    </td> 
                                                    <td>
                                                        <v-layout row wrap> 
                                                            <v-spacer></v-spacer>
                                                            <v-btn @click="REMOVE_KIT(kit,index,item)" icon><v-icon>mdi-delete</v-icon></v-btn>
                                                        </v-layout>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                    <v-btn @click="ON_ADD_KIT(item)" color="primary">{{item.label}} <v-icon class="ml-2">mdi-plus-circle</v-icon> </v-btn>
                                </v-layout>
                            </v-card>
                            <v-text-field v-else
                                :outlined="outlined"
                                :filled="filled!=undefined?filled:true" 
                                :dense="dense!=undefined?dense:item.label_top?true:item.dense?true:false"
                                :append-icon="item.append_icon"
                                :prepend-icon="item.prepend_icon"
                                :prepend-inner-icon="item.prepend_inner_icon" 
                                :required ="item.required?true:false"
                                @focus="ON_FOCUSING(item)"
                                :change="UPDATE_MODEL(input,item,index)" 
                                :type="item.type" autocomplete="one-time-code"  
                                :autofocus="item.autofocus?true:false"
                                v-model="input[item.value]" 
                                :label="item.label"
                                :error="ITEM_ERROR(item,input[item.value])?true:false"
                                :disabled="DisableField(item)"
                            />   
    
                        </div> 
                        <!-- //show error -->
                        <span v-if="ITEM_ERROR(item,input[item.value])" 
                            class="error--text font-italic">{{ITEM_ERROR(item,input[item.value])}}</span> 
                    </span>
                </v-col>   
            </template>     
        </v-layout>
        <!-- //DIALOGS -->
        <div>
            <mbs-item-manager 
                :show="false"  
                :name_item="selected_field_action?selected_field_action.NAME_ITEM:null"   
                :field_items="selected_field_action?selected_field_action.fields:null"   
                :path_item="selected_field_action?selected_field_action.path:null"  
                :select_items="SELECT_ITEMS"   
            />  
            
            <!-- //add kit -->
            <div v-if="dialog_add_kit">
                <v-dialog 
                    v-model="dialog_add_kit"
                    :hide-overlay="true"
                    :scrollable="false"
                    width="700" >   
                    <v-card 
                        flat
                        width="100%"
                        v-if="selected_kit_field">
                        <v-card-title class="primary white--text">
                            ADD 
                            <v-spacer></v-spacer>
                            <v-btn dark icon @click="dialog_add_kit = false"><v-icon >mdi-close</v-icon></v-btn>
                        </v-card-title>
    
                        <form id="addKitForm" @submit.prevent="ADD_KIT(input_kit,selected_kit_field)" >
                            <v-card-text class="pt-5">
                                <mbs-input-fields 
                                    v-model="input_kit"
                                    :field_items="selected_kit_field.fields"
                                    :select_items="SELECT_ITEMS"/>
                            </v-card-text>
        
                            <v-divider></v-divider>
        
                            <v-card-actions class="mx-2 pb-5">
                                <v-spacer></v-spacer>
                                
                                <v-btn
                                    color="primary"
                                    text
                                    @click="dialog_add_kit = false" >
                                    Cancel 
                                </v-btn>
                                <v-btn class="px-5"
                                    color="primary" 
                                   type="submit" >
                                    {{selected_kit_field.label}}
                                    <v-icon class="ml-2">mdi-plus-circle</v-icon>
        
                                </v-btn>
                            </v-card-actions> 
                        </form>
                    </v-card>
                </v-dialog> 

            </div>

        </div>
    </div> 
</template>

<script>
    import DATA from '../../plugins/DATA';
    import {mapState} from "vuex" 
    let PAGE_NAME  = "INPUT_FIELDS"  
    export default {
        name:PAGE_NAME,
        props:[
            'show','value',
            'name_items', 
            'name_item', 
            'value_items', 
            'value_item', 
            'field_items',
            'select_items', 
            'disabled_fields',  
            'update_item',  
            'updating_item',  
            'item',
            'outlined','dense','filled',  
            'py','px'
        ],
        data() {
            return { 
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,
  
                excelSelecter:false,
                EXCEL_DATA_ITEMS:[],
                generatedCode:null, 
                inputCode:null, 
                inputCodeLoading:false, 
                running_data_change:false, 
                re_running_data_change:false, 
                menu_date_picker:{},
                menu_time_picker:{},
                dialog_time_picker:{},
                dialog_add_kit:false,
                dialogInput:{},
                search:'',  
                search_input:null,   
                input_:"",   
                input_kit:{},   
                added_kits:[],   
                input:this.value,
                loading_input:false,
                input_errors:{},   
                selected_field_action:null, 
                selected_kit_field:null, 
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters',
                    emailMatch: () => (`The email and password you entered don't match`),
                },
                error_text:{
                    no_code:"Code Required",
                    used:"Entered value is already used",
                    used_by_many:"Entered value used by more than 1 items",
                },
                show3: false,
                this_: this,
            }
        },
        created() {
            try {
                // this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {   
                //SET AUTO CODE
                let input = this.input
                let items = this.field_items  
                if (items) {
                    items.forEach(field => {
                        //CODE
                        if (field.value == "code") {
                            if (field.auto_code) {
                                if (field.auto_code.onstart && !input.code) {
                                   this.setAutoCode(field.auto_code.values,false) 
                                }
                            }
                        }
                        //DATE
                        if (field.type == "date") {
                            if (field.onstart && !input[field.value]) {
                                let moment = this.MBS.date.moment
                                let today = this.MBS.date.today
                                let tomorrow = this.MBS.date.tomorrow
                                let yesterday = this.MBS.date.yesterday
                                if (field.onstart=="today") {
                                    this.input[field.value] = today
                                }else if (field.onstart=="tomorrow") {
                                    this.input[field.value] = tomorrow
                                }else if (field.onstart=="yesterday") {
                                    this.input[field.value] = yesterday
                                }else{
                                    this.input[field.value] = today
                                }    
                            }
                        } 
                    });
                }

                //
                let code = this.value?this.value.code:null
                let generatedCode = this.value?this.value.generatedCode:null
                if (code) {
                    this.inputCode = code
                }if (generatedCode) {
                    this.generatedCode = generatedCode
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses, 
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()], 
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                SelectedCompany: state=> state.items["SELECTED_COMPANY"], 
            }),
            FIELD_ITEMS(){ 
                let items = null
                if (this.field_items) {
                    items = [...this.field_items]
                }else{
                    items = [
                        {type:"number",name:"code",value:"code",label:"Code",required:true,sm:12,md:12}, 
                        {type:"text",name:"name",value:"name",label:"Name",required:true,sm:12,md:12},   
                    ]  
                }  
                return items
            }, 
            DisableField(){
                let disabled_fields = this.disabled_fields
                return field=>{
                    if(!field){return false}
                    let disabled = field.disabled
                    let field_functions = field.field_functions
                    let disabled_function = field_functions?field_functions.disabled:null
                    if (typeof disabled =="function") {
                        return disabled(this,this.input)
                    }
                    if(disabled_function){ 
                        return disabled_function(this.input)
                    }
                    if(!disabled_fields){return field.disabled?true:false}
                    let is_disabled = disabled_fields.indexOf(field.value)>-1?true:false
                    return is_disabled
                }
            },
            VisibleField(){ 
                let visible = true
                return field=>{
                    if(!field){return visible}
                    let field_functions = field.field_functions
                    let visible_function = field_functions?field_functions.visible:null
                    if(visible_function){ 
                        return visible_function(this,this.input)
                    } 
                    return visible
                }
            },
            JoinedAddKits(){ 
                return field=>{
                    if(!field){return null}
                    let added_kits = this.input[field.value]   
                    if(!added_kits){return null} 
                    let joined = this.MBS.actions.JOIN({
                        items:added_kits,
                        name:field.select.value
                    })
                    
                    return joined 
                }
            },
            SELECT_ITEMS(){ 
                let item = this.select_items 
                return item?item:null
            },  
            ITEM_PRIMARY(){  
                return (item,text_value,primary)=>{ 
                    let input = this.input
                    let updating_item = this.updating_item
                    let updating_item_value = updating_item?updating_item[item.value]:null
                    let item_key = input?this.input.key:null
                    let item_code = input?this.input.code:null 
                    let value = item?item.value:null 
                    let response = {
                        primary:primary,
                        isPrimary:primary?true:false,
                        isUsed:false,
                        text_value:text_value,
                        items:null,
                        key_item:null,
                        code_item:null,
                        key_item_size:0,
                        code_item_size:0,
                        hasMyKey:false,
                        hasMyCode:false,

                        size:0,
                    }  
                    // if(!primary||!value){return response} 
                    if(!primary||!value||!text_value){return response} 

                    
                    if (text_value &&  this.MBS.actions.TEXT_SPACE0(this.MBS.actions.TEXT_UP(text_value+""))
                        ==  this.MBS.actions.TEXT_SPACE0(this.MBS.actions.TEXT_UP(updating_item_value+""))) {
                        return response
                    }
                    let items = this.$store.state.items[primary.toUpperCase()]
                    let filtered = items?items.filter(item=>{
                        return this.MBS.actions.TEXT_SPACE0(this.MBS.actions.TEXT_UP(item[value]+""))
                            == this.MBS.actions.TEXT_SPACE0(this.MBS.actions.TEXT_UP(text_value+""))
                    }):null
 
                    let size = this.MBS.actions.SIZE(filtered) 
                    let key_item = filtered?filtered.filter(item=>item.key==item_key):null
                    let code_item = filtered?filtered.filter(item=>item.key==item_code):null
                    let key_item_size = this.MBS.actions.SIZE(key_item)
                    let code_item_size = this.MBS.actions.SIZE(code_item)
                    response.items = filtered
                    response.size = size
                    response.isUsed = size>0?true:false
                    response.hasMyKey = key_item_size>0?true:false
                    response.hasMyCode = code_item_size>0?true:false
                    response.key_item = key_item
                    response.code_item = code_item
                    response.key_item_size = key_item_size
                    response.code_item_size = code_item_size
                    return response
                }
            },  
            ITEM_ERROR(){  
                return (item,value)=>{
                    let roles = item?item.roles:null
                    let field_error = roles?roles.field_error:null
                    let field_warning = roles?roles.field_warning:null 
                    let error_text = this.error_text

                    delete this.input_errors[item.value]

                    let size = (min,max)=>{
                        let s = this.MBS.actions.SIZE(value+"")
                        let is_min = min?s>=min?true:false:true
                        let is_max = max?s<=max?true:false:true
                        if (!is_min && !is_max) {
                            this.input_errors[item.value] = "Characters should be "+min+" minimum & "+max+" maximum"
                            return this.input_errors[item.value]
                        }else if(!is_min){
                            this.input_errors[item.value] = "Characters should be "+min+" minimum."
                            return this.input_errors[item.value] 
                        }else if(!is_max){
                            this.input_errors[item.value] = "Characters should be "+max+" maximum."
                            return  this.input_errors[item.value]
                        } 
                    }

                    if (!roles) { 
                        return  
                    }
                    if (field_error) { 
                        //check primary
                        let item_primary = this.ITEM_PRIMARY(item,value,field_error.primary)
                        let primary_size = item_primary?item_primary.size:null
                        let isUsed = item_primary?item_primary.isUsed:null 
                        if (isUsed) {
                            this.input_errors[item.value] =  primary_size>1?error_text.used_by_many:error_text.used
                            return  this.input_errors[item.value]
                        }
                        
                        //check size
                        let size_error = size(field_error.min_size,field_error.max_size)
                        if (size_error) {
                            this.input_errors[item.value] = size_error 
                           return  this.input_errors[item.value]
                        }
                    }
                    if (field_warning) {
                        
                    }
                    

                    return
                    let item_primary = this.ITEM_PRIMARY(item,code)
                    let primary_size = item_primary?item_primary.size:null
                    let isUsed = item_primary?item_primary.isUsed:null
                    let hasMyKey = item_primary?item_primary.hasMyKey:null

                    if (this.MBS.actions.SIZE(code)==0) {
                        return error_text.no_code
                    }

                    if (isUsed) {
                        if (primary_size>1) {
                            if (hasMyKey) {
                                return null
                            } 
                            return error_text.used_by_many
                        }else{
                            if (hasMyKey) {
                                return null
                            } 
                            return error_text.used
                        }
                    }  
                    return null
                }
            },   
        },
        methods: {  
            FIELD_APPEND_ACTIONS(item){
                try {   
                    if(!item){return null}
                    let newItems = []
                    item.forEach(element => {
                        let link = null
                        let path = null
                        let fields = null
                        let data_item = null
                        let NAME_ITEMS = null
                        let NAME_ITEM = null
                        let VALUE_ITEMS = null
                        let VALUE_ITEM = null
                        if (element.item_values) {
                            fields = DATA.FIELDS[this.MBS.actions.TEXT_UP0(element.item_values)]
                            path = DATA.PATHS[this.MBS.actions.TEXT_UP0(element.item_values)]
                            data_item = DATA.ITEMS[this.MBS.actions.TEXT_UP(element.item_values)]
                        }
                        if (data_item) {
                            NAME_ITEMS = data_item.names
                            NAME_ITEM=data_item.name
                            VALUE_ITEMS=data_item.values
                            VALUE_ITEM=data_item.value
                        }

                        if (element.link) {
                            link = this.MBS.actions.COMPANY_LINK(element.link,element.company_link)
                        }
                            path = this.MBS.actions.COMPANY_LINK(path,element.company_link) 
                        if (element.append) {
                            newItems.push({
                                ...element,
                                fields:fields,
                                data_item:data_item,
                                link:link,
                                path:path,
                                NAME_ITEMS:NAME_ITEMS,
                                NAME_ITEM:NAME_ITEM,
                                VALUE_ITEMS:VALUE_ITEMS,
                                VALUE_ITEM:VALUE_ITEM,
                            }) 
                        }
                    });
                    return newItems
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FIELD_APPEND_ACTIONS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            FIELD_NO_DATA_ACTIONS(item){
                try {  
                    if(!item){return null}
                    let newItems = []
                    item.forEach(element => {
                        let link = null
                        let path = null
                        let fields = null
                        let data_item = null
                        let NAME_ITEMS = null
                        let NAME_ITEM = null
                        let VALUE_ITEMS = null
                        let VALUE_ITEM = null
                        if (element.item_values) {
                            fields = DATA.FIELDS[this.MBS.actions.TEXT_UP0(element.item_values)]
                            path = DATA.PATHS[this.MBS.actions.TEXT_UP0(element.item_values)]
                            data_item = DATA.ITEMS[this.MBS.actions.TEXT_UP(element.item_values)]
                        }
                        if (data_item) {
                            NAME_ITEMS = data_item.names
                            NAME_ITEM=data_item.name
                            VALUE_ITEMS=data_item.values
                            VALUE_ITEM=data_item.value
                        }

                        if (element.link) {
                            link = this.MBS.actions.COMPANY_LINK(element.link,element.company_link)
                        }
                            path = this.MBS.actions.COMPANY_LINK(path,element.company_link) 
                        if (element.no_data) {
                            newItems.push({
                                ...element,
                                fields:fields,
                                data_item:data_item,
                                link:link,
                                path:path,
                                NAME_ITEMS:NAME_ITEMS,
                                NAME_ITEM:NAME_ITEM,
                                VALUE_ITEMS:VALUE_ITEMS,
                                VALUE_ITEM:VALUE_ITEM,
                            }) 
                        }
                    });
                    return newItems
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FIELD_APPEND_ACTIONS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            FIELD_NO_DATA_SEARCH_ACTIONS(item){
                try {  
                    if(!item){return null}
                    let newItems = []
                    item.forEach(element => {
                        let link = null
                        let path = null
                        let fields = null
                        let data_item = null
                        let NAME_ITEMS = null
                        let NAME_ITEM = null
                        let VALUE_ITEMS = null
                        let VALUE_ITEM = null
                        if (element.item_values) {
                            fields = DATA.FIELDS[this.MBS.actions.TEXT_UP0(element.item_values)]
                            data_item = DATA.ITEMS[this.MBS.actions.TEXT_UP(element.item_values)]
                        }
                        if (data_item) {
                            NAME_ITEMS = data_item.names
                            NAME_ITEM=data_item.name
                            VALUE_ITEMS=data_item.values
                            VALUE_ITEM=data_item.value
                        }

                        if (element.link) {
                            link = this.MBS.actions.COMPANY_LINK(element.link,element.company_link)
                        }
                        if ((element.searchable)) {
                            newItems.push({
                                ...element,
                                fields:fields,
                                data_item:data_item,
                                link:link,
                                NAME_ITEMS:NAME_ITEMS,
                                NAME_ITEM:NAME_ITEM,
                                VALUE_ITEMS:VALUE_ITEMS,
                                VALUE_ITEM:VALUE_ITEM,
                            }) 
                        }
                    });
                    return newItems
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FIELD_APPEND_ACTIONS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            FIELDS_ACTION(action){
                try {    
                    this.selected_field_action = action
                    // if (action.action=="add_item") {
                        setTimeout(() => { 
                            this.MBS.events.$emit("ITEM_ACTION",action,action.action)
                        }, 0);
                    // }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FIELD_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            AT_SEARCH_INPUT(input){
                try {  
                    this.search_input = input
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'AT_SEARCH_INPUT',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            AT_INPUT_CHANGE(input){
                try {   
                    this.$emit('input', {...this.input,errors: this.input_errors}) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'AT_INPUT_CHANGE',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            ON_ADD_KIT(field){
                try {  
                    this.selected_kit_field=field
                    this.input_kit = {}
                    this.dialog_add_kit = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_ADD_KIT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            ADD_KIT(input_kit,field){
                try {  
                    if (input_kit && field) {
                        let new_input = {}
                        if (field.fields) {
                            field.fields.forEach(element => {
                               new_input[element.value] = input_kit[element.value] 
                            }); 
                        }
                        let value_key = field.select?field.select.value_key:null
                        // let index = this.added_kits.findIndex(it=>it[value_key] == input_kit[value_key])
                        if(!this.input[field.value]){this.input[field.value]=[]}
                        let index = this.input[field.value].findIndex(it=>it[value_key] == input_kit[value_key])
                        if (index==-1 || !value_key) {
                            // this.added_kits.push(new_input)
                            this.input[field.value].push(new_input)
                        } else {
                            // this.added_kits[index] = new_input
                            this.MBS.actions.dialog({
                                show:true,
                                title:"ALREADY ADDED",
                                text:"The selected item have already been added.",
                                btnYes:"OK"
                            })
                            return
                        }

                        if (field.value) {
                            this.input = {
                                ...this.input
                            }
                            // this.input[field.value] = this.added_kits
                            this.UPDATE_MODEL(this.input,field)
                        } 
                        this.dialog_add_kit = false 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_KIT',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            REMOVE_KIT(item,index,field){
                try {   
                    if (this.input[field.value]) {
                        this.input[field.value].splice(index,1) 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REMOVE_KIT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            CREATE_FIELDS_ARRAY_DATA(child,input,notify){
                try { 
                    let code = this.inputCode
                    //CREATE ITEM ARRAY
                    let fields_array_data = {}
                    let required = false
                    let field_items = child?this.FIELD_CHILD_ITEMS:this.FIELD_ITEMS
                    if (input.key) {
                        fields_array_data["key"]=input.key
                    }
                    field_items.forEach(field => {
                        if (field.value==='code') {
                            fields_array_data.code = code 
                        } else {
                            let value = input[field.value]?input[field.value]:null
                            if (!value) {
                                if (field.required) {
                                    if (notify) {
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:false,
                                            title:this.MBS.text.field_required(field.label).title,
                                            text:this.MBS.text.field_required(field.label).text,
                                            btnYes:this.MBS.text.field_required(field.label).btnYes
                                        }) 
                                    }
                                    required = true
                                }
                            }else{
                                fields_array_data[field.value] = value 
                            }
                        } 
                    }); 
                    return {
                        fields_array_data:fields_array_data,
                        required:required
                    }
                         
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_FIELDS_ARRAY_DATA',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },  
            async setAutoCode(values,notify=true){ 
                this.inputCodeLoading = true
                try {
                    let SelectedCompany = this.SelectedCompany
                    let settings = this.CompanySettings
                    let use_uniform_code = settings?settings.use_uniform_code:null
                    let company_key = SelectedCompany?SelectedCompany.company_key:null
                    let name_items = this.name_items
                    let path = "COMPANIES/"+company_key
                    let path_name = "" 
                    //
                    if(values == "SYSTEM_CODE"){
                        path_name = this.MBS.actions.TEXT_UP(name_items)
                    }else{
                        path_name = values
                    }

                    if(use_uniform_code){
                        path = path+"/"+path_name 
                    }else{
                        path = values
                    }
                    
                    // console.log(path,'path............');
                    // console.log(name_items,'name_items............');
                    // console.log(settings,'settings............');
                    // console.log(SelectedCompany,'SelectedCompany............');

                    if (this.generatedCode) {
                        this.inputCode = this.generatedCode
                        this.inputCodeLoading = false 
                    }else{ 
                        let res = await this.MBS.actions.CODE_GENERATE(this.MBS.actions.TEXT_UP(path)) 
                        let res_code = this.MBS.actions.NUMBER_SAME_LENGTH(res.code,3) 
                        this.inputCode= res_code
                        this.generatedCode= res_code
                        this.input.generatedCode= res_code
                        this.inputCodeLoading = false 
                    }
                    if (notify) {
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.generate_code_successful.title,
                            text:this.MBS.text.generate_code_successful.text,
                            btnYes:this.MBS.text.generate_code_successful.btnYes,
                        })   
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'setAutoCode',
                        page:PAGE_NAME, 
                    }) 
                    this.inputCodeLoading = false
                }
            },
            isAutoCode(code){ 
                try {
                    if(!this.generatedCode){return false}
                    if(!code){return false}
                    if (this.generatedCode === code) {
                        return true
                    } 
                } catch (error) {
                   this.MBS.actions.error({
                        error:error,
                        from:'isAutoCode',
                        page:PAGE_NAME, 
                    })  
                }
                return false
            }, 
            
            //---------------[MAIN FUNCTIONS]------------
            SAVE_DIALOG_TIME_PICKER(value){
                try {
                   this.input[value] = this.dialogInput[value] 
                   this.dialog_time_picker[value]= false
                   this.UPDATE_MODEL()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_MODEL',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_FOCUSING(field){
                try {  
                    if (field) {
                        this.focused_field = field
                        // setTimeout(() => {  
                        //     this.focused_field = field
                        // }, 100); 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_FOCUSING',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            CALCULATE_KEY_VALUE(input,field){
                try {  
                    let fields = this.field_items
                    if (fields&&field) {

                        fields.forEach(key_field => {
                            if (key_field.key_value == field.value) {
                                if (this.input_!='Loading...' && this.input_!=this.MBS.actions.TEXT_SPACE_(this.MBS.actions.TEXT_UP0(input[field.value])) ) { 

                                    console.log(this.input_,'---',input[field.value],'v..................');
                                    this.input[key_field.value] = this.MBS.actions.TEXT_SPACE_(this.MBS.actions.TEXT_UP0(input[field.value])) 
                                    this.input_ = "Loading..."
                                    setTimeout(() => {
                                        
                                        this.input_ = this.MBS.actions.TEXT_SPACE_(this.MBS.actions.TEXT_UP0(input[field.value])) 
        
                                        console.log(this.input[key_field.value],input[field.value],'v.......');
                                    }, 500);
                                }
                            }
                        });
                        
                    }

                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_KEY_VALUE',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            UPDATE_MODEL(input,field,index){
                try {  
                    this.CALCULATE_KEY_VALUE(input,field)
                    let focused_field = this.focused_field 
                    let focused_field_value = focused_field?focused_field.value:null
                    let field_value = field?field.value:null 
                    // if (field && focused_field_value == field_value ) {   
                    //     console.log('STARTS.......');
                    //     setTimeout(() => {
                    //         this.DATA_CHANGE(input) 
                    //     }, 1); 
                    // } 
                    this.search_input=null 
                    
                    this.$emit('input', {...this.input,errors: this.input_errors})  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_MODEL',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            DATA_CHANGE( input=this.input, field=this.focused_field ){ 
                try { 
                    if (!this.running_data_change) {
                        this.running_data_change = true
                        // this.$emit('input', {...this.input,errors: this.input_errors})  

                        if (field) {  
                            console.log(field.value,'running........'); 
                            setTimeout(() => {
                                let data_change = field.field_functions?field.field_functions.data_change:null
                                if (data_change) {
                                    this.focused_field = null
                                    let res = data_change(this,input,field)
                                    let new_input = res?res.new_input:null
                                    if (new_input) {
                                        this.loading_input=true
                                        this.input = {
                                            ...this.input,
                                            ...new_input
                                        }
 
                                        console.log(this.input,'.+++++');
                                         
                                    }
                                    console.log(new_input,'new_input.....'); 
                                    console.log(res,'res.....'); 
                                    setTimeout(() => { 
                                        this.loading_input=false
                                        this.focused_field = field
                                        // setTimeout(() => {  
                                        // }, 100);  
                                    }, 100); 
                                } 
                            }, 1);
                            // 
                        }
                    }else{ 
                        this.re_running_data_change = true
                    }
                    setTimeout(() => {
                        this.running_data_change = false 
                        if (this.re_running_data_change) {
                            this.re_running_data_change = false
                            this.DATA_CHANGE(input,field)
                        }
                    }, 2000); 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DATA_CHANGE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            DIALOG_YES(action){ 
                try {
                    if (action.code) {  
                        if (action.code ===PAGE_NAME+'=AUTO-GENERATE-CODE') { 
                            if (action.YES) {
                                this.EXCEL_DATA_ITEMS= this.MBS.actions.CODE_AUTO_GENERATE(this.EXCEL_DATA_ITEMS) 
                                setTimeout(() => { 
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:'GENERATED',
                                        text:"Code, successfully generated.",
                                        btnYes:"OK"
                                    }) 
                                }, this.MBS.data.ACTION_REFRESH_TIME); 
                            } else { 
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=AUTO-SET-CODE') { 
                            if (action.YES) {
                                this.inputCodeLoading = true
                                setTimeout(() => { 
                                    this.setAutoCode()
                                }, this.MBS.data.ACTION_REFRESH_TIME); 
                            } else {
                                
                            }
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);    
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);    
        },
        watch: { 
            us(v){ 
            },  
            value(value) { 
                // this.input = value
            },
            input(currentValue,change_data) {    
                // console.log(currentValue,'currentValue.....');
                // this.$emit('input', {...currentValue,errors: this.input_errors})
            }, 
            inputCode(currentValue) { 
                this.input.code=currentValue
            },    
        },
  }
</script>