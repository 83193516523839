<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card >  
                        <mbs-page-tab 
                            :items="MY_LINKS"/> 
                        <mbs-item-manager
                            :add_vertical_lines="true"
                            :top_label="false"
                            :filter_date="true"
                            :row_click="true"
                            :filter_date_value="'local_created_at'"
                            :filter_default_days="'90'"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM" 
                            :header_items="HEADER_ITEMS" 
                            :data_items="DataItems"   
                            :is_joined="true"
                            :path_item="SALES_PATH"   

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div>
            
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.DAYS_SHIFTS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.DAYS_SHIFTS.names,
                NAME_ITEM:DATA.ITEMS.DAYS_SHIFTS.name,
                VALUE_ITEMS:DATA.ITEMS.DAYS_SHIFTS.values,
                VALUE_ITEM:DATA.ITEMS.DAYS_SHIFTS.value,      
                table_actions:[  
                    {type:"btn",action:"start_day",color:"secondary", outlined:false,  text:"Start a Day"},  
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                ] 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                DaysShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDaysShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
            }), 
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
             
            /////////
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("sale",1,true) 
                return thisLinks
            }, 
            SALES_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.sale_orders,true) 
                return path
            },  
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',icon:'mdi-form-dropdown',color:"primary",tile:true,size:40,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Open",value:'local_created_at_',show:true},   
                    {id:0,name:"close",value:'close_time_',show:true},   
                    {id:0,name:"Operational",value:'operating_date_',show:true},     
                    {id:0,name:"Outlet",value:"outlet_name",show:false},      
                    {id:0,name:"code",value:"key",align:"center",show:false},         
                    {id:0,name:"User",value:"user_name",show:true},      
                    {id:0,name:"Floating",value:'total_floating_cash_',align:'right',show:true},    
                    {id:0,name:"Close Cash",value:'total_closing_cash_',align:'right',show:true},    
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Report",action:"view_report",icon:"mdi-eye",show_if:item=>item.closed}, 
                        {name:"X Read",action:"x_read",icon:"mdi-eye",show_if:item=>!item.closed}, 
                        {name:"Z Read",action:"z_read",icon:"link",show_if:item=>!item.closed},   
                    ]}, 
                ]
            }, 
            DataItems(){ 
                let DaysShifts = this.DaysShifts
                let JoinedDaysShifts = this.JoinedDaysShifts
                let items = JoinedDaysShifts?JoinedDaysShifts:DaysShifts
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))  
                console.log(list,'list..---...');
                return list 
            } 
        },
        methods: { 
            TABLE_ACTION(action,item){
                try { 
                    if (action == "start_day") {
                        let link = this.MBS.actions.COMPANY_LINK("/sale/start-day",true)
                        this.MBS.actions.go(link)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    if (action == "view_report") {
                        let link = "/day-shift-report/"+(item?item.key:null)
                        const path = this.MBS.actions.COMPANY_LINK(link,true)  
                        this.MBS.actions.go(path)
                    }if (action == "x_read") {
                        let link = "/x-read/"+(item?item.key:null)
                        const path = this.MBS.actions.COMPANY_LINK(link,true)  
                        this.MBS.actions.go(path)
                    }if (action == "z_read") {
                        let link = "/z-read/"+(item?item.key:null)
                        const path = this.MBS.actions.COMPANY_LINK(link,true)  
                        this.MBS.actions.go(path)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            }, 
        },
        watch: { 
            us:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            loading:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            processes:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            responses:{ 
                handler:"FEEDBACKS",
                immediate:true
            } 
        },
    }
</script>
 
